import React, { Component } from "react";
import Accordion from "../components/Accordion";

class FaqInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="faqs-invite">
          <h3>FAQs</h3>
          <div className="accordions">
            <Accordion
              title="How do I create an entry?"
              content="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group. "
            />

            <Accordion
              title="What is the deadline for entries?"
              content="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
            />
          </div>
        </div>
      </>
    );
  }
}

export default FaqInvite;
