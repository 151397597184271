import React, { Component } from "react";

class Earnpoints extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="points-cards">
          <div className="step-icon">
            <img src={this.props.imgsrc} alt="deafult" />
          </div>
          <div className="text">
            <p>{this.props.text}</p>
          </div>
        </div>
      </>
    );
  }
}

export default Earnpoints;
