import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Placeadd from "../components/Placeadd";
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import TimerBanner from "../components/timer-banner";
import Bluebtn from "../components/Bluebtn";
import { GetData } from "../utils/apiRequestHandler";
import Loader from "../components/Loader";
import { Form, Modal, Button } from "react-bootstrap";
import PublicIcon from "../assets/images/public.svg";
import PrivateIcon from "../assets/images/private.svg";
import CrossBtn from "../assets/images/cross-btn.png";
import CopyIcon from "../assets/images/copy.svg";
import MemberListTable from "../components/MemberListTable";
import LeaderBoardTable from "../components/LeaderBoardTable";
import CreateGroupModal from "../components/groupModal/CreateGroupModal";
import JoinGroupModal from "../components/groupModal/JoinGroupModal";
import CreateBracketModal from "../components/groupModal/CreateBracketModal";
import DeleteBracket from "../components/groupModal/DeleteBracket";
import DeleteGroup from "../components/groupModal/DeleteGroup";
import TransferModal from "../components/groupModal/TransferModal";
import LeaderBoardMain from "./LeaderBoardMain";
import MyGroupLogo from "../assets/images/logo3.png";
import ShareButton from "../components/ShareButton";

const men = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/men-tourna.png";
const women = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/women-tourna.png";

class CreateBracket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            tournamentStart: true,
            bracketData: [],
            maleBracket: [],
            femaleBracket: [],
            currentBracket: "male",
            currentTabSelected: "my-brackets",
            isPartiallyFilledMens: false,
            isPartiallyFilledWomens: false,
            isBracketEditableMens: true,
            isBracketEditableWomens: true,
            removeButton: false,
            activeTabBracket: "tabBracket1",
            activeTabMyGroups: "tabMyGroup1",
            activeTabPublicGroups: "tabPublicGroup1",
            leaderMemberTableList: {
                type: '',
                id: ''
            },
            showModal: false,
            show:false,
            tabModalShow:true,
            bracketNameModal: false,
            deleteBktModal: false,
            transferOwner: false,
            deleteGrpModal: false,
            deleteId: null,
            groupdeleteId: null,
            groupData: [],
            groupDataMale: [],
            groupDataFemale: [],
            editGroupData: null,
            maleBracketEnable: true,
            femaleBracketEnable: true,
            createGroupEnable: false,
            showCopy: false,
            memberList: [],
            publicGroupMaleData: {
                rows: [],
                count: 0,
                limit: 4,
                page: 1
            },
            publicGroupFemaleData: {
                rows: [],
                count: 0,
                limit: 4,
                page: 1
            },
            joinGroupModal: {
                show: false,
                hide: this.closeJoinGroupModal
            },
            searchVal: '',
            s3URL: 'https://ncrstaging.s3.us-west-2.amazonaws.com/'
        };
        this._currentBracket = "male";
    }

    componentDidMount() {

        let tournamenttime = JSON.parse(localStorage.getItem("tournamenttime"));
        let tabset = false;
        if (this.props.match.params.tabname && (this.props.match.params.tabname === 'mens' || this.props.match.params.tabname === 'womens')) {
            tabset = this.props.match.params.tabname === 'mens' ? 'tabBracket1' : 'tabBracket2';
        }
        if (tournamenttime) {
            this.setState({
                tournamentStart: tournamenttime.gameStarted
            }, () => {
                this.getAllBracket(tabset);
                if (this.props.match.params.tabname && this.props.match.params.tabname === 'my-groups') {
                    this.handleSelect('my-groups');
                } else if (this.props.match.params.tabname && this.props.match.params.tabname === 'my-groups') {
                    this.handleSelect('public-groups');
                } else if (this.props.match.params.tabname && this.props.match.params.tabname === 'mens') {
                    this.handleSelect('my-brackets');
                } else if (this.props.match.params.tabname && this.props.match.params.tabname === 'womens') {
                    this.handleSelect('my-brackets');
                }
            })
        } else {

            this.getAllBracket(tabset);
            if (this.props.match.params.tabname && this.props.match.params.tabname === 'my-groups') {
                this.handleSelect('my-groups');
            } else if (this.props.match.params.tabname && this.props.match.params.tabname === 'my-groups') {
                this.handleSelect('public-groups');
            } else if (this.props.match.params.tabname && this.props.match.params.tabname === 'mens') {
                this.handleSelect('my-brackets');
            } else if (this.props.match.params.tabname && this.props.match.params.tabname === 'womens') {
                this.handleSelect('my-brackets');
            }
        }
        // How to use
    }

    getAllBracket = (tabset = false) => {
        this.setState({ loader: true });
        GetData("manage-user-bracket/getAllBracket").then((response) => {
            console.log("Response", response);
            let femaleBracket = [], maleBracket = []
            if (response.status && response.data) {
                if (response.data && response.data.length) {
                    response.data.map((d) => {
                        if (d.type === 'male') {
                            maleBracket.push(d)
                        } else {
                            femaleBracket.push(d)
                        }
                    })
                }
                // this.setState({ removeButton: true });
                this.setState({
                    loader: false,
                    bracketData: response.data,
                    maleBracket: maleBracket,
                    femaleBracket: femaleBracket,
                    activeTabBracket: tabset ? tabset : (maleBracket.length === 0 && femaleBracket.length === 0) ? "tabBracket1" : maleBracket.length === 0 ? "tabBracket2" : "tabBracket1",
                    currentBracket: maleBracket.length < 5 ? 'male' : 'female',
                    maleBracketEnable: maleBracket.length < 5 ? true : false,
                    femaleBracketEnable: femaleBracket.length < 5 ? true : false,

                });
            } else {
                this.setState({
                    bracketData: [],
                    loader: false,
                    maleBracket: maleBracket,
                    femaleBracket: femaleBracket,
                    currentBracket: tabset ? tabset : maleBracket.length < 5 ? 'male' : 'female',
                    maleBracketEnable: maleBracket.length < 5 ? true : false,
                    femaleBracketEnable: femaleBracket.length < 5 ? true : false,
                });
            }
        });
    }

    getGroups = (tabsel = false) => {
        this.setState({ loader: true });
        GetData("groups").then((response) => {
            let adminGroup = [], male = [], female = [];
            if (response.status && response.data && response.data) {
                response.data.map((d) => {
                    if (d.is_admin) {
                        adminGroup.push(d);
                    }
                    if (d.tournament_type === 'male') {
                        male.push(d);
                    }
                    if (d.tournament_type === 'female') {
                        female.push(d);
                    }
                })

                if (adminGroup.length) {
                    this.setState({
                        loader: false,
                        groupData: response.data,
                        s3URL: response?.s3url,
                        groupDataMale: male,
                        groupDataFemale: female,
                        activeTabMyGroups: tabsel ? tabsel : male.length ? 'tabMyGroup1' : female.length ? 'tabMyGroup2' : 'tabMyGroup1',
                        createGroupEnable: false
                    });
                } else {
                    this.setState({
                        loader: false,
                        groupData: response.data,
                        s3URL: response?.s3url,
                        groupDataMale: male,
                        groupDataFemale: female,
                        activeTabMyGroups: tabsel ? tabsel : male.length ? 'tabMyGroup1' : female.length ? 'tabMyGroup2' : 'tabMyGroup1',
                        createGroupEnable: true
                    });
                }
            } else {
                this.setState({
                    groupData: [],
                    loader: false,
                    groupDataMale: male,
                    groupDataFemale: female,
                    s3URL: response?.s3url,
                    activeTabMyGroups: tabsel ? tabsel : male.length ? 'tabMyGroup1' : 'tabMyGroup2',
                });
            }
        });
    }
    changeLimit = (event) => {
        let page = parseInt(event.selected) + 1;
        if (this.state.activeTabPublicGroups === 'tabPublicGroup1') {
            this.setState({
                publicGroupMaleData: { ...this.state.publicGroupMaleData, ...{ page: page } }
            }, () => {
                this.getPublicGroups();
            });
        } else {
            this.setState({
                publicGroupFemaleData: { ...this.state.publicGroupFemaleData, ...{ page: page } }
            }, () => {
                this.getPublicGroups();
            });
        }

    };
    getPublicGroups = (searhValue = '') => {
        this.setState({ loader: true });
        let gender = this.state.activeTabPublicGroups === 'tabPublicGroup1' ? 'male' : 'female';
        let limit = gender === 'male' ? this.state.publicGroupMaleData.limit : this.state.publicGroupFemaleData.limit;
        let page = gender === 'male' ? this.state.publicGroupMaleData.page : this.state.publicGroupFemaleData.page;
        let url = `groups/public?type=${gender}&limit=${limit}&page=${page}`;
        if (this.state.searchVal) {
            url += `&searhValue=${this.state.searchVal}`;
        }
        GetData(url).then((response) => {
            if (response.status && response.data && response.data.rows) {
                if (gender === 'male') {
                    this.setState({
                        loader: false,
                        publicGroupMaleData: { ...this.state.publicGroupMaleData, ...response.data }
                    });
                } else {
                    this.setState({
                        loader: false,
                        publicGroupFemaleData: { ...this.state.publicGroupFemaleData, ...response.data }
                    });
                }
            } else {
                if (gender === 'male') {
                    this.setState({
                        loader: false,
                        publicGroupMaleData: {
                            rows: [],
                            count: 0,
                            limit: 4,
                            page: 1
                        }
                    });
                } else {
                    this.setState({
                        loader: false,
                        publicGroupFemaleData: {
                            rows: [],
                            count: 0,
                            limit: 4,
                            page: 1
                        }
                    });
                }
            }
        });
    }

    createYourGroupModal = (edit = false) => {
        if (edit) {
            this.setState({ showModal: !this.state.showModal });
        } else {
            this.setState({ showModal: !this.state.showModal, editGroupData: null });
        }

    }


    closeJoinGroupModal = (data) => {
        this.setState({
            joinGroupModal: {
                show: false,
                hide: this.closeJoinGroupModal
            }
        }, () => {
            if (data) {
                this.getPublicGroups();
                this.getPublicGroups();
            }
        }
        );
    }

    closeYourGroupModal = (data, tabselected) => {
        if (data) {
            let tabsel = false;
            if (tabselected) {
                tabsel = tabselected == 'female' ? 'tabMyGroup2' : 'tabMyGroup1';
            }
            this.setState(
                {
                    showModal: false,
                    editGroupData: null,
                    gptabselected: tabselected
                }, () => {
                    this.getGroups(tabsel);
                });
        }
    }

    yourBracketModal = () => {
        this.setState({
            bracketNameModal: !this.state.bracketNameModal
        }, () => {
            if (!this.state.bracketNameModal) {
                this.getAllBracket();
            }
        });
    }

    deleteBktModalOpen = (id, selectedTab) => {
        // e.preventDefault();
        this.setState({
            deleteBktModal: true,
            deleteId: id,
            selectedTab: selectedTab
        })
    }

    deleteBktModalClose = (data) => {
        this.setState({ deleteBktModal: false, deleteId: null }, () => {
            if (data) {
                let tab = false;
                if (this.state.selectedTab) {
                    tab = this.state.selectedTab == 'mens' ? 'tabBracket1' : 'tabBracket2'
                }
                this.getAllBracket(tab);
            }
        })
    };

    openGrpModal = (e, id, type = 'delete', gender) => {
        e.preventDefault();
        this.setState({ deleteGrpModal: true, groupdeleteId: id, deleteType: type, gpselectedTab: gender });
    }

    closeGrpModal = (data) => this.setState({ deleteGrpModal: false, groupdeleteId: null, editGroupData: null }, () => {
        if (data) {
            let tabsel = false;
            if (this.state.gpselectedTab) {
                tabsel = this.state.gpselectedTab == 'womens' ? 'tabMyGroup2' : 'tabMyGroup1';
            }
            this.getGroups(tabsel);
        }
    });

    openTransferModal = () => this.setState({ transferOwner: true, deleteGrpModal: false });
    closeTransferModal = (d) => {
        if (d) {
            this.setState({ transferOwner: false, deleteGrpModal: false, deleteBktModal: false, deleteId: null }, () => {
                let tabsel = false;
                if (this.state.gpselectedTab) {
                    tabsel = this.state.gpselectedTab == 'womens' ? 'tabMyGroup2' : 'tabMyGroup1';
                }
                this.getGroups(tabsel);
            })
        } else {
            this.setState({ transferOwner: false, deleteGrpModal: true })
        }
    };

    mensBracketTab1 = () => this.setState({ activeTabBracket: "tabBracket1" });
    womensBracketTab2 = () => this.setState({ activeTabBracket: "tabBracket2" });
    mensMyGroupTab1 = () => this.setState({ activeTabMyGroups: "tabMyGroup1" });
    womensMyGroupTab2 = () => this.setState({ activeTabMyGroups: "tabMyGroup2" });
    mensPublicGroupTab1 = () => this.setState(
        { activeTabPublicGroups: "tabPublicGroup1" }, () => {
            this.getPublicGroups();
        }
    );
    womensPublicGroupTab2 = () => this.setState(
        { activeTabPublicGroups: "tabPublicGroup2" }, () => {
            this.getPublicGroups();
        }
    );
    leaderBoardBtn = (e) => {
        e.preventDefault();
        this.setState({ leaderMemberTableList: "leaderBoardList" })
    }
    cleanSearch = () => {
        this.setState({
            searchVal: ''
        }, () => {
            if (this.state.activeTabPublicGroups === 'tabPublicGroup1') {
                this.setState({
                    publicGroupMaleData: {
                        ...this.state.publicGroupMaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups();
                });
            } else {
                this.setState({
                    publicGroupFemaleData: {
                        ...this.state.publicGroupFemaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups();
                });
            }
        })
    }
    searchValue = (e) => {
        let value = e.target.value;
        this.setState({
            searchVal: value
        })
        if (value && value.length > 1) {
            if (this.state.activeTabPublicGroups === 'tabPublicGroup1') {
                this.setState({
                    publicGroupMaleData: {
                        ...this.state.publicGroupMaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups(value);
                });
            } else {
                this.setState({
                    publicGroupFemaleData: {
                        ...this.state.publicGroupFemaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups(value);
                });
            }
        } else if (!value || value.length === 0) {
            if (this.state.activeTabPublicGroups === 'tabPublicGroup1') {
                this.setState({
                    publicGroupMaleData: {
                        ...this.state.publicGroupMaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups();
                });
            } else {
                this.setState({
                    publicGroupFemaleData: {
                        ...this.state.publicGroupFemaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups();
                });
            }
        }
    }
    memberListBtn = (e, id, type) => {
        e.preventDefault();
        this.setState({ loader: true });
        if (this.state.leaderMemberTableList.id === id && this.state.leaderMemberTableList.type === type) {
            this.setState({
                loader: false,
                memberList: [],
                leaderMemberTableList: {
                    id: '',
                    type: ''
                }
            });

        } else {
            GetData("groups/member?group_id=" + id).then((response) => {
                console.log("Response", response);
                if (response.status && response.data) {
                    this.setState({
                        loader: false,
                        memberList: response.data,
                        leaderMemberTableList: {
                            type: type,
                            id: id
                        }
                    });
                } else {
                    this.setState({
                        loader: false,
                        memberList: [],
                        leaderMemberTableList: {
                            type: type,
                            id: id
                        }
                    });
                }
            });
        }
    }

    getmemberList = (id) => {
        this.setState({ loader: true });
        GetData("groups/member?group_id=" + id).then((response) => {
            console.log("Response", response);
            if (response.status && response.data) {
                this.setState({
                    loader: false,
                    memberList: response.data,
                    leaderMemberTableList: id
                });
            } else {
                this.setState({
                    loader: false,
                    memberList: [],
                    leaderMemberTableList: id
                });
            }
        });

    }

    getBrackets = (t) => {
        this.setState({ loader: true });
        GetData("manage-user-bracket/bracketDetails/" + t).then((response) => {
            console.log("Response", response);
            if (response.status && response.data.bracketDetails.length === 0) {
                this.setState({ removeButton: true });
                this.setState({
                    loader: false,
                    isPartiallyFilledMens: response.data.isPartiallyFilledBracket.male,
                    isPartiallyFilledWomens: response.data.isPartiallyFilledBracket.female,
                    isBracketEditableMens: response.data.isBracketEditable,
                });
            } else {
                this.setState({ removeButton: false });
            }

        });
    };

    editGroup = (e, id, tab) => {
        e.preventDefault();
        let gd = [];
        gd = this.state.groupData.filter((d) => {
            if (d.id === id) {
                return true;
            }
        })
        this.setState({
            editGroupData: gd[0],
            selectedTab: tab
        }, () => {
            this.createYourGroupModal(true);
        })

    }

    closelogin = () => {
        this.setState({ importModal: false });
    };

    openBracketPage = () => {
        this.props.history.push("/" + this._currentBracket);
    };

    setBracketType = (type) => {
        this.setState({ currentBracket: type });
    };

    handleSelect = (key) => {
        // if (key === "leaderboard") {
        //     this.props.history.push("/leader-board-main/mens");
        // }
        if (key === 'my-groups' && this.state.currentTabSelected !== 'my-groups') {
            this.getGroups();
        }
        if (key === "public-groups" && this.state.currentTabSelected !== 'public-groups') {
            this.getPublicGroups();
        }
        this.setState({
            ...this.state,
            currentTabSelected: key
        })
    };

    cptoClipboard = (e, d) => {
        console.log(d)
        e && e.preventDefault();
        this.setState({
            showCopy: d.id
        })
        setTimeout(() => {
            this.setState({
                showCopy: false
            })
        }, 1000)
        window.Clipboard = (function (window, document, navigator) {
            var textArea,
                copy;

            function isOS() {
                return navigator.userAgent.match(/ipad|iphone/i);
            }

            function createTextArea(text) {
                textArea = document.createElement('textArea');
                textArea.value = text;
                document.body.appendChild(textArea);
            }

            function selectText() {
                var range,
                    selection;

                if (isOS()) {
                    range = document.createRange();
                    range.selectNodeContents(textArea);
                    selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    textArea.setSelectionRange(0, 999999);
                } else {
                    textArea.select();
                }
            }

            function copyToClipboard() {
                document.execCommand('copy');
                document.body.removeChild(textArea);
            }

            copy = function (text) {
                createTextArea(text);
                selectText();
                copyToClipboard();
            };

            return {
                copy: copy
            };
        })(window, document, navigator);

        // window.Clipboard.copy('text to be copied-121212');
        // navigator.clipboard.writeText(`${window.location.origin}/public-groups?name=${d.group_name}`);
        window.Clipboard.copy(`${window.location.origin}/public-groups?type=${d.tournament_type}&name=${d.group_name}`);
    }

    joinGroup = (e, d, edit = false) => {
        e && e.preventDefault();
        this.getAllBracket();
        this.setState({
            joinGroupModal: {
                show: true,
                hide: this.closeJoinGroupModal,
                group_id: d.id,
                edit: edit
            }
        }, () => {
            // this.getGroups();
        }
        );
    }
   handleClose = () => this.setState({tabModalShow:false});
   handleShow = () => this.setState({tabModalShow:true});

    render() {
        return (
          <Aux>
            <Loader show={this.state.loader} />
            <Menu />
            <div className="Maincontent invite-friend-wrap fillbracket">
              <div className="container-fluid">
                <Row>
                  <Col md="12">
                    <div className="leaderord headerAdvt">
                      <Placeadd />
                    </div>
                  </Col>
                </Row>
                <TimerBanner />
                <div className="invite-body-outer">
                  <Container fluid>
                    <Row>
                      <div className="col-12">
                        <Tabs
                          defaultActiveKey={
                            // this.state.currentTabSelected === "my-brackets" ? "my-brackets" :
                            this.props.match.params?.tabname &&
                            this.props.match.params?.tabname != "mens" &&
                            this.props.match.params?.tabname != "womens"
                              ? this.props.match.params.tabname
                              : "my-brackets"
                          }
                          activeKey={this.state.currentTabSelected}
                          transition={false}
                          onSelect={(e) => this.handleSelect(e)}
                        >
                          <Tab
                          // active={this.state.currentTabSelected === "my-brackets" && true}
                          // tabClassName={`${this?.state?.currentTabSelected === "my-brackets" ? "active":""}`}
                            className="tab1"
                            eventKey="my-brackets"
                            title="MY BRACKETS"
                          >
                            <div className="createbracket create-bkt-tab1">
                              {this.state.bracketData.length >= 0 && (
                                <div className="my-brackets-wrap">
                                  <div className="bracket-head-outer">
                                    <div className="my-bracket-head">
                                      My Brackets (
                                      <span>
                                        {this.state.activeTabBracket ===
                                        "tabBracket1"
                                          ? this.state.maleBracket.length
                                          : this.state.femaleBracket.length}
                                        /5
                                      </span>
                                      )
                                    </div>
                                    <div className="bracket-top-tab">
                                      <span
                                        onClick={this.mensBracketTab1}
                                        className={
                                          this.state.activeTabBracket ===
                                          "tabBracket1"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        MEN'S
                                      </span>
                                      <span
                                        onClick={this.womensBracketTab2}
                                        className={
                                          this.state.activeTabBracket ===
                                          "tabBracket2"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        WOMEN'S
                                      </span>
                                    </div>
                                    {!this.state.tournamentStart &&
                                    this.state.activeTabBracket ===
                                      "tabBracket1" &&
                                    this.state.maleBracket.length < 5 ? (
                                      <div className="create-bkt-top-button">
                                        <Button
                                          onClick={this.yourBracketModal}
                                          variant="primary"
                                          type="submit"
                                        >
                                          START CREATING BRACKET
                                        </Button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {!this.state.tournamentStart &&
                                    this.state.activeTabBracket ===
                                      "tabBracket2" &&
                                    this.state.femaleBracket.length < 5 ? (
                                      <div className="create-bkt-top-button">
                                        <Button
                                          onClick={this.yourBracketModal}
                                          variant="primary"
                                          type="submit"
                                        >
                                          START CREATING BRACKET
                                        </Button>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  {this.state.activeTabBracket ===
                                  "tabBracket1" ? (
                                    <div className="mens-container">
                                      {/* white box start */}
                                      {this.state.maleBracket.length
                                        ? this.state.maleBracket.map((d, i) => (
                                            <div className="white-box">
                                              <div className="white-box-inner">
                                                <div className="bracket-box-name">
                                                  <span className="name-inner">
                                                    {d.name}{" "}
                                                    {d.score
                                                      ? `( ${d.score} )`
                                                      : ""}{" "}
                                                    &nbsp;{" "}
                                                    <small className="small">
                                                      {d.bracketCompleted
                                                        ? `( Completed )`
                                                        : `( Incomplete )`}
                                                    </small>
                                                  </span>
                                                  <span className="mens-circle">
                                                    men's
                                                  </span>
                                                </div>
                                                <div className="right-btns">
                                                  {
                                                    d?.bracketCompleted && (
                                                  <ShareButton type="v2" url={d?.bracket_short_url} />
                                                    )
                                                  }
                                                  <Link
                                                    to={decodeURI(
                                                      `/mens-bracket/${d.name}/${d.id}`
                                                    )}
                                                    className="viewbtn"
                                                  >
                                                    View
                                                  </Link>
                                                  {!this.state
                                                    .tournamentStart ? (
                                                    <a
                                                      href="javascript:;"
                                                      onClick={(e) => {
                                                        this.deleteBktModalOpen(
                                                          d.id,
                                                          "mens"
                                                        );
                                                      }}
                                                      className="deletebtn"
                                                    >
                                                      Delete
                                                    </a>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))
                                        : "You have not created a bracket for the Men's Tournament"}

                                      {/* end */}
                                    </div>
                                  ) : (
                                    <div className="womens-container">
                                      {this.state.femaleBracket.length
                                        ? this.state.femaleBracket.map(
                                            (d, i) => (
                                              <div className="white-box">
                                                <div className="white-box-inner">
                                                  <div className="bracket-box-name">
                                                    <span className="name-inner">
                                                      {d.name}{" "}
                                                      {d.score
                                                        ? `( ${d.score} )`
                                                        : ""}{" "}
                                                      &nbsp;{" "}
                                                      <small className="small">
                                                        {d.bracketCompleted
                                                          ? `( Completed )`
                                                          : `( Incomplete )`}
                                                      </small>
                                                    </span>
                                                    <span className="womens-circle">
                                                      Women's
                                                    </span>
                                                  </div>
                                                  <div className="right-btns">
                                                    {
                                                      d?.bracketCompleted && (
                                                        <ShareButton type="v2" url={d?.bracket_short_url} />
                                                      )
                                                    }
                                                  
                                                    <Link
                                                      to={decodeURI(
                                                        `/womens-bracket/${d.name}/${d.id}`
                                                      )}
                                                      className="viewbtn"
                                                    >
                                                      View
                                                    </Link>
                                                    {!this.state
                                                      .tournamentStart ? (
                                                      <a
                                                        href="javascript:;"
                                                        onClick={(e) => {
                                                          this.deleteBktModalOpen(
                                                            d.id,
                                                            "womens"
                                                          );
                                                        }}
                                                        className="deletebtn"
                                                      >
                                                        Delete
                                                      </a>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )
                                        : "You have not created a bracket for the Women's Tournament"}

                                      {/* end */}
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="inner-body">
                                {this.state.bracketData.length < 10 &&
                                "a" === "b" ? (
                                  <>
                                    <h1>
                                      {this.state.isBracketEditableMens
                                        ? "Build"
                                        : "Build"}{" "}
                                      Your Bracket
                                    </h1>
                                    <p></p>
                                    <h3>Select Tournament</h3>
                                    <div className="gendertornament">
                                      <div
                                        className={
                                          !this.state.maleBracketEnable
                                            ? "disabled button1"
                                            : "button1"
                                        }
                                      >
                                        <button
                                          onClick={() =>
                                            this.state.maleBracketEnable
                                              ? this.setBracketType("male")
                                              : ""
                                          }
                                          className={
                                            this.state.currentBracket === "male"
                                              ? "btn btn-mentournament active"
                                              : "btn btn-mentournament"
                                          }
                                        >
                                          <img src={men} alt="deafult" />
                                          MEN’S Tournament
                                        </button>
                                      </div>
                                      <div
                                        className={
                                          !this.state.femaleBracketEnable
                                            ? "disabled button2"
                                            : "button2"
                                        }
                                      >
                                        <button
                                          onClick={() =>
                                            this.state.femaleBracketEnable
                                              ? this.setBracketType("female")
                                              : ""
                                          }
                                          className={
                                            this.state.currentBracket ===
                                            "female"
                                              ? "btn btn-womentournament active"
                                              : "btn btn-womentournament"
                                          }
                                        >
                                          <img src={women} alt="deafult" />
                                          WOMEN’S Tournament
                                        </button>
                                      </div>
                                    </div>
                                    <div className="startbtn">
                                      {this.state.removeButton ? (
                                        <div
                                          style={{ color: "red", fontSize: 20 }}
                                        >
                                          The bracket challenge is now closed.
                                        </div>
                                      ) : (
                                        <Bluebtn
                                          value="START CREATING BRACKET"
                                          type="submit"
                                          onClick={this.yourBracketModal}
                                        />
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="my-groups" title="MY GROUPS" 
                          // tabClassName={`${this?.state?.currentTabSelected === "my-groups" && this?.state?.currentTabSelected !== "my-brackets"? "active":""}`}
                          // className={`${this?.state?.currentTabSelected === "my-groups" ? "active":""}`}
                          // // disabled={this.state.bracketData.length === 0  && true}
                          // active={this?.state?.currentTabSelected === "my-groups" && true}
                          >
                           {this.state.bracketData.length === 0 && this.state.currentTabSelected==="my-groups" ? 
                            <Aux>
                                            <Modal
                                            // show={this.state.tabModalShow}
                                            show={ this.state.currentTabSelected === "my-groups" && true}
                                            centered
                                            onHide={this.handleClose}
                                            animation={false}
                                            className="modal-style-groups"
                                          >
                                           
                                            <Modal.Body className="modal-small">
                                              <div className="delete-grp-text">
                                                Please create the brackets before making groups 
                                              </div>
                                              <div className="footer-btn footer-btn-delete-modal">
                                                <Button
                                                  onClick={() => {
                                                              // this.props.history.push("/create-brackets");
                                                              this.setState({currentTabSelected:"my-brackets"})
                                                              this.handleClose()
                                                  }}
                                                  variant="primary"
                                                  type="submit"
                                                >
                                                    Ok
                                                </Button>
                                                <Button
                                                  className="cancel-btn transfer-btn"
                                                  onClick={() => {
                                                    // props.hide(true);
                                                    this.setState({currentTabSelected:"my-brackets"})
                                                    this.handleClose();
                                                        // this.props.history.push("/create-brackets");
                                                  }}
                                                >
                                                  Cancel
                                                </Button>
                                              </div>
                                            </Modal.Body>
                                          </Modal>
                                        </Aux>
                           :
                           
                            <div className="my-groups-wrap createbracket">
                              <div className="my-brackets-wrap">
                                {this.state.groupData.length > 0 && (
                                  <div className="bracket-head-outer">
                                    <div className="bracket-top-tab">
                                      <span
                                        onClick={this.mensMyGroupTab1}
                                        className={
                                          this.state.activeTabMyGroups ===
                                          "tabMyGroup1"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        MEN'S
                                      </span>
                                      <span
                                        onClick={this.womensMyGroupTab2}
                                        className={
                                          this.state.activeTabMyGroups ===
                                          "tabMyGroup2"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        WOMEN'S
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {this.state.activeTabMyGroups ===
                                "tabMyGroup1" ? (
                                  <div className="mens-container-my-group">
                                    {/* white box start */}
                                    {this.state.groupDataMale.length ? (
                                      this.state.groupDataMale.map((d) => (
                                        <div className="white-box">
                                          <div className="white-box-inner">
                                            <div className="my-group-left">
                                              <div className="group-left-inner">
                                                <div className="mygroup-logo">
                                                  <img
                                                    src={
                                                      d.group_image
                                                        ? `${this.state.s3URL}${d.group_image}`
                                                        : MyGroupLogo
                                                    }
                                                    alt="Logo"
                                                  />
                                                </div>
                                                <div className="bracket-box-name">
                                                  <span className="name-inner">
                                                    {d.group_name}
                                                    {d.is_admin &&
                                                    d.is_admin > 0 ? (
                                                      <span className="admin-style">
                                                        admin
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                  <div className="public-logo-wrap">
                                                    <span className="mens-circle">
                                                      men's
                                                    </span>
                                                    <div className="public-icon">
                                                      <img
                                                        src={
                                                          d.is_public
                                                            ? PublicIcon
                                                            : PrivateIcon
                                                        }
                                                        alt="Icon"
                                                      />
                                                      {d.is_public
                                                        ? "Public"
                                                        : "Private"}{" "}
                                                      <span>
                                                        (
                                                        {d.member_count
                                                          ? d.member_count
                                                          : 0}
                                                        )
                                                      </span>
                                                    </div>
                                                  </div>

                                                  <div className="group-text">
                                                    {d.group_description}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="right-btns">
                                              <span className="mem-lead-group">
                                                {d.is_admin > 0 && (
                                                  <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                      this.memberListBtn(
                                                        e,
                                                        d.id,
                                                        "memberlist"
                                                      )
                                                    }
                                                    className={
                                                      this.state
                                                        .leaderMemberTableList
                                                        .id === d.id &&
                                                      this.state
                                                        .leaderMemberTableList
                                                        .type === "memberlist"
                                                        ? "member-btn active-btn"
                                                        : "member-btn"
                                                    }
                                                  >
                                                    Member List
                                                  </Link>
                                                )}
                                                {!this.state.tournamentStart &&
                                                  d.is_admin === 0 && (
                                                    <Link
                                                      to="#"
                                                      className="member-btn"
                                                      onClick={(e) =>
                                                        this.joinGroup(
                                                          e,
                                                          d,
                                                          true
                                                        )
                                                      }
                                                    >
                                                      Add Bracket
                                                    </Link>
                                                  )}
                                                <Link
                                                  to="#"
                                                  onClick={(e) =>
                                                    this.memberListBtn(
                                                      e,
                                                      d.id,
                                                      "leaderboard"
                                                    )
                                                  }
                                                  className={
                                                    this.state
                                                      .leaderMemberTableList
                                                      .id === d.id &&
                                                    this.state
                                                      .leaderMemberTableList
                                                      .type === "leaderboard"
                                                      ? "member-btn active-btn"
                                                      : "member-btn"
                                                  }
                                                >
                                                  Leaderboard
                                                </Link>
                                              </span>
                                              <span className="edit-dt-group">
                                                {!this.state.tournamentStart &&
                                                  d.is_admin > 0 && (
                                                    <Link
                                                      to="#"
                                                      className="viewbtn"
                                                      onClick={(e) =>
                                                        this.editGroup(
                                                          e,
                                                          d.id,
                                                          "mens"
                                                        )
                                                      }
                                                    >
                                                      Edit
                                                    </Link>
                                                  )}
                                                {d.is_admin > 0 && (
                                                  <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                      this.openGrpModal(
                                                        e,
                                                        d.id,
                                                        "delete",
                                                        "mens"
                                                      )
                                                    }
                                                    className="deletebtn"
                                                  >
                                                    Delete
                                                  </Link>
                                                )}
                                                {!this.state.tournamentStart &&
                                                  d.is_admin === 0 && (
                                                    <Link
                                                      to="#"
                                                      onClick={(e) =>
                                                        this.openGrpModal(
                                                          e,
                                                          d.id,
                                                          "leave",
                                                          "mens"
                                                        )
                                                      }
                                                      className="deletebtn"
                                                    >
                                                      Leave
                                                    </Link>
                                                  )}
                                              </span>
                                            </div>
                                          </div>
                                          {this.state.leaderMemberTableList
                                            .id === d.id &&
                                          this.state.leaderMemberTableList
                                            .type === "memberlist" ? (
                                            <MemberListTable
                                              memberList={this.state.memberList}
                                              groupData={d}
                                              getmemberList={this.getmemberList}
                                            />
                                          ) : null}
                                          {this.state.leaderMemberTableList
                                            .id === d.id &&
                                          this.state.leaderMemberTableList
                                            .type === "leaderboard" ? (
                                            <LeaderBoardTable groupData={d} />
                                          ) : null}
                                        </div>
                                      ))
                                    ) : (
                                      "You have not created or joined a group."
                                    )}

                                    {/* end */}
                                  </div>
                                ) : (
                                  <div className="womens-container-my-group">
                                    {this.state.groupDataFemale.length
                                      ? this.state.groupDataFemale.map((d) => (
                                          <div className="white-box">
                                            <div className="white-box-inner">
                                              <div className="my-group-left">
                                                <div className="group-left-inner">
                                                  <div className="mygroup-logo">
                                                    <img
                                                      src={
                                                        d.group_image
                                                          ? `${this.state.s3URL}${d.group_image}`
                                                          : MyGroupLogo
                                                      }
                                                      alt="Logo"
                                                    />
                                                  </div>
                                                  <div className="bracket-box-name">
                                                    <span className="name-inner">
                                                      {d.group_name}
                                                      {d.is_admin ? (
                                                        <span className="admin-style">
                                                          admin
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                    <div className="public-logo-wrap">
                                                      <span className="womens-circle">
                                                        Women's
                                                      </span>
                                                      <div className="public-icon">
                                                        <img
                                                          src={
                                                            d.is_public
                                                              ? PublicIcon
                                                              : PrivateIcon
                                                          }
                                                          alt="Icon"
                                                        />
                                                        {d.is_public
                                                          ? "Public"
                                                          : "Private"}{" "}
                                                        <span>
                                                          (
                                                          {d.member_count
                                                            ? d.member_count
                                                            : 0}
                                                          )
                                                        </span>
                                                      </div>
                                                    </div>

                                                    <div className="group-text">
                                                      {d.group_description}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="right-btns">
                                                <span className="mem-lead-group">
                                                  {d.is_admin > 0 && (
                                                    <Link
                                                      to="#"
                                                      onClick={(e) =>
                                                        this.memberListBtn(
                                                          e,
                                                          d.id,
                                                          "memberlist"
                                                        )
                                                      }
                                                      className={
                                                        this.state
                                                          .leaderMemberTableList
                                                          .id === d.id &&
                                                        this.state
                                                          .leaderMemberTableList
                                                          .type === "memberlist"
                                                          ? "member-btn active-btn"
                                                          : "member-btn"
                                                      }
                                                    >
                                                      Member List
                                                    </Link>
                                                  )}
                                                  {!this.state
                                                    .tournamentStart &&
                                                    d.is_admin === 0 && (
                                                      <Link
                                                        to="#"
                                                        className="member-btn"
                                                        onClick={(e) =>
                                                          this.joinGroup(
                                                            e,
                                                            d,
                                                            true
                                                          )
                                                        }
                                                      >
                                                        Add Bracket
                                                      </Link>
                                                    )}
                                                  <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                      this.memberListBtn(
                                                        e,
                                                        d.id,
                                                        "leaderboard"
                                                      )
                                                    }
                                                    className={
                                                      this.state
                                                        .leaderMemberTableList
                                                        .id === d.id &&
                                                      this.state
                                                        .leaderMemberTableList
                                                        .type === "leaderboard"
                                                        ? "member-btn active-btn"
                                                        : "member-btn"
                                                    }
                                                  >
                                                    Leaderboard
                                                  </Link>
                                                </span>
                                                <span className="edit-dt-group">
                                                  {!this.state
                                                    .tournamentStart &&
                                                    d.is_admin > 0 && (
                                                      <Link
                                                        to="#"
                                                        className="viewbtn"
                                                        onClick={(e) =>
                                                          this.editGroup(
                                                            e,
                                                            d.id,
                                                            "womens"
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </Link>
                                                    )}
                                                  {d.is_admin > 0 && (
                                                    <Link
                                                      to="#"
                                                      onClick={(e) =>
                                                        this.openGrpModal(
                                                          e,
                                                          d.id,
                                                          "delete",
                                                          "womens"
                                                        )
                                                      }
                                                      className="deletebtn"
                                                    >
                                                      Delete
                                                    </Link>
                                                  )}
                                                  {!this.state
                                                    .tournamentStart &&
                                                    d.is_admin === 0 && (
                                                      <Link
                                                        to="#"
                                                        onClick={(e) =>
                                                          this.openGrpModal(
                                                            e,
                                                            d.id,
                                                            "leave",
                                                            "womens"
                                                          )
                                                        }
                                                        className="deletebtn"
                                                      >
                                                        Leave
                                                      </Link>
                                                    )}
                                                </span>
                                              </div>
                                            </div>
                                            {this.state.leaderMemberTableList
                                              .id === d.id &&
                                            this.state.leaderMemberTableList
                                              .type === "memberlist" ? (
                                              <MemberListTable
                                                memberList={
                                                  this.state.memberList
                                                }
                                                groupData={d}
                                                getmemberList={
                                                  this.getmemberList
                                                }
                                              />
                                            ) : null}
                                            {this.state.leaderMemberTableList
                                              .id === d.id &&
                                            this.state.leaderMemberTableList
                                              .type === "leaderboard" ? (
                                              <LeaderBoardTable groupData={d} />
                                            ) : null}
                                          </div>
                                        ))
                                      : "You have not created or joined a group."}

                                    {/* end */}
                                  </div>
                                )}
                              </div>
                              {!this.state.tournamentStart &&
                              this.state.createGroupEnable ? (
                                <>
                                  <div className="inner-body">
                                    <h1>Create your group</h1>
                                    <div className="startbtn">
                                      {this.state.removeButton === true ? (
                                        <div
                                          style={{ color: "red", fontSize: 20 }}
                                        >
                                          The bracket challenge is now closed.
                                        </div>
                                      ) : (
                                        <Bluebtn
                                          value={"START CREATING GROUP"}
                                          type="submit"
                                          onClick={this.createYourGroupModal}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
    }
                          </Tab>
                          <Tab eventKey="public-groups" title="PUBLIC GROUPS">
                            <div className="public-groups-wrap createbracket">
                              <div className="my-brackets-wrap">
                                
                                <div className="bracket-head-outer">
                                  <div className="my-bracket-head">
                                    PUBLIC GROUPS
                                  </div>
                                  <div className="bracket-top-tab">
                                    <span
                                      onClick={this.mensPublicGroupTab1}
                                      className={
                                        this.state.activeTabPublicGroups ===
                                        "tabPublicGroup1"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      MEN'S
                                    </span>
                                    <span
                                      onClick={this.womensPublicGroupTab2}
                                      className={
                                        this.state.activeTabPublicGroups ===
                                        "tabPublicGroup2"
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      WOMEN'S
                                    </span>
                                  </div>
                                  <div className="search-box">
                                    <Form.Control
                                      type="text"
                                      onChange={(e) => {
                                        this.searchValue(e);
                                      }}
                                      className={
                                        this.state.searchVal
                                          ? "search-group focus"
                                          : "search-group"
                                      }
                                      placeholder="Search..."
                                      value={this.state.searchVal}
                                    />
                                    <button type="button" className="btn">
                                      <svg width="15px" height="15px">
                                        <path d="M11.618 9.897l4.224 4.212c.092.09.1.23.02.312l-1.464 1.46c-.08.08-.222.072-.314-.02L9.868 11.66M6.486 10.9c-2.42 0-4.38-1.955-4.38-4.367 0-2.413 1.96-4.37 4.38-4.37s4.38 1.957 4.38 4.37c0 2.412-1.96 4.368-4.38 4.368m0-10.834C2.904.066 0 2.96 0 6.533 0 10.105 2.904 13 6.486 13s6.487-2.895 6.487-6.467c0-3.572-2.905-6.467-6.487-6.467 "></path>
                                      </svg>
                                    </button>
                                    <div
                                      className={
                                        this.state.searchVal
                                          ? "cross"
                                          : "cross hide"
                                      }
                                    >
                                      <img
                                        onClick={() => {
                                          this.cleanSearch();
                                        }}
                                        src={CrossBtn}
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="public-group-box-outer">
                                  {this.state.activeTabPublicGroups ===
                                  "tabPublicGroup1" ? (
                                    <div className="mens-container">
                                      {/* white box start */}
                                      {this.state.publicGroupMaleData.count  === 0 && "No Public Group Data to display"}
                                      {this.state.publicGroupMaleData.rows &&
                                        this.state.publicGroupMaleData.rows.map(
                                          (d) => (
                                            <div className="white-box">
                                              <div className="white-box-inner">
                                                <div className="my-group-left">
                                                  <div className="group-left-inner">
                                                    <div className="mygroup-logo">
                                                      <img
                                                        src={
                                                          d.group_image
                                                            ? `${this.state.s3URL}${d.group_image}`
                                                            : MyGroupLogo
                                                        }
                                                        alt="Logo"
                                                      />
                                                    </div>
                                                    <div className="bracket-box-name">
                                                      <span className="name-inner">
                                                        {d.group_name
                                                          ? d.group_name
                                                          : ""}
                                                        {d.is_admin ? (
                                                          <span className="admin-style">
                                                            admin
                                                          </span>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                      <div className="public-logo-wrap">
                                                        <span className="mens-circle">
                                                          men's
                                                        </span>
                                                        <div className="public-icon">
                                                          <img
                                                            src={
                                                              d.is_public
                                                                ? PublicIcon
                                                                : PrivateIcon
                                                            }
                                                            alt="Icon"
                                                          />
                                                          {d.is_public
                                                            ? "Public"
                                                            : "Private"}{" "}
                                                          <span>
                                                            (
                                                            {d.member_count
                                                              ? d.member_count
                                                              : 0}
                                                            )
                                                          </span>
                                                        </div>
                                                      </div>

                                                      <div className="group-text">
                                                        {d.group_description
                                                          ? d.group_description
                                                          : ""}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="right-btns">
                                                  <span
                                                    className="copy-icon"
                                                    title="Copy Link"
                                                    onClick={(e) => {
                                                      this.cptoClipboard(e, d);
                                                    }}
                                                  >
                                                    <img
                                                      src={CopyIcon}
                                                      alt="copy"
                                                    />
                                                    {this.state.showCopy ==
                                                    d.id ? (
                                                      <span className="copied-text">
                                                        Copied
                                                      </span>
                                                    ) : null}
                                                  </span>
                                                  {this.state.tournamentStart ||
                                                  d.is_joined ? (
                                                    <>
                                                      <span className="mem-lead-group">
                                                        <Link
                                                          to="#"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                          }}
                                                          className="member-btn disabled"
                                                        >
                                                          Join Group
                                                        </Link>
                                                      </span>{" "}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <span className="mem-lead-group">
                                                        <Link
                                                          to="#"
                                                          onClick={(e) => {
                                                            this.joinGroup(
                                                              e,
                                                              d
                                                            );
                                                          }}
                                                          className="member-btn"
                                                        >
                                                          Join Group
                                                        </Link>
                                                      </span>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  ) : (
                                    <div className="womens-container">
                                    {this.state.publicGroupFemaleData.count  === 0 && "No Public Group Data to display"}

                                      {this.state.publicGroupFemaleData.rows &&
                                        this.state.publicGroupFemaleData.rows.map(
                                          (d) => (
                                            <div className="white-box">
                                              <div className="white-box-inner">
                                                <div className="my-group-left">
                                                  <div className="group-left-inner">
                                                    <div className="mygroup-logo">
                                                      <img
                                                        src={
                                                          d.group_image
                                                            ? `${this.state.s3URL}${d.group_image}`
                                                            : MyGroupLogo
                                                        }
                                                        alt="Logo"
                                                      />
                                                    </div>
                                                    <div className="bracket-box-name">
                                                      <span className="name-inner">
                                                        {d.group_name
                                                          ? d.group_name
                                                          : ""}
                                                        {d.is_admin ? (
                                                          <span className="admin-style">
                                                            admin
                                                          </span>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </span>
                                                      <div className="public-logo-wrap">
                                                        <span className="womens-circle">
                                                          women's
                                                        </span>
                                                        <div className="public-icon">
                                                          <img
                                                            src={
                                                              d.is_public
                                                                ? PublicIcon
                                                                : PrivateIcon
                                                            }
                                                            alt="Icon"
                                                          />
                                                          {d.is_public
                                                            ? "Public"
                                                            : "Private"}{" "}
                                                          <span>
                                                            (
                                                            {d.member_count
                                                              ? d.member_count
                                                              : 0}
                                                            )
                                                          </span>
                                                        </div>
                                                      </div>

                                                      <div className="group-text">
                                                        {d.group_description
                                                          ? d.group_description
                                                          : ""}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="right-btns">
                                                  <span
                                                    className="copy-icon"
                                                    title="Copy Link"
                                                    onClick={(e) => {
                                                      this.cptoClipboard(e, d);
                                                    }}
                                                  >
                                                    <img
                                                      src={CopyIcon}
                                                      alt="copy"
                                                    />
                                                    {this.state.showCopy ==
                                                    d.id ? (
                                                      <span className="copied-text">
                                                        Copied
                                                      </span>
                                                    ) : null}
                                                  </span>
                                                  {this.state.tournamentStart ||
                                                  d.is_joined ? (
                                                    <>
                                                      <span className="mem-lead-group">
                                                        <Link
                                                          to="#"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                          }}
                                                          className="member-btn disabled"
                                                        >
                                                          Join Group
                                                        </Link>
                                                      </span>{" "}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <span className="mem-lead-group">
                                                        <Link
                                                          to="#"
                                                          onClick={(e) => {
                                                            this.joinGroup(
                                                              e,
                                                              d
                                                            );
                                                          }}
                                                          className="member-btn"
                                                        >
                                                          Join Group
                                                        </Link>
                                                      </span>
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}

                                      {/* end */}
                                    </div>
                                  )}
                                </div>

                                <div className="pagination-group">
                                  <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    onPageChange={this.changeLimit}
                                    pageRangeDisplayed={4}
                                    pageCount={
                                      this.state.activeTabPublicGroups ===
                                      "tabPublicGroup1"
                                        ? this.state.publicGroupMaleData.count %
                                            4 ===
                                          0
                                          ? parseInt(
                                              this.state.publicGroupMaleData
                                                .count / 4
                                            )
                                          : parseInt(
                                              this.state.publicGroupMaleData
                                                .count / 4
                                            ) + 1
                                        : this.state.publicGroupFemaleData
                                            .count %
                                            4 ===
                                          0
                                        ? parseInt(
                                            this.state.publicGroupFemaleData
                                              .count / 4
                                          )
                                        : parseInt(
                                            this.state.publicGroupFemaleData
                                              .count / 4
                                          ) + 1
                                    }
                                    previousLabel="prev"
                                    renderOnZeroPageCount={null}
                                  />
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="leaderboard" title="LEADERBOARD">
                            <div className="public-groups-wrap createbracket">
                              <div className="my-brackets-wrap leader-board-wrap">
                                <LeaderBoardMain
                                  subPage={true}
                                  gender={this?.props?.match?.params?.gender}
                                />
                              </div>
                            </div>
                          </Tab>

                          {/* <Tab eventKey="leaderboard" title="LEADERBOARD">
                        <div className="leaderboard"></div>
                      </Tab> */}
                        </Tabs>
                      </div>
                    </Row>
                  </Container>
                </div>
                <Placeaddsm />
              </div>
            </div>

            {/* Create your group modal */}
            <CreateGroupModal
              show={this.state.showModal}
              hide={this.closeYourGroupModal}
              bracketData={this.state.bracketData}
              editGroupData={this.state.editGroupData}
              s3URL={this.state.s3URL}
              randomValue={Math.random()}
            />
            {/* Join group your group modal */}
            <JoinGroupModal
              {...this.state.joinGroupModal}
              bracketData={this.state.bracketData}
              editGroupData={this.state.editGroupData}
              selectedGender={
                this.state.currentTabSelected == "my-groups"
                  ? this.state.activeTabMyGroups === "tabMyGroup1"
                    ? "male"
                    : "female"
                  : this.state.activeTabPublicGroups === "tabPublicGroup1"
                  ? "male"
                  : "female"
              }
            />
            {/* Bracket Name Modal */}
            <CreateBracketModal
              show={this.state.bracketNameModal}
              hide={this.yourBracketModal}
              type={
                this.state.activeTabBracket === "tabBracket1"
                  ? "male"
                  : "female"
              }
            />
            {/* Delete Bracket */}
            <DeleteBracket
              show={this.state.deleteBktModal}
              // show2={this.openTransferModal}
              hide={this.deleteBktModalClose}
              deleteId={this.state.deleteId}
            />

            <DeleteGroup
              show={this.state.deleteGrpModal}
              type={this.state.deleteType}
              show2={this.openTransferModal}
              hide={this.closeGrpModal}
              deleteId={this.state.groupdeleteId}
            />

            <TransferModal
              show={this.state.transferOwner}
              hide={this.closeTransferModal}
              groupId={this.state.groupdeleteId}
            />
          </Aux>
        );
    }
}
export default withRouter(CreateBracket);
