import React, { Component } from "react";
import { Row } from "react-bootstrap";
import GoogleAd from './GoogleAdsense'

class Placeaddsm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="small-adds-outer" style={{display:'none'}}>
        <Row>
          <div className="col-12">
            <div className="placeleaderbox">
            <div className="box">
          <GoogleAd slot="1065601590" classNames="page-top" />
        </div>
        <div className="box">
          <GoogleAd slot="4585495460" classNames="page-top" />
        </div>
        <div className="box">
          <GoogleAd slot="4010780398" classNames="page-top" />
        </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

export default Placeaddsm;
