import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import { GetData, PostData } from "../../utils/apiRequestHandler";

const loginclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function DeleteBracket(props){
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMsg, seterrorMsg] = useState('');
  const deleteBracket = () => {
    setShowSpinner(true);
    let bid = props.deleteId;
    GetData(`manage-user-bracket/deleteBracketById/${bid}`).then((response) => {
      setShowSpinner(false);
      if (response.status) {
          props.hide && props.hide(response.status);
      } else {
        seterrorMsg(response.message);
      }
  });
  }




  return(
    <Aux>
        <Modal
          show={props.show}
          centered
          onHide={props.hide}
          animation={false}
          className="modal-style-groups"
        >
        <Modal.Header closeButton className="header-style-modal">
            <Button onClick={props.hide} className="btn closebtn">
              <img src={loginclose} alt="deafult" />
            </Button>
            <Modal.Title>DELETE BRACKET</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-small">
            <div className="delete-grp-text">
                Are you sure you want to delete this Bracket?
            </div>  
            <div>{errorMsg}</div>
            <div className="footer-btn footer-btn-delete-modal">
              <Button
                onClick={()=> {deleteBracket()}}
                variant="primary"
                type="submit"
              >
                {showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                DELETE
              </Button>
              <Button className="cancel-btn transfer-btn"
                onClick={props.hide}
              >
                CANCEL
              </Button>
            </div>
          </Modal.Body>
         
        </Modal>
    </Aux>
  )
}