import React, { useEffect, useState, useRef } from 'react';
import Aux from "../hoc/_Aux";
import { withRouter, Link } from "react-router-dom";
import TeamUserImg from "../assets/images/team-user-img.jpg";
import { GetData, PostData } from "../utils/apiRequestHandler";
import LeaderBoardRoundWiseScore from "./LeaderBoardRoundWiseScore";

function LeaderBoardTable(props) {
    const [list, setlist] = useState([]);
    const [start, setstart] = useState(0);
    const [lastrank, setlastrank] = useState(0);
    const [lastscore, setlastscore] = useState(0);
    const [loadMore, setloadMore] = useState(false);
    const [tournamentStart, settournamentStart] = useState(true);
    const [adminPage, setadminPage] = useState(props.adminPage ? props.adminPage : false);


    const outerSection = useRef(null);

    const getTopRanks = () => {
        if (props.groupData && props.groupData.id) {
            let url = `groups/getTopRanks?group_id=${props.groupData.id}&gender=${props.groupData.tournament_type}&start=${start}&end=${start + 10}&lastrank=${lastrank}&lastscore=${lastscore}`;
            GetData(url).then((response) => {
                console.log("Response", response);
                if (response.status && response.data && response.data.allRank) {
                    let allRank = response.data.allRank;
                    setlist([...allRank])
                    if (allRank.length) {
                        setlastrank(allRank.rank);
                        setlastscore(allRank.score);
                        setloadMore(true);
                        setstart(start + 10);
                    }

                } else {
                    setloadMore(true);
                }
            });
        }

    }

    const onScrollEvent = () => {
        let element = outerSection.current
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            loadMore && getTopRanks();
        }
    }
    useEffect(() => {
        let tournamenttime = JSON.parse(localStorage.getItem("tournamenttime"));
        if (tournamenttime) {
            settournamentStart(tournamenttime.gameStarted)
        }
        setlist([]);
        getTopRanks();

        return (() => {
            setlastrank(0);
            setlastscore(0);
            setloadMore(false);
            setstart(0);
        })
    }, [props])

    useEffect(() => {
        outerSection && outerSection.current.addEventListener('scroll', onScrollEvent)
        return (() => {
            outerSection && outerSection.current && outerSection.current.removeEventListener('scroll', onScrollEvent, true);
        })
    }, [outerSection])

    return (
        adminPage ? (
            <Aux>
                <div className="white-box-bottom">
                    <div className="whitebox-leaderboard">
                        <div className="leader-table-right" style={{'width':'100%'}}>
                            <div className="leaderboard-table leaderboard-table-team">
                                <table>
                                    <thead>
                                        <tr>
                                            <th><span>Rank</span></th>
                                            <th><span>Username</span></th>
                                            <th><span>Champion</span></th>
                                            <th><span>Score</span></th>
                                        </tr>
                                    </thead>
                                </table>
                                <div className="table-height" ref={outerSection}>
                                    <table className="table-style mb-4">
                                        <tbody>
                                            {
                                                tournamentStart ? list.map((d) =>
                                                    <tr>
                                                        <td>{d.rank}</td>
                                                        <td>
                                                            <div className="user-team-box leader-board-bktname">
                                                                {/* <div className="usr-team-img"><img src={TeamUserImg} alt="image" /></div> */}
                                                                {
                                                                    d.bracket_name ? <span className="bracket-name">{d.bracket_name}</span> : ''
                                                                }
                                                                <span>{d.userName}</span>
                                                            </div>
                                                        </td>
                                                        <td>{d.winner_team}</td>
                                                        <td>{d.score ? d.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</td>
                                                    </tr>
                                                ) : <><tr><td colSpan={4}></td></tr><tr><td colSpan={4} align="center" style={{textAlign:"center"}}>
                                                    {tournamentStart ? 'No Data Available' : 'The tournament has not started yet'}
                                                </td></tr></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Aux>
        ) : (
            <Aux>
                <div className="white-box-bottom">
                    <div className="whitebox-leaderboard">
                        <div className="leader-table-left">
                            <LeaderBoardRoundWiseScore
                                groupData={props.groupData}
                            />
                        </div>
                        <div className="leader-table-right">
                            <div className="leaderboard-table leaderboard-table-team">
                                <table>
                                    <thead>
                                        <tr>
                                            <th><span>Rank</span></th>
                                            <th><span>Username</span></th>
                                            <th><span>Champion</span></th>
                                            <th><span>Score</span></th>
                                        </tr>
                                    </thead>
                                </table>
                                <div className="table-height" ref={outerSection}>
                                    <table className="table-style mb-4">
                                        <tbody>
                                            {
                                                tournamentStart ? list.map((d) =>
                                                    <tr>
                                                        <td>{d.rank}</td>
                                                        <td>
                                                            <div className="user-team-box leader-board-bktname">
                                                                {/* <div className="usr-team-img"><img src={TeamUserImg} alt="image" /></div> */}
                                                                {
                                                                    d.bracket_name ? <span className="bracket-name">{d.bracket_name}</span> : ''
                                                                }
                                                                <span>{d.userName}</span>
                                                            </div>
                                                        </td>
                                                        <td>{d.winner_team}</td>
                                                        <td>{d.score ? d.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}</td>
                                                    </tr>
                                                ) : <><tr><td colSpan={4}></td></tr><tr><td colSpan={4} align='center' style={{textAlign:"center"}}>
                                                    {tournamentStart ? 'No Data Available' : 'The tournament has not started yet'}
                                                </td></tr></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Aux>
        )

    )
}
export default LeaderBoardTable;