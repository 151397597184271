import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import { globalVariable } from "../../utils/global";
import { consts } from "../../utils/validation";
import UploadLogo from "../../assets/images/logo-upload.jpg";
import { GetData } from "../../utils/apiRequestHandler";

const Openeye = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const Closeye = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";
const loginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function TransferModal(props) {

  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState([]);
  const [userId, setUserId] = useState(0);
  const [successMsg, setsuccessMsg] = useState('');
  const [errorMsg, seterrorMsg] = useState('');

  
  const getnonAdminMember = () => {
    setLoader(false);
    let groupId = props.groupId ? props.groupId : '';
    if( groupId ) {
      GetData(`groups/member/memberListWithOutGroup?group_id=${groupId}`).then((response) => {
        console.log("Response", response);
        if (response.status && response.data ) {
          setOptions(response.data);
        } else {
          seterrorMsg('No user available to transfer this group');
          setOptions([]);
        }
      });
    }
  }

  const transferGroup = () => {
    setsuccessMsg('');
    seterrorMsg('');
    let groupId = props.groupId ? props.groupId : '';
    if( userId && groupId ) {
      GetData(`groups/update/transferGroup?user_id=${userId}&group_id=${groupId}`).then((response) => {
        console.log("Response", response);
        if (response.status && response.data ) {
          setsuccessMsg(response.message);
          setTimeout(() => {
            props.hide && props.hide(true);
          },1500);
        } else {
         seterrorMsg(response.message);
        }
      });
    }
  }

  useEffect(() => {
    setUserId(0);
    setsuccessMsg('');
    seterrorMsg('');
    getnonAdminMember();
  }, [props])

  return (
    <Aux>
      <Loader show={loader} />
      <Modal
        show={props.show}
        centered
        onHide={() => {props.hide(false)}}
        animation={false}
        className="modal-style-groups"
      >
        <Modal.Header closeButton className="header-style-modal">
          <Button onClick={props.hide} className="btn closebtn">
            <img src={loginclose} alt="deafult" />
          </Button>
          <Modal.Title>TRANSFER OWNERSHIP</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-small">
          <Form.Group>
            <label className="form-label">Who Do You Want Transfer Ownership Of This Group?</label>
            <select
              className="form-control"
              onChange={(e) => {setUserId(e.target.value)}}
            >
              <option value="">Select</option>
              {
                options.map((d) =>
                  <option value={d.user_id}>{d.firstName} {d.lastName}</option> 
                )
              }
            </select>
          </Form.Group>
          <div className="error-msg">{errorMsg}</div>
          <div className="success-msg">{successMsg}</div>
          <div className="footer-btn">
            <Button
              onClick={transferGroup}
              variant="primary"
              type="submit"
            >
              SUBMIT
            </Button>
            <Button className="cancel-btn"
              onClick={() => {props.hide(false)}}
            >
              CANCEL
            </Button>
          </div>
        </Modal.Body>

      </Modal>
    </Aux>
  )
}