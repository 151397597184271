import React from "react";
import Aux from "../hoc/_Aux";
import Placeadd from "../components/Placeadd";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import ProfileBaner from "../components/Profile-hero";
import Faqaccordian from "../components/Faqs";
import Menu from "../screens/includes/Navbar";
import Placebigadd from "../components/Placeyouraddbigbox";

const profilehero =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/about-banner.jpg";
const profileheromobile =  "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/about-banner-mobile.jpg";

class Readmore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: "input",
    };
    this.showHide = () => this.showHide.bind(this);
  }
  componentDidMount() {
    document.title = "NCR Rugby | Read More";
  }
  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent">
          <Row>
            <Col md="12">
              <div className="leaderord headerAdvt">
                <Placeadd />
              </div>
            </Col>
          </Row>
          <Row>
            <ProfileBaner
              text="DISCLAIMER"
              img={profilehero}
              alt="deafult"
              imgmobile={profileheromobile}
            />
          </Row>
          <Row className="deatilstext" style={{ backgroundColor: "#eeeeee" }}>
            <Col className="container">
              <div className="textandadd">
                <div className="Aboutus">
                  <p className="blackpad">
                    <strong>
                      No purchase necessary to enter, win or claim a prize.
                    </strong>
                  </p>
                  <p className="blackpad">
                    This promotion is intended for viewing in the United States,
                    including the District of Columbia, and evaluated according
                    to United States Law.
                  </p>
                  <p className="blackpad">
                    Do not proceed with this promotion if you are not located in
                    the United States, District of Columbia or if you are
                    located in Rhode Island. This promotion is void where
                    prohibited by law.
                  </p>
                  <p className="blackpad">
                    This promotion is in no way sponsored, endorsed or
                    administered by Facebook, Instagram or Twitter.
                  </p>
                  <p className="blackpad">
                    The objective of the promotion is to accumulate the most
                    points by correctly selecting the "winning team" for each of
                    the sixty-four (64) men's and thirty two (32) women’s
                    college rugby games associated with the collegiate rugby
                    championship. Please note that your participation in this
                    promotion constitutes full and unconditional agreement to
                    the Official Rules and to the National Collegiate Rugby
                    Championships decisions.
                  </p>
                  <p className="blackpad">
                    For further details please read carefully the
                    <Link to="/terms-and-conditions"> Official Rules.</Link>
                  </p>
                </div>

                <div className="img-box1">
                  <div className="img-box" style={{display:'none'}}>
                    <div className="img"></div>
                  </div>
                  <Placebigadd />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Placeaddsm />
            </Col>
          </Row>
        </div>
      </Aux>
    );
  }
}
export default Readmore;
