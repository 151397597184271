import { globalVariable } from "./global";
import axios from "axios";

export const GetData = async (url) => {
  let token = localStorage.getItem("login")
    ? JSON.parse(localStorage.getItem("login"))
    : "";
  const accesstoken = token ? token.login : "";
  let BaseUrl = globalVariable.baseURL + url;
  return await axios
    .get(BaseUrl, {
      headers: {
        access_token: accesstoken,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 413) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/";
            return error.response.data;
          } else {
            return error.response.data;
          }
        }
      }
      return false;
    });
};
export const GetRank = async (url, id) => {
  let token = JSON.parse(localStorage.getItem("login"));
  const accesstoken = token.login;
  let BaseUrl = globalVariable.baseURL + url;
  const formData = new FormData();
  formData.append("bracket_type", 1);
  return await axios
    .get(BaseUrl, {
      headers: {
        access_token: accesstoken,
      },
      body: formData,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 413) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/";
            return error.response.data;
          } else {
            return error.response.data;
          }
        }
      }
      return false;
    });
};

export const PostData = async (url, formData) => {
  let token = JSON.parse(localStorage.getItem("login"));
  const accesstoken = token.login;
  let BaseUrl = globalVariable.baseURL + url;
  return await axios
    .post(BaseUrl, formData, {
      headers: {
        access_token: accesstoken,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 413) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = "/";
            return error.response.data;
          } else {
            return error.response.data;
          }
        }
      }
      return false;
    });
};

export const DeleteData = async (url, formData) => {
  let token = localStorage.getItem("login")
    ? JSON.parse(localStorage.getItem("login"))
    : "";
  const accesstoken = token ? token.login : "";
  let BaseUrl = globalVariable.baseURL + url;
  return await axios
    .delete(BaseUrl, {
      headers: {
        access_token: accesstoken,
      },
      data: formData
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const PutData = async (url, formData) => {
  let token = localStorage.getItem("login")
    ? JSON.parse(localStorage.getItem("login"))
    : "";
  const accesstoken = token ? token.login : "";
  let BaseUrl = globalVariable.baseURL + url;
  return await axios
    .put(BaseUrl, formData, {
      headers: {
        access_token: accesstoken,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const notificationdata = async (formData) => {
  let token = JSON.parse(localStorage.getItem("login"));
  const accesstoken = token.login;
  let BaseUrl = globalVariable.baseURL + "notifications/sendNotification";
  return await axios
    .post(BaseUrl, formData, {
      headers: {
        access_token: accesstoken,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return false;
    });
};
