import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "./includes/Navbar";
import { withRouter, Link } from "react-router-dom";
import { Prompt, Redirect } from "react-router";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeadd from "../components/Placeadd";
import Placeaddsm from "../components/Placeaddsm";
import TimerBanner from "../components/timer-banner";
import { GetData, PostData } from "../utils/apiRequestHandler";
import BracketBox from "../components/brackets/BracketBox";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import { Button } from "react-bootstrap";
import ConfirmationModal from "../components/groupModal/ConfirmationModal";

const logo = "https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png";
const closeIcon = "https://d7le4ielk3ho1.cloudfront.net/src_images/login-cross.png";
const loginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";

class RugbyBracket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      isEditable: true,
      partiallyWomens: false,
      tieBrackerModal: false,
      mensLeagueloserIds: [],
      survivorloserIds: [],
      challengeloserIds: [],
      totalScore: "N/A",
      championloserIds: [],
      loserIds: [],
      tieBrackerMatch: {},
      tiebrackerError: "",
      tieBrackerScore: { team1: 0, team2: 0 },
      modalIsOpen: false,
      crossBtn: false,
      partiallySaved: false,
      partiallyUpdate: false,
      modalMsg: "",
      type: "input",
      btn1value: "",
      btn2value: "",
      addClass: false,
      addClass1: false,
      addClass2: false,
      addClass3: false,
      tabNumber: 1,
      mensLeagueTopLeft: [],
      mensLeagueBottomLeft: [],
      mensLeagueTopRight: [],
      mensLeagueBottomRight: [],
      mensLeagueCenter: [],
      mensCenterText: "--",
      mensTopLeftText: "--",
      mensTopRightText: "--",
      mensBottomLeftText: "--",
      mensBottomRightText: "--",
      survivorsCenter: [],
      survivorsLeft: [],
      survivorsRight: [],
      challengeCenter: [],
      challengeLeft: [],
      challengeRight: [],
      championLeft: [],
      championRight: [],
      championCenter: [],
      bracketNotFilled: false,
      showPrompt: false,
      showPremierScroll: true,
      redirect: false,
      modalState: {
        show: false,
        hide: this.closeModal,
        modalMessage: 'You have unsaved changes, are you sure you want to leave?',
        modalTitle: 'Confirmation',
        modalAction: this.modalCallback,
        yesButtonName: "Yes",
        noButtonName: "Cancel"
      },
      tournamentStart: true,
      backPopupMsg: false
    };
    console.log('male page props==>', props);
    this._userBracketId = "";
    this.showHide = () => this.showHide.bind(this);
    this._popupButton = 1;
    this._send_bracket_mail = "yes";
    this.name = props?.match?.params?.name;
  }

  componentDidMount() {
    if (localStorage.tp !== "2" && 'a' !== 'a') {
      this.props.history.push("/");
    } else {
      document.title = "NCR Rugby | Rugby Mens Bracket";
      let tournamenttime = JSON.parse(localStorage.getItem("tournamenttime"));
      if (tournamenttime) {
        // this.setState({
        //   tournamentStart: tournamenttime.gameStarted
        // }, () => {
        //   this.getBrackets();
        //   this.getUserRank();
        // })
        this.getBrackets();
        // this.getUserRank();
      } else {
        this.getBrackets();
        // this.getUserRank();
      }


    }
  }

  getUserRank = () => {
    // https://stageapi.maymadness7s.com/api/v1/leaderboard/getTopperDetails?gender=male
    GetData("leaderboard/getTopperDetails?gender=male").then((response) => {
      if (response.status === true) {
        if (response.data.mensRank[0]) {
          this.setState({
            totalScore: response.data.mensRank[0].score
              ? response.data.mensRank[0].score
              : 0,
          });
        }
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  getBrackets = () => {
    this.setState({ loader: true });
    // let id = this.props?.match?.params?.id;
    GetData(`leaderboard/getTopperDetails?gender=male`).then((response) => {
      this.setState({ loader: false });
      if (response.status === true) {

        console.log("bracket data is ", response)
        if(response.data["userBracketDetails"] && response.data["userBracketDetails"]["bracketDetails"] ) {
          response.data["bracketDetails"] =  response.data["userBracketDetails"]["bracketDetails"];
          response.data["loser_ids"] = response.data["userBracketDetails"]["loser_ids"];
          response.data.isBracketEditable = false;
        }
        if (response.data["bracketDetails"].length === 0) {
          this.setState({ bracketNotFilled: true });
          return;
        }

        this.setState({
          totalScore: response.data.score ? response.data.score : 'N/A', 
          isEditable: response.data.isBracketEditable,
          partiallySaved: response.data.isBracketEditable
            ? response.data.isPartiallyFilledBracket.male
            : false,
          partiallyWomens: response.data.isBracketEditable
            ? response.data.isPartiallyFilledBracket.female
            : false,
          mensLeagueloserIds: response.data.loser_ids["Men_League"],
          survivorloserIds: response.data.loser_ids["Survivor_Cup"],
          challengeloserIds: response.data.loser_ids["Challenge_Cup"],
          championloserIds: response.data.loser_ids["Champions_Cup"],
          loserIds: [],
        });
        this._userBracketId =
          response.data["userBracketDetails"]["user_bracket_id"];
        const mensLeagueTopLeft = response.data[
          "bracketDetails"
        ][0].brackets.find((x) => x.bracket_position === "top_left");

        if (response.data.isBracketEditable === false) {
          if (!mensLeagueTopLeft["games"][0].winner_id) {
            this.setState({ bracketNotFilled: true });
            return;
          }
        } else {
          this._send_bracket_mail = "no";
        }

        const mensLeagueBottomLeft = response.data[
          "bracketDetails"
        ][0].brackets.find((x) => x.bracket_position === "bottom_left");
        const mensLeagueTopRight = response.data[
          "bracketDetails"
        ][0].brackets.find((x) => x.bracket_position === "top_right");
        const mensLeagueBottomRight = response.data[
          "bracketDetails"
        ][0].brackets.find((x) => x.bracket_position === "bottom_right");
        const mensLeagueCenter = response.data[
          "bracketDetails"
        ][0].brackets.find((x) => x.bracket_position === "center");

        this.setState({
          tieBrackerScore: {
            team1: mensLeagueCenter.games[2].team1.team_1_score
              ? mensLeagueCenter.games[2].team1.team_1_score
              : 0,
            team2: mensLeagueCenter.games[2].team2.team_2_score
              ? mensLeagueCenter.games[2].team2.team_2_score
              : 0,
          },
        });
        const survivorsLeft = response.data["bracketDetails"][1].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const survivorsRight = response.data["bracketDetails"][1].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const survivorsCenter = response.data["bracketDetails"][1].brackets[2];

        const challengeLeft = response.data["bracketDetails"][2].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const challengeRight = response.data["bracketDetails"][2].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const challengeCenter = response.data[
          "bracketDetails"
        ][2].brackets.find((x) => x.bracket_position === "center");

        const championLeft = response.data["bracketDetails"][3].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const championRight = response.data["bracketDetails"][3].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const championCenter = response.data["bracketDetails"][3].brackets.find(
          (x) => x.bracket_position === "center"
        );

        this.setState({
          championLeft: championLeft.games,
          championRight: championRight.games,
          championCenter: championCenter.games,
          challengeLeft: challengeLeft.games,
          challengeRight: challengeRight.games,
          challengeCenter: challengeCenter.games,
          mensTopLeftText: mensLeagueTopRight.devision,
          mensTopRightText: mensLeagueTopLeft.devision,
          mensBottomLeftText: mensLeagueBottomRight.devision,
          mensBottomRightText: mensLeagueBottomLeft.devision,
          mensCenterText: mensLeagueCenter.devision,
          mensLeagueTopLeft: mensLeagueTopLeft.games,
          mensLeagueBottomLeft: mensLeagueBottomLeft.games,
          mensLeagueTopRight: mensLeagueTopRight.games,
          mensLeagueBottomRight: mensLeagueBottomRight.games,
          mensLeagueCenter: mensLeagueCenter.games,
          survivorsLeft: survivorsLeft.games,
          survivorsRight: survivorsRight.games,
          survivorsCenter: survivorsCenter.games,
        });
      } else {
        console.log("Error while fetching data");
      }
    });
  };
  leavebtnClick = () => {
    this.setState({
      modalIsOpen: false,
      leavebtnvalue: null,
      savebtnvalue: null,
      modalMsg: "Please fill all brackets",
    });
  }
  submitBracket = (save = false) => {
    return false;
    let stateKeys = [
      "championLeft",
      "championRight",
      "championCenter",
      "challengeLeft",
      "challengeRight",
      "challengeCenter",
      "mensLeagueTopLeft",
      "mensLeagueBottomLeft",
      "mensLeagueTopRight",
      "mensLeagueBottomRight",
      "mensLeagueCenter",
      "survivorsLeft",
      "survivorsRight",
      "survivorsCenter",
    ];
    let formDataArray = [];
    if (
      this.state.mensLeagueCenter[2].team1.team_id &&
      this.state.mensLeagueCenter[2].team2.team_id && !save
    ) {
      if (
        !this.state.mensLeagueCenter[2].winner_id ||
        !this.state.mensLeagueCenter[3].winner_id
      ) {
        let message =
          "Please select the winner for the Premier Cup 3rd place games.";
        if (!this.state.mensLeagueCenter[2].winner_id) {
          message =
            "Please select the winner for the Premier Cup championship.";
        }
        if (
          !this.state.mensLeagueCenter[2].winner_id &&
          !this.state.mensLeagueCenter[3].winner_id
        ) {
          message =
            "Please select the winner for the Premier Cup championship and 3rd place games.";
        }
        //mensPremierCup
        const elmnt = document.getElementById("premiorBracket");
        elmnt.scrollIntoView();
        this.setState({
          modalIsOpen: true,
          crossBtn: false,
          btn1value: "Back",
          btn2value: "",
          modalMsg: message,
        });
        return;
      }
    }

    if (
      this.state.survivorsCenter[2].team1.team_id &&
      this.state.survivorsCenter[2].team2.team_id && !save
    ) {
      if (
        !this.state.survivorsCenter[2].winner_id ||
        !this.state.survivorsCenter[3].winner_id
      ) {
        //survivorsCup

        let message =
          "Please select the winner for the Survivors Bowl 3rd place games.";
        if (!this.state.survivorsCenter[2].winner_id) {
          message =
            "Please select the winner for the Survivors Bowl championship.";
        }
        if (
          !this.state.survivorsCenter[2].winner_id &&
          !this.state.survivorsCenter[3].winner_id
        ) {
          message =
            "Please select the winner for the Survivors Bowl championship and 3rd place games.";
        }

        const elmnt = document.getElementById("survivorsCup");
        elmnt.scrollIntoView();
        this.setState({
          modalIsOpen: true,
          crossBtn: false,
          btn1value: "Back",
          btn2value: "",
          modalMsg: message,
        });
        return;
      }
    }

    if (
      this.state.challengeCenter[2].team1.team_id &&
      this.state.challengeCenter[2].team2.team_id && !save
    ) {
      if (
        !this.state.challengeCenter[2].winner_id ||
        !this.state.challengeCenter[3].winner_id
      ) {
        //challengeCup
        let message =
          "Please select the winner for the Challenge Bowl 3rd place games.";
        if (!this.state.challengeCenter[2].winner_id) {
          message =
            "Please select the winner for the Challenge Bowl championship.";
        }
        if (
          !this.state.challengeCenter[2].winner_id &&
          !this.state.challengeCenter[3].winner_id
        ) {
          message =
            "Please select the winner for the Challenge Bowl championship and 3rd place games.";
        }

        const elmnt = document.getElementById("challengeCup");
        elmnt.scrollIntoView();
        this.setState({
          modalIsOpen: true,
          crossBtn: false,
          btn1value: "Back",
          btn2value: "",
          modalMsg: message,
        });
        return;
      }
    }

    if (
      this.state.championCenter[2].team1.team_id &&
      this.state.championCenter[2].team2.team_id && !save
    ) {
      if (
        !this.state.championCenter[2].winner_id ||
        !this.state.championCenter[3].winner_id
      ) {
        //championCup
        let message =
          "Please select the winner for the Champion Plate 3rd place games.";
        if (!this.state.championCenter[2].winner_id) {
          message =
            "Please select the winner for the Champion Plate championship.";
        }
        if (
          !this.state.championCenter[2].winner_id &&
          !this.state.championCenter[3].winner_id
        ) {
          message =
            "Please select the winner for the Champion Plate championship and 3rd place games.";
        }
        const elmnt = document.getElementById("championCup");
        elmnt.scrollIntoView();
        this.setState({
          modalIsOpen: true,
          crossBtn: false,
          btn1value: "Back",
          btn2value: "",
          modalMsg: message,
        });
        return;
      }
    }
    let error = 0;

    stateKeys.map((key) => {
      let stateData = this.state[key];
      stateData.map((data) => {
        if (data.team1.team_id && data.team2.team_id) {
          formDataArray.push({
            user_bracket_id: this._userBracketId,
            game_id: data.game_id,
            team_1_id: data.team1.team_id ? data.team1.team_id : null,
            team_2_id: data.team2.team_id ? data.team2.team_id : null,
            winner_id: data.winner_id
              ? data.winner_id
              : data.winner
                ? data.winner
                : null,
            team_1_score: data.team1.score || data.team1.score === 0
              ? data.team1.score
              : data.team1.team_1_score,
            team_2_score: data.team2.score || data.team2.score === 0
              ? data.team2.score
              : data.team1.team_2_score,
          });
        } else {
          formDataArray.push({
            user_bracket_id: this._userBracketId,
            game_id: data.game_id,
            team_1_id: data.team1.team_id ? data.team1.team_id : null,
            team_2_id: data.team2.team_id ? data.team2.team_id : null,
            winner_id: data.winner_id
              ? data.winner_id
              : data.winner
                ? data.winner
                : null,
            team_1_score: data.team1.score || data.team1.score === 0
              ? data.team1.score
              : data.team1.team_1_score,
            team_2_score: data.team2.score || data.team2.score === 0
              ? data.team2.score
              : data.team1.team_2_score,
          });
          error = 1;
        }
      });

      return null;
    });
    if (error && !save) {
      this.setState({
        modalIsOpen: true,
        crossBtn: false,
        btn1value: null,
        btn2value: null,
        leavebtnvalue: 'Leave',
        savebtnvalue: 'Save',
        modalMsg: "Please fill all brackets",
      });
      return;
    }
    let formData = new FormData();
    formData.append("userBracketDetails", JSON.stringify(formDataArray));
    formData.append("send_bracket_mail", this._send_bracket_mail);
    formData.append("bracketType", "male");

    console.log("form data is ", formData)

    this.setState({ loader: true });
    PostData("manage-user-bracket/updateDetails", formData).then((response) => {

      this.getBrackets();
      this.setState({ 
        showPrompt: false,
        modalState: {
          ...{ show: false }
        }
      });
      
      this.leavebtnClick();
      setTimeout(() => {
        this.setState({
          partiallyUpdate: false,
          crossBtn: true,
          modalIsOpen: true,
          btn1value: "",
          btn2value: "",
          modalMsg: "Your bracket has been saved",
        });
      }, 800);
    });
    // }
  };

  removeDependencies = (dependents) => {
    dependents.map((d) => {
      const updateState = this.state[d.state];
      let indexKey;
      if (d.position) {
        indexKey = updateState.findIndex(
          (x) => x.round === d.round && x.position === d.position
        );
      } else {
        indexKey = updateState.findIndex((x) => x.round === d.round);
      }
      updateState[indexKey]["winner_id"] = "";
      updateState[indexKey]["winner"] = "";
      updateState[indexKey]["selected"] = "";
      d.teams.map((t) => {
        updateState[indexKey][t] = {};
        updateState[indexKey][t] = {};
        return null;
      });

      return null;
    });
  };

  updateAllStates = (currentState, match) => {
    switch (currentState) {
      case "championLeft":
        var dependents = [
          { state: "championCenter", round: 3, teams: ["team1"] },
          { state: "championCenter", round: 3, position: 2, teams: ["team1"] },
        ];
        this.removeDependencies(dependents);
        break;

      case "championRight":
        var dependents = [
          { state: "championCenter", round: 3, teams: ["team2"] },
          { state: "championCenter", round: 3, position: 2, teams: ["team2"] },
        ];
        this.removeDependencies(dependents);
        break;

      case "challengeLeft":
        var dependents = [
          { state: "challengeCenter", round: 3, teams: ["team1"] },
          { state: "challengeCenter", round: 3, position: 2, teams: ["team1"] },
        ];
        this.removeDependencies(dependents);
        break;

      case "challengeRight":
        var dependents = [
          { state: "challengeCenter", round: 3, teams: ["team2"] },
          { state: "challengeCenter", round: 3, position: 2, teams: ["team2"] },
        ];
        this.removeDependencies(dependents);
        break;
      case "survivorsLeft":
        if (match.round === 1) {
          let dependents;
          if (match.position > 2) {
            dependents = [
              {
                state: "survivorsLeft",
                round: 2,
                position: 2,
                teams: match.position === 3 ? ["team1"] : ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
          } else {
            dependents = [
              {
                state: "survivorsLeft",
                round: 2,
                position: 1,
                teams: match.position === 1 ? ["team1"] : ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
          }
          this.removeDependencies(dependents);
        }
        break;

      case "survivorsRight":
        if (match.round === 1) {
          let dependents;
          if (match.position > 2) {
            dependents = [
              {
                state: "survivorsRight",
                round: 2,
                position: 2,
                teams: match.position === 3 ? ["team1"] : ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
          } else {
            dependents = [
              {
                state: "survivorsRight",
                round: 2,
                position: 1,
                teams: match.position === 1 ? ["team1"] : ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
          }
          this.removeDependencies(dependents);
        }
        break;

      case "mensLeagueTopLeft":
        if (match.round === 1) {
          if (match.position === 1 || match.position === 2) {
            let dependents = [
              { state: "mensLeagueTopLeft", round: 3, teams: ["team1"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team1"],
              },
              { state: "survivorsLeft", round: 2, teams: ["team1"] },
              {
                state: "survivorsCenter",
                position: 1,
                round: 3,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeLeft", round: 1, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championLeft",
                round: 1,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "championCenter", round: 3, teams: ["team1"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
            this.removeDependencies(dependents);
          }
          if (match.position === 3 || match.position === 4) {
            let dependents = [
              { state: "mensLeagueTopLeft", round: 3, teams: ["team2"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team1"],
              },
              { state: "survivorsLeft", round: 2, teams: ["team2"] },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeLeft", round: 1, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championLeft",
                round: 1,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "championCenter", round: 3, teams: ["team1"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
            this.removeDependencies(dependents);
          }
        }
        if (match.round === 2) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "championCenter",
              round: 2,
              position: 1,
              teams: ["team1"],
            },
            { state: "championCenter", round: 3, teams: ["team1"] },
            {
              state: "championCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
          ];
          this.removeDependencies(dependents);
        }
        if (match.round === 3) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team1"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;
      case "mensLeagueBottomLeft":
        if (match.round === 1) {
          if (match.position === 5 || match.position === 6) {
            let dependents = [
              { state: "mensLeagueBottomLeft", round: 3, teams: ["team1"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsLeft",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeLeft",
                round: 1,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championLeft",
                round: 1,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "championCenter", round: 3, teams: ["team1"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
            this.removeDependencies(dependents);
          }
          if (match.position === 7 || match.position === 8) {
            let dependents = [
              { state: "mensLeagueBottomLeft", round: 3, teams: ["team2"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsLeft",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeLeft",
                round: 1,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championLeft",
                round: 1,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "championCenter", round: 3, teams: ["team1"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
            this.removeDependencies(dependents);
          }
        }
        if (match.round === 2) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "championCenter",
              round: 2,
              position: 1,
              teams: ["team2"],
            },
            { state: "championCenter", round: 3, teams: ["team1"] },
            {
              state: "championCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
          ];
          this.removeDependencies(dependents);
        }
        if (match.round === 3) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team1"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;

      case "mensLeagueTopRight":
        if (match.round === 1) {
          if (match.position === 1 || match.position === 2) {
            let dependents = [
              { state: "mensLeagueTopRight", round: 3, teams: ["team1"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team2"],
              },
              { state: "survivorsRight", round: 2, teams: ["team1"] },
              {
                state: "survivorsCenter",
                position: 2,
                round: 3,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeRight", round: 1, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championRight",
                round: 1,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "championCenter", round: 3, teams: ["team2"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
            this.removeDependencies(dependents);
          }
          if (match.position === 3 || match.position === 4) {
            let dependents = [
              { state: "mensLeagueTopRight", round: 3, teams: ["team2"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team2"],
              },
              { state: "survivorsRight", round: 2, teams: ["team2"] },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeRight", round: 1, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championRight",
                round: 1,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "championCenter", round: 3, teams: ["team2"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
            this.removeDependencies(dependents);
          }
        }
        if (match.round === 2) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "championCenter",
              round: 2,
              position: 2,
              teams: ["team1"],
            },
            { state: "championCenter", round: 3, teams: ["team2"] },
            {
              state: "championCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        if (match.round === 3) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;
      case "mensLeagueBottomRight":
        if (match.round === 1) {
          if (match.position === 5 || match.position === 6) {
            let dependents = [
              { state: "mensLeagueBottomRight", round: 3, teams: ["team1"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsRight",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeRight",
                round: 1,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championRight",
                round: 1,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "championCenter", round: 3, teams: ["team2"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
            this.removeDependencies(dependents);
          }
          if (match.position === 7 || match.position === 8) {
            let dependents = [
              { state: "mensLeagueBottomRight", round: 3, teams: ["team2"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsRight",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeRight",
                round: 1,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championRight",
                round: 1,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "championCenter", round: 3, teams: ["team2"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
            this.removeDependencies(dependents);
          }
        }
        if (match.round === 2) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "championCenter",
              round: 2,
              position: 2,
              teams: ["team2"],
            },
            { state: "championCenter", round: 3, teams: ["team2"] },
            {
              state: "championCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        if (match.round === 3) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;
      default:
        break;
    }
  };

  forceUpdate = () => {
    if (this._popupButton === 2) {
      this.props.history.push("womens-bracket");
      return;
    }
    this.handleClick(
      this._match,
      this._stateKey,
      this._team,
      this._losingTeam,
      this._index,
      true
    );
    this.setState({ modalIsOpen: false });
  };

  handleClick = (match, stateKey, team, losingTeam, index, force = false) => {
    if (
      (this.state.partiallySaved === true &&
        this.state.partiallyUpdate === false) ||
      this.state.isEditable === false
    ) {
      return;
    }

    if (!match.team1.team_id || !match.team2.team_id) {
      this.setState({
        modalIsOpen: true,
        crossBtn: false,
        btn1value: "Back",
        btn2value: "",
        modalMsg: "Please select a winner in the previous game.",
      });
      return;
    }

    if ((match.winner || match.winner_id) && force === false) {
      this._match = match;
      this._stateKey = stateKey;
      this._team = team;
      this._losingTeam = losingTeam;
      this._index = index;
      this._popupButton = 1;
      //this.setState({modalIsOpen:true,btn1value:"Cancel",btn2value:"Save",modalMsg:'Editing the field will reset all the brackets hierarchy'})
      //return
    }
    if (!this.state.partiallySaved) {
      this.setState({ showPrompt: true });
    }
    this.updateAllStates(stateKey, match);

    let currentStateData = this.state[stateKey][index];

    currentStateData["winner"] = team.team_id;
    currentStateData["selected"] = team.team_id;
    currentStateData["winner_id"] = team.team_id;

    if (stateKey === "championCenter" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";

      leagueData[leagueIndexLosingTeam]["winner"] = "";
      leagueData[leagueIndexLosingTeam]["winner_id"] = "";
      leagueData[leagueIndexLosingTeam]["selected"] = "";

      this.setState({ [stateKey]: leagueData });
      return;
    }
    if (stateKey === "championCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "championLeft" || stateKey === "championRight") {
      let challengeKey = "championCenter";
      let leagueData = this.state[challengeKey];
      let position = 1;
      if (stateKey === "championRight") {
        position = 2;
      }

      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";

      this.setState({ championCenter: leagueData });
      return;
    }

    if (stateKey === "challengeCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      if (
        (leagueData[2].winner_id || leagueData[2].winner_id) &&
        (leagueData[3].winner_id || leagueData[3].winner_id) &&
        window.innerWidth < 700
      ) {
        this.scrollToTargetAdjusted("championCup");
      }
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "challengeCenter" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";

      leagueData[leagueIndexLosingTeam]["winner"] = "";
      leagueData[leagueIndexLosingTeam]["winner_id"] = "";
      leagueData[leagueIndexLosingTeam]["selected"] = "";

      this.setState({ [stateKey]: leagueData });
      return;
    }

    if (stateKey === "challengeLeft" || stateKey === "challengeRight") {
      let challengeKey = "challengeCenter";
      let leagueData = this.state[challengeKey];
      let position = 1;
      if (stateKey === "challengeRight") {
        position = 2;
      }

      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";

      this.setState({ challengeCenter: leagueData });
      return;
    }

    if (stateKey === "survivorsCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";

      leagueData[leagueIndexLosingTeam]["winner"] = "";
      leagueData[leagueIndexLosingTeam]["winner_id"] = "";
      leagueData[leagueIndexLosingTeam]["selected"] = "";

      this.setState({ [stateKey]: leagueData });
      return null;
    }
    if (stateKey === "survivorsCenter" && match.round === 4) {
      let leagueData = this.state[stateKey];
      if (
        (leagueData[2].winner_id || leagueData[2].winner_id) &&
        (leagueData[3].winner_id || leagueData[3].winner_id) &&
        window.innerWidth < 700
      ) {
        this.scrollToTargetAdjusted("challengeCup");
      }

      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "survivorsLeft" || stateKey === "survivorsRight") {
      let round = 2;
      let position = 1;
      if (match.round === 2) {
        round = 3;
      }
      if (match.position > 2) {
        position = 2;
      }
      let leagueDataState = stateKey;
      if (round === 3) {
        leagueDataState = "survivorsCenter";
        if (stateKey === "survivorsRight") {
          position = 2;
        }
      }
      let leagueData = this.state[leagueDataState];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === round && x.position === position
      );

      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";

      if (match.round === 1) {
        let chalengeStateKey = "challengeLeft";
        if (stateKey === "survivorsRight") {
          chalengeStateKey = "challengeRight";
        }
        let challengeCup = this.state[chalengeStateKey];

        let cposition = 1;
        if (match.position > 2) {
          cposition = 2;
        }
        const challengeIndex = leagueData.findIndex(
          (x) => x.position === cposition
        );

        if (match.position % 2 !== 0) {
          challengeCup[challengeIndex].team1 = losingTeam;
        }
        if (match.position % 2 === 0) {
          challengeCup[challengeIndex].team2 = losingTeam;
        }
        challengeCup[challengeIndex]["winner"] = "";
        challengeCup[challengeIndex]["winner_id"] = "";
        challengeCup[challengeIndex]["selected"] = "";

        this.setState({ [chalengeStateKey]: challengeCup });
      }

      this.setState({ [leagueDataState]: leagueData });
      return;
    }

    if (match.round === 1) {
      let leagueData = this.state[stateKey];
      let position = 1;
      if (match.position > 2) {
        position = 2;
      }
      if (match.position > 4) {
        position = 3;
      }
      if (match.position > 6) {
        position = 4;
      }
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";
      leagueData[2].team1.team_1_score = 0;
      leagueData[2].team2.team_2_score = 0;
      let survivorKey = 0;
      if (match.position > 2) {
        survivorKey = 1;
      }
      if (match.position > 4) {
        survivorKey = 2;
      }
      if (match.position > 6) {
        survivorKey = 3;
      }
      let survivorState = "survivorsLeft";
      if (
        stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight"
      ) {
        survivorState = "survivorsRight";
      }
      let survivorsData = this.state[survivorState];
      if (match.position % 2 !== 0) {
        survivorsData[survivorKey].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        survivorsData[survivorKey].team2 = losingTeam;
      }
      survivorsData[survivorKey]["winner"] = "";
      survivorsData[survivorKey]["winner_id"] = "";
      survivorsData[survivorKey]["selected"] = "";
      this.setState({
        [stateKey]: leagueData,
        tieBrackerScore: { team1: 0, team2: 0 },
      });
    }

    if (match.round === 2) {
      let championState = "championLeft";
      if (
        stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight"
      ) {
        championState = "championRight";
      }
      let championData = this.state[championState];

      let championKey = 0;
      if (match.position > 2) {
        championKey = 1;
      }
      if (match.position % 2 !== 0) {
        championData[championKey].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        championData[championKey].team2 = losingTeam;
      }
      championData[championKey]["winner"] = "";
      championData[championKey]["winner_id"] = "";
      championData[championKey]["selected"] = "";
      this.setState({ [championState]: championData });
    }

    if (stateKey === "mensLeagueTopLeft" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";
      leagueData[2].team1.team_1_score = 0;
      leagueData[2].team2.team_2_score = 0;
      this.setState({
        mensLeagueTopLeft: leagueData,
        tieBrackerScore: { team1: 0, team2: 0 },
      });
    }
    if (stateKey === "mensLeagueBottomLeft" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 3) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 4) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";
      leagueData[2].team1.team_1_score = 0;
      leagueData[2].team2.team_2_score = 0;
      this.setState({
        mensLeagueBottomLeft: leagueData,
        tieBrackerScore: { team1: 0, team2: 0 },
      });
    }

    if (stateKey === "mensLeagueTopRight" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";
      leagueData[2].team1.team_1_score = 0;
      leagueData[2].team2.team_2_score = 0;
      this.setState({
        mensLeagueTopRight: leagueData,
        tieBrackerScore: { team1: 0, team2: 0 },
      });
    }

    if (stateKey === "mensLeagueBottomRight" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex((x) => x.round === 3);
      if (match.position === 3) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 4) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";
      leagueData[2].team1.team_1_score = 0;
      leagueData[2].team2.team_2_score = 0;
      this.setState({
        mensLeagueBottomRight: leagueData,
        tieBrackerScore: { team1: 0, team2: 0 },
      });
    }

    if (
      (stateKey === "mensLeagueTopLeft" ||
        stateKey === "mensLeagueBottomLeft") &&
      match.round === 3
    ) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 1
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";
      leagueData[2].team1.team_1_score = 0;
      leagueData[2].team2.team_2_score = 0;
      this.setState({
        mensLeagueCenter: leagueData,
        tieBrackerScore: { team1: 0, team2: 0 },
      });
    }

    if (
      (stateKey === "mensLeagueTopRight" ||
        stateKey === "mensLeagueBottomRight") &&
      match.round === 3
    ) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 4 && x.position === 2
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }
      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";
      leagueData[2].team1.team_1_score = 0;
      leagueData[2].team2.team_2_score = 0;
      this.setState({
        mensLeagueCenter: leagueData,
        tieBrackerScore: { team1: 0, team2: 0 },
      });
    }

    if (stateKey === "mensLeagueCenter" && match.round === 4) {
      let leagueData = this.state.mensLeagueCenter;
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 5 && x.position === 1
      );
      if (match.position === 1) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position === 2) {
        leagueData[leagueIndex].team2 = team;
      }

      leagueData[leagueIndex]["winner"] = "";
      leagueData[leagueIndex]["winner_id"] = "";
      leagueData[leagueIndex]["selected"] = "";

      const LosingLeagueIndex = leagueData.findIndex(
        (x) => x.round === 5 && x.position === 2
      );
      if (match.position === 1) {
        leagueData[LosingLeagueIndex].team1 = losingTeam;
      }
      if (match.position === 2) {
        leagueData[LosingLeagueIndex].team2 = losingTeam;
      }
      leagueData[LosingLeagueIndex]["winner"] = "";
      leagueData[LosingLeagueIndex]["winner_id"] = "";
      leagueData[LosingLeagueIndex]["selected"] = "";

      leagueData[2].team1.team_1_score = 0;
      leagueData[2].team2.team_2_score = 0;

      this.setState({
        mensLeagueCenter: leagueData,
        tieBrackerScore: { team1: 0, team2: 0 },
      });
    }
    if (stateKey === "mensLeagueCenter" && match.round === 5) {
      let leagueData = this.state.mensLeagueCenter;

      if (match.position === 1) {
        this.setState({
          tieBrackerModal: true,
          tieBrackerScore: {
            team1: leagueData[2].team1.team_1_score
              ? leagueData[2].team1.team_1_score
              : 0,
            team2: leagueData[2].team2.team_2_score
              ? leagueData[2].team2.team_2_score
              : 0,
          },
          tieBrackerMatch: match,
          tiebrackerError: "",
        });
      }
      if (
        (leagueData[2].winner_id || leagueData[2].winner_id) &&
        (leagueData[3].winner_id || leagueData[3].winner_id) &&
        window.innerWidth < 700
      ) {
        this.scrollToTargetAdjusted("survivorsCup");
      }

      this.setState({ mensLeagueCenter: leagueData });
    }
  };

  setAddClass = () => {
    this.setState({
      addClass: true,
      addClass1: false,
      addClass2: false,
      tabNumber: 1,
    });
  };
  setAddClass1 = () => {
    this.setState({
      addClass1: true,
      addClass: false,
      addClass2: false,
      tabNumber: 2,
    });
  };
  setAddClass2 = () => {
    this.setState({
      addClass2: true,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };
  setAddClass3 = () => {
    this.setState({
      addClass3: false,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };

  arrowClass = () => {
    if (this.state.tabNumber == 3) {
      this.setAddClass1();
    } else if (this.state.tabNumber == 2) {
      this.setAddClass();
    } else if (this.state.tabNumber == 1) {
      this.setAddClass3();
    } else {
    }
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };
  toggleModal = () => {
    this.setState({
      showLogin: !this.state.showLogin,
    });
  }
    

  setTieBreakerScore = (e, team) => {
    this.setState({ tiebrackerError: "" });
    if (isNaN(e.target.value)) {
      return;
    }
    if (e.target.value.length > 3) {
      return;
    }
    let tieBreaker = this.state.tieBrackerScore;
    if (team === "team1") {
      tieBreaker["team_1_score"] =
        e.target.value === "" ? 0 : parseInt(e.target.value);
    } else {
      tieBreaker["team_2_score"] =
        e.target.value === "" ? 0 : parseInt(e.target.value);
    }
    tieBreaker[team] = e.target.value === "" ? 0 : parseInt(e.target.value);
    this.setState({ tieBrackerScore: tieBreaker });
  };

  saveTieBreakerScore = () => {
    let leagueData = this.state.mensLeagueCenter;
    if (
      this.state.tieBrackerScore["team2"] ===
      this.state.tieBrackerScore["team1"]
    ) {
      this.setState({ tiebrackerError: "Scores cannot be equal" });
      return;
    }

    if (
      (leagueData[2]["winner_id"] === leagueData[2]["team1"].team_id ||
        leagueData[2]["winner"] === leagueData[2]["team1"].team_id) &&
      this.state.tieBrackerScore["team2"] > this.state.tieBrackerScore["team1"]
    ) {
      this.setState({
        tiebrackerError: "Team 1 score should be greater than Team 2 score",
      });
      return;
    }
    if (
      (leagueData[2]["winner_id"] === leagueData[2]["team2"].team_id ||
        leagueData[2]["winner"] === leagueData[2]["team2"].team_id) &&
      this.state.tieBrackerScore["team1"] > this.state.tieBrackerScore["team2"]
    ) {
      this.setState({
        tiebrackerError: "Team 2 score should be greater than Team 1 score",
      });
      return;
    }

    leagueData[2]["team1"]["score"] = this.state.tieBrackerScore["team1"];
    leagueData[2]["team2"]["score"] = this.state.tieBrackerScore["team2"];

    this.setState({ mensLeagueCenter: leagueData, tieBrackerModal: false });
  };

  gotowomens = () => {
    if (this.state.partiallyWomens && this.state.isEditable === true) {
      this._popupButton = 2;
      this.setState({
        modalIsOpen: true,
        crossBtn: false,
        btn1value: "Cancel",
        btn2value: "Proceed",
        modalMsg: "Would you like to update women's bracket?",
      });
    } else {
      this.props.history.push("womens-bracket");
    }
  };

  selectBracket = (e) => {
    console.log("cscas", e.target.value);

    this.scrollToTargetAdjusted(e.target.value);
    /*var elmnt = document.getElementById(e.target.value);
    elmnt.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });*/
  };

  scrollToTargetAdjusted = (id) => {
    let offset = -600;
    var element = document.getElementById(id);
    var headerOffset = offset;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  closeModal = () => {
    this.setState({
      modalState: {
        show: false,
        hide: this.closeModal,
        modalMessage: 'What do you want to do?',
        modalTitle: 'Confirmation',
        modalAction: this.modalCallback,
        noButtonLink: this.closeModal,
        yesButtonName: "Save",
        noButtonName: "Leave",
      }
    })
    this.modalCallback();
  }

  hideModal = (redirect=false) => {
    this.setState({
      modalState: {
        show: false,
        hide: this.hideModal,
        modalMessage: 'What do you want to do?',
        modalTitle: 'Confirmation',
        modalAction: this.modalCallback,
        noButtonLink: this.closeModal,
        yesButtonName: "Save",
        noButtonName: "Leave",
      }
    })
    redirect && this.modalCallback(); 
  }

  openModal = (e) => {
    e.preventDefault();
    if (!this.state.tournamentStart) {
      this.setState({
        modalState: {
          show: true,
          hide: this.hideModal,
          modalMessage: 'What do you want to do?',
          modalTitle: 'Confirmation',
          modalAction: this.savebtnClick,
          modalAction: this.modalCallback,
          yesButtonName: "Save",
          noButtonName: "Leave",
        }
      })
      // this.submitBracket(true);
    } else {
      this.modalCallback()
    }

  }

  modalCallback = () => {
    this.setState({
      redirect: true,
      modalState: {
        ...{ show: false },
      }
    })
  }

  savebtnClick= () => {
    this.submitBracket(true);
  }

  render() {
    return (
      <Aux>
        {/* <React.Fragment>
          <Prompt
            when={this.state.showPrompt}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        </React.Fragment> */}
        <Loader show={this.state.loader} />
        {/* <Menu /> */}
        <div className="Maincontent leader-board-wrap" style={{'paddingTop': '0px'}}>
          <div className="container-fluid">
            {/* <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row> */}
            {/* <TimerBanner /> */}
            {/* <div className="bracket-name-style-inner">
              {this.name}
              <span onClick={this.openModal} className="close-bkt-name"><Link to="#"><img src={loginclose} alt="close" /></Link></span>
            </div> */}
            {/* <div className="leader-board-toplinks">
              <Container>
                <Row>
                  <div className="col-12">
                    <ul>
                      <li>
                        <div className="premier-links active">
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          MEN’S PREMIER
                        </div>
                       
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </div> */}
            <div className="invite-body-outer">
              {this.state.bracketNotFilled === true ? (
                <div className="bracket-error-message">
                  <img src={logo} alt="logo" />
                  <div className="message">
                    You have not participated in this tournament
                  </div>
                  <Link to="/mens-score">
                    Click here to check the tournament score
                  </Link>
                </div>
              ) : (
                <div className="rugby-bracket-wrap">
                  <h1>
                    Total Men’s Tournament Points:
                    <span>{this.state.totalScore}</span>
                    {/* <Link to="/create-brackets/leaderboard/mens">view details</Link> */}
                  </h1>
                  <div className="tournament-selector">
                    <label htmlFor="tournament-view">Choose bracket</label>
                    <select
                      id="tournament-view"
                      className="form-control"
                      onChange={this.selectBracket}
                    >
                      <option value="premiorBracket">Premier Cup</option>
                      <option value="survivorsCup">Survivor Bowl</option>
                      <option value="challengeCup">Challenge Bowl</option>
                      <option value="championCup">Champion Plate</option>
                    </select>
                  </div>

                  <div className="bracket-wrapper-inner">
                    <div
                      className={
                        this.state.showPremierScroll === true
                          ? "brackets hbreakers scrollableBracket"
                          : "brackets hbreakers"
                      }
                      id="premiorBracket"
                    >
                      <ul
                        className={`
                                    ${this.state.addClass
                            ? "bracket-rouds-wrap secRd-left"
                            : "bracket-rouds-wrap"
                          }
                                    ${this.state.addClass1 ? "secRd-left1" : ""}
                                    ${this.state.addClass2 ? "secRd-left2" : ""}
                                
                                `}
                      >
                        <li
                          className={`
                                        ${this.state.addClass
                              ? "round-tab arrow-left shift-arr1"
                              : "round-tab arrow-left"
                            }
                                        ${this.state.addClass1
                              ? "shift-arr2"
                              : ""
                            }
                                        ${this.state.addClass2
                              ? "shift-arr3"
                              : ""
                            }
                                    
                                    `}
                        >
                          <span
                            onClick={
                              this.state.showPremierScroll === true
                                ? ""
                                : this.arrowClass
                            }
                          ></span>
                        </li>
                        <li className="round-tab r_1">
                          <span className="r_name">1st ROUND</span>
                          <span className="r_date">May 28</span>
                        </li>
                        <li
                          className="round-tab r_2"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass
                          }
                        >
                          <span className="r_name">2nd ROUND</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li
                          className="round-tab r_3"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass1
                          }
                        >
                          <span className="r_name">QUARTERFINALS</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li
                          className="round-tab r_4"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass2
                          }
                        >
                          <span className="r_name">SEMI FINALS</span>
                          <span className="r_date">May 30</span>
                        </li>
                        <li
                          className="round-tab championship"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass2
                          }
                        >
                          <span className="r_name">CHAMPIONSHIP</span>
                          <span className="r_date">May 30</span>
                        </li>
                        <li className="round-tab r_4">
                          <span className="r_name">SEMI FINALS</span>
                          <span className="r_date">May 30</span>
                        </li>
                        <li className="round-tab r_3">
                          <span className="r_name">QUARTERFINALS</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li className="round-tab r_2">
                          <span className="r_name">2nd ROUND</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li className="round-tab r_1">
                          <span className="r_name">1st ROUND</span>
                          <span className="r_date">May 28</span>
                        </li>
                      </ul>

                      <div className="bracket-challenge-head">
                        {
                          !this.state.tournamentStart ? this.state.isEditable ? (
                            this.state.partiallySaved ? (
                              <div
                                className="submit-bracket"
                                style={{ position: "unset" }}
                              >
                                <input
                                  type="button"
                                  className="submit-style"
                                  value={
                                    this.state.partiallyUpdate === false
                                      ? "Edit Bracket"
                                      : "Update Bracket"
                                  }
                                  onClick={() =>
                                    this.state.partiallyUpdate === false
                                      ? this.setState({ partiallyUpdate: true })
                                      : this.submitBracket()
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className="submit-bracket"
                                style={{ position: "unset" }}
                              >
                                <input
                                  type="button"
                                  className="submit-style"
                                  value="SUBMIT BRACKET"
                                  onClick={() => this.submitBracket()}
                                />
                              </div>
                            )
                          ) : (
                            ""
                          ) : ''
                        }

                      </div>
                      <div className="premier-cup-head" id="mensPremierCup">
                        PREMIER CUP
                      </div>

                      <div
                        className={`
                                    ${this.state.addClass
                            ? "pairs-wrap sec-pair"
                            : "pairs-wrap"
                          }
                                    ${this.state.addClass1 ? "sec-pair1" : ""}
                                    ${this.state.addClass2 ? "sec-pair2" : ""}
                                `}
                      >
                        {/* <div className="pairs-wrap"> */}
                        {/* first pair */}
                        <div className="mW mW_1">
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* second pair */}
                        <div className="mW mW_2">
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>

                        {/* third pair */}
                        <div className="mW mW_3">
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueBottomLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomLeft}
                            round={1}
                            position={[5, 6]}
                          />
                        </div>
                        {/* fourth pair */}
                        <div className="mW mW_4">
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueBottomLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomLeft}
                            round={1}
                            position={[7, 8]}
                          />
                        </div>
                        {/* 5th pair */}
                        <div className="mW mW_5" style={{ zIndex: "0" }}>
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* 6th pair */}
                        <div className="mW mW_6" style={{ zIndex: "0" }}>
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>
                        {/* 7th pair */}
                        <div className="mW mW_7" style={{ zIndex: "0" }}>
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueBottomRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomRight}
                            round={1}
                            position={[5, 6]}
                          />
                        </div>
                        {/* 8th pair */}
                        <div className="mW mW_8" style={{ zIndex: "0" }}>
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueBottomRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomRight}
                            round={1}
                            position={[7, 8]}
                          />
                        </div>
                        {/* 9th pair */}
                        <div
                          style={{ zIndex: "3" }}
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_9 m9Sec"
                              : "mW mW_9"
                            }
                                    ${this.state.addClass1 ? "m9Sec" : ""}
                                    ${this.state.addClass2 ? "m9Sec" : ""}
                                `}
                        >
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        {/* 10th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_10 m10Sec"
                              : "mW mW_10"
                            }
                                    ${this.state.addClass1 ? "m10Sec" : ""}
                                    ${this.state.addClass2 ? "m10Sec" : ""}
                                `}
                        >
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueBottomLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomLeft}
                            type={2}
                            round={2}
                            position={[3, 4]}
                          />
                        </div>

                        {/* 11th pair */}
                        <div
                          style={{ zIndex: "1" }}
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_11 m11Sec"
                              : "mW mW_11"
                            }
                                    ${this.state.addClass1 ? "m11Sec" : ""}
                                    ${this.state.addClass2 ? "m11Sec" : ""}
                                `}
                        >
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        {/* 12th pair */}
                        <div
                          style={{ zIndex: "1" }}
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_12 m12Sec"
                              : "mW mW_12"
                            }
                                    ${this.state.addClass1 ? "m12Sec" : ""}
                                    ${this.state.addClass2 ? "m12Sec" : ""}
                                `}
                        >
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueBottomRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomRight}
                            type={2}
                            round={2}
                            position={[3, 4]}
                          />
                        </div>
                        {/* 13th pair */}
                        <div
                          style={{ zIndex: "1" }}
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_13 m13Sec"
                              : "mW mW_13"
                            }
                                    ${this.state.addClass1 ? "m13Sec1" : ""}
                                    ${this.state.addClass2 ? "m13Sec1" : ""}
                                `}
                        >
                          <div
                            className={`
                                        ${this.state.addClass
                                ? "regions-left regTop"
                                : "regions-left"
                              }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                          >
                            {this.state.mensTopLeftText}
                          </div>
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            type={2}
                            round={3}
                            position={[1]}
                          />
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueBottomLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomLeft}
                            type={2}
                            round={3}
                            position={[2]}
                          />
                          <div
                            className={`
                                        ${this.state.addClass
                                ? "regions-left-bottom regBot"
                                : "regions-left-bottom"
                              }
                                        ${this.state.addClass1 ? "regBot1" : ""}
                                    `}
                          >
                            {this.state.mensBottomLeftText}
                          </div>
                        </div>
                        {/* 14th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_14 m14Sec"
                              : "mW mW_14"
                            }
                                    ${this.state.addClass1 ? "m14Sec1" : ""}
                                    ${this.state.addClass2 ? "m14Sec1" : ""}
                                `}
                        >
                          {/* <div className="regions-right">WEST</div> */}
                          <div
                            className={`
                                        ${this.state.addClass
                                ? "regions-right regTop"
                                : "regions-right"
                              }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                          >
                            {this.state.mensTopRightText}
                          </div>
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            type={2}
                            round={3}
                            position={[1]}
                          />
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueBottomRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomRight}
                            type={2}
                            round={3}
                            position={[2]}
                          />
                          {/* <div className="regions-right-bottom">CENTRAL</div> */}
                          <div
                            className={`
                                        ${this.state.addClass
                                ? "regions-right-bottom regBot"
                                : "regions-right-bottom"
                              }
                                        ${this.state.addClass1 ? "regBot1" : ""}
                                    `}
                          >
                            {this.state.mensBottomRightText}
                          </div>
                        </div>
                        {/* 15th pair */}
                        <div
                          style={{ zIndex: "0" }}
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_15 m15Sec"
                              : "mW mW_15"
                            }
                                    ${this.state.addClass1 ? "m15Sec1" : ""}
                                    ${this.state.addClass2 ? "m15Sec2" : ""}
                                `}
                        >
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={4}
                            position={[1]}
                          />
                        </div>
                        {/* 16th pair */}
                        <div
                          style={{ zIndex: "0" }}
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_16 m16Sec"
                              : "mW mW_16"
                            }
                                    ${this.state.addClass1 ? "m16Sec1" : ""}
                                    ${this.state.addClass2 ? "m16Sec2" : ""}
                                `}
                        >
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={4}
                            position={[2]}
                          />
                        </div>
                        {/* 17th pair */}
                        <div
                          style={{ zIndex: 1 }}
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_17 m17Sec"
                              : "mW mW_17"
                            }
                                    ${this.state.addClass1 ? "m17Sec1" : ""}
                                    ${this.state.addClass2 ? "m17Sec2" : ""}
                                `}
                        >
                          <div
                            className={
                              parseInt(this.state.tieBrackerScore.team1) +
                                parseInt(this.state.tieBrackerScore.team2) >
                                0
                                ? "nat-champion-text active"
                                : "nat-champion-text"
                            }
                          >
                            National Championship
                            {parseInt(this.state.tieBrackerScore.team1) +
                              parseInt(this.state.tieBrackerScore.team2) >
                              0 ? (
                              <div>
                                Total Points:
                                {parseInt(this.state.tieBrackerScore.team1) +
                                  parseInt(this.state.tieBrackerScore.team2)}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={5}
                            position={[1]}
                          />
                          <BracketBox
                            loserIds={this.state.mensLeagueloserIds}
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={5}
                            position={[2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                      </div>
                      {/* pairs end */}
                      <div className="logo-brackets-bottom">
                        <img src="/assets/images/logo-brackets.png" alt="Bracket challenge" />
                      </div>
                    </div>

                    {/* -------------------------survivor cup bracket ------------------------------*/}
                    <div
                      className="brackets survivor-bracket-wrap"
                      id="survivorsCup"
                    >
                      <div className="survivor-head">
                        SURVIVOR BOWL
                      </div>
                      <div
                        className="survivor-bracket-outer"
                        style={{ paddingTop: "45px" }}
                      >
                        <div className="survivor-bracket-inner">
                          {/* left col pair 1 */}
                          <div className="mW mW_18">
                            <BracketBox
                              loserIds={this.state.survivorloserIds}
                              stateKey="survivorsLeft"
                              handleClick={this.handleClick}
                              data={this.state.survivorsLeft}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col pair 2 */}
                          <div className="mW mW_19">
                            <BracketBox
                              loserIds={this.state.survivorloserIds}
                              stateKey="survivorsLeft"
                              handleClick={this.handleClick}
                              data={this.state.survivorsLeft}
                              type={2}
                              round={1}
                              position={[3, 4]}
                            />
                          </div>
                          {/* right col pair 1 */}
                          <div className="mW mW_20" style={{ zIndex: 2 }}>
                            <BracketBox
                              loserIds={this.state.survivorloserIds}
                              stateKey="survivorsRight"
                              handleClick={this.handleClick}
                              data={this.state.survivorsRight}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col pair 2 */}
                          <div className="mW mW_21" style={{ zIndex: 2 }}>
                            <BracketBox
                              loserIds={this.state.survivorloserIds}
                              stateKey="survivorsRight"
                              handleClick={this.handleClick}
                              data={this.state.survivorsRight}
                              type={2}
                              round={1}
                              position={[3, 4]}
                            />
                          </div>
                          {/* left col 2 */}
                          <div className="mW mW_22">
                            <BracketBox
                              loserIds={this.state.survivorloserIds}
                              stateKey="survivorsLeft"
                              handleClick={this.handleClick}
                              data={this.state.survivorsLeft}
                              type={2}
                              round={2}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col 2 */}
                          <div className="mW mW_23" style={{ zIndex: 1 }}>
                            <BracketBox
                              loserIds={this.state.loserIds}
                              stateKey="survivorsRight"
                              handleClick={this.handleClick}
                              data={this.state.survivorsRight}
                              type={2}
                              round={2}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col 3 */}
                          <div className="mW mW_24" style={{ zIndex: 0 }}>
                            <BracketBox
                              loserIds={this.state.survivorloserIds}
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={3}
                              position={[1]}
                            />
                          </div>
                          {/* right col 3 */}
                          <div className="mW mW_25">
                            <BracketBox
                              loserIds={this.state.survivorloserIds}
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={3}
                              position={[2]}
                            />
                          </div>
                          {/* center pair */}
                          <div className="mW mW_26" style={{ zIndex: 1 }}>
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              loserIds={this.state.survivorloserIds}
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={4}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                        </div>
                        <div className="bracket-bottom-text">
                          1st round losers from <br />
                          Premier Cup
                        </div>
                      </div>
                    </div>
                    {/* -------------------------challenger cup bracket ------------------------------*/}
                    <div
                      className="brackets challenge-bracket-wrap"
                      id="challengeCup"
                    >
                      <div className="challenge-head">
                        CHALLENGE  SHIELD
                      </div>
                      <div className="challenge-bracket-outer">
                        <div className="challenge-bracket-inner">
                          {/* left col pair 1 */}
                          <div className="mW mW_27">
                            <BracketBox
                              loserIds={this.state.challengeloserIds}
                              stateKey="challengeLeft"
                              handleClick={this.handleClick}
                              data={this.state.challengeLeft}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col pair 1 */}
                          <div className="mW mW_28">
                            <BracketBox
                              loserIds={this.state.challengeloserIds}
                              stateKey="challengeRight"
                              handleClick={this.handleClick}
                              data={this.state.challengeRight}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col 2 */}
                          <div className="mW mW_29" style={{ zIndex: 0 }}>
                            <BracketBox
                              loserIds={this.state.challengeloserIds}
                              stateKey="challengeCenter"
                              handleClick={this.handleClick}
                              data={this.state.challengeCenter}
                              type={2}
                              round={2}
                              position={[1]}
                            />
                          </div>
                          {/* lerightft col 2 */}
                          <div className="mW mW_30">
                            <BracketBox
                              loserIds={this.state.challengeloserIds}
                              stateKey="challengeCenter"
                              handleClick={this.handleClick}
                              data={this.state.challengeCenter}
                              type={2}
                              round={2}
                              position={[2]}
                            />
                          </div>
                          {/* center col */}
                          <div className="mW mW_31" style={{ zIndex: 0 }}>
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              loserIds={this.state.challengeloserIds}
                              stateKey="challengeCenter"
                              handleClick={this.handleClick}
                              data={this.state.challengeCenter}
                              type={2}
                              round={3}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                        </div>
                        <div
                          className="bracket-bottom-text challenge-bottom-text"
                          style={{ left: "0px" }}
                        >
                          1st round losers of
                          <br /> Survivor Bowl
                        </div>
                      </div>
                    </div>
                    {/* -------------------------champion cup bracket ------------------------------*/}
                    <div
                      className="brackets champion-bracket-wrap"
                      id="championCup"
                    >
                      <div className="champion-head">
                        CONTENDER PLATE
                      </div>
                      <div className="champion-bracket-outer">
                        <div className="champion-bracket-inner">
                          {/* left col pair 1 */}
                          <div className="mW mW_32">
                            <BracketBox
                              loserIds={this.state.championloserIds}
                              stateKey="championLeft"
                              handleClick={this.handleClick}
                              data={this.state.championLeft}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col pair 1 */}
                          <div className="mW mW_33">
                            <BracketBox
                              loserIds={this.state.championloserIds}
                              stateKey="championRight"
                              handleClick={this.handleClick}
                              data={this.state.championRight}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col 2 */}
                          <div className="mW mW_34" style={{ zIndex: 0 }}>
                            <BracketBox
                              loserIds={this.state.championloserIds}
                              stateKey="championCenter"
                              handleClick={this.handleClick}
                              data={this.state.championCenter}
                              type={2}
                              round={2}
                              position={[1]}
                            />
                          </div>
                          {/* lerightft col 2 */}
                          <div className="mW mW_35">
                            <BracketBox
                              loserIds={this.state.championloserIds}
                              stateKey="championCenter"
                              handleClick={this.handleClick}
                              data={this.state.championCenter}
                              type={2}
                              round={2}
                              position={[2]}
                            />
                          </div>
                          {/* center col */}
                          <div className="mW mW_36" style={{ zIndex: 0 }}>
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              loserIds={this.state.championloserIds}
                              stateKey="championCenter"
                              handleClick={this.handleClick}
                              data={this.state.championCenter}
                              type={2}
                              round={3}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                        </div>
                        <div
                          className="bracket-bottom-text challenge-bottom-text"
                          style={{ left: "0px" }}
                        >
                          2nd round losers of <br />
                          Premier Cup
                        </div>
                      </div>
                      {
                        !this.state.tournamentStart ?
                          this.state.isEditable ? (
                            this.state.partiallySaved ? (
                              <div
                                className="submit-bracket"
                                style={{ position: "unset", marginTop: "20px" }}
                              >
                                <input
                                  type="button"
                                  className="submit-style"
                                  value={
                                    this.state.partiallyUpdate === false
                                      ? "Edit Bracket"
                                      : "Update Bracket"
                                  }
                                  onClick={() =>
                                    this.state.partiallyUpdate === false
                                      ? this.setState({ partiallyUpdate: true })
                                      : this.submitBracket()
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className="submit-bracket"
                                style={{ position: "unset", marginTop: "20px" }}
                              >
                                <input
                                  type="button"
                                  className="submit-style"
                                  value="SUBMIT BRACKET"
                                  onClick={() => this.submitBracket()}
                                />
                              </div>
                            )
                          ) : (
                            ""
                          ) : ''
                      }

                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <Placeaddsm /> */}
            <div
              className="modal fade WelcomeModal"
              style={{
                display: `${this.state.tieBrackerModal ? "block" : "none"}`,
                opacity: "1",
              }}
              id="WelcomeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="rugby-tie-bracker">
                <div
                  className="modal-dialog modal-dialog-centered"
                  style={{ width: "320px" }}
                  width="350"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <Button
                        style={{ display: "none" }}
                        className="btn closebtn"
                        onClick={() =>
                          this.setState({ tieBrackerModal: false })
                        }
                      >
                        <img src={closeIcon} alt="deafult" />
                      </Button>
                    </div>
                    <div className="modal-body ">
                      <div className="tie-title">
                        Tie Breaker
                        <div className="tie-subtitle">Pick the final score</div>
                      </div>
                      <div className="tie-title" style={{ marginTop: "10px" }}>
                        Total :{" "}
                        {parseInt(this.state.tieBrackerScore.team1) +
                          parseInt(this.state.tieBrackerScore.team2)}
                      </div>

                      <div
                        className="team-tiles"
                        style={
                          this.state.tieBrackerMatch.team2
                            ? this.state.tieBrackerMatch.winner_id ===
                              this.state.tieBrackerMatch.team2.team_id ||
                              this.state.tieBrackerMatch.winner ===
                              this.state.tieBrackerMatch.team2.team_id
                              ? { flexDirection: "column-reverse" }
                              : {}
                            : {}
                        }
                      >
                        <div className="tile" style={{ marginBottom: "10px" }}>
                          <div className="tile-box">
                            <div className="flag">
                              {this.state.tieBrackerMatch.team1
                                ? this.state.tieBrackerMatch.team1.name
                                : ""}
                            </div>
                            <input
                              type="text"
                              className="score"
                              onChange={(e) =>
                                this.setTieBreakerScore(e, "team1")
                              }
                              value={this.state.tieBrackerScore.team1}
                            />
                          </div>
                          <div className="tile-text">
                            {this.state.tieBrackerMatch.team1
                              ? this.state.tieBrackerMatch.winner_id ===
                                this.state.tieBrackerMatch.team1.team_id ||
                                this.state.tieBrackerMatch.winner ===
                                this.state.tieBrackerMatch.team1.team_id
                                ? "WINNER"
                                : "LOSER"
                              : "LOSER"}
                          </div>
                        </div>
                        <div className="tile" style={{ marginBottom: "10px" }}>
                          <div className="tile-box">
                            <div className="flag">
                              {this.state.tieBrackerMatch.team2
                                ? this.state.tieBrackerMatch.team2.name
                                : ""}
                            </div>
                            <input
                              type="text"
                              className="score"
                              onChange={(e) =>
                                this.setTieBreakerScore(e, "team2")
                              }
                              value={this.state.tieBrackerScore.team2}
                            />
                          </div>
                          <div className="tile-text">
                            {this.state.tieBrackerMatch.team2
                              ? this.state.tieBrackerMatch.winner_id ===
                                this.state.tieBrackerMatch.team2.team_id ||
                                this.state.tieBrackerMatch.winner ===
                                this.state.tieBrackerMatch.team2.team_id
                                ? "WINNER"
                                : "LOSER"
                              : "LOSER"}
                          </div>
                        </div>
                      </div>
                      <div className="error-message">
                        {this.state.tiebrackerError}
                      </div>
                      <div
                        className="submit-bracket-2"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          style={{ width: "100px" }}
                          type="button"
                          className="submit-style"
                          value="Save"
                          onClick={() => this.saveTieBreakerScore()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              imgsrc={logo}
              toggle={() => {
                this.setState({ modalIsOpen: false }) 
                if( this.state.crossBtn ) {
                  this.modalCallback();
                }
            }}
              showModal={this.state.modalIsOpen}
              title="MEN’S PREMIER CUP"
              className="rugby-mens-bracket"
              bodytext={this.state.modalMsg}
              btn1value={this.state.btn1value}
              btn2value={this.state.btn2value}
              button1Click={() => this.setState({ modalIsOpen: false })}
              button2Click={this.forceUpdate}

              
              savebtnvalue={this.state.savebtnvalue}
              savebtnClick={this.savebtnClick}

              leavebtnvalue={this.state.leavebtnvalue}
              leavebtnClick={this.modalCallback}
            />
          </div>
          <ConfirmationModal
            {...this.state.modalState}
          />
        </div>
      </Aux>
    );
  }
}
export default withRouter(RugbyBracket);
