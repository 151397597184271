import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Menu from "../screens/includes/Navbar";
import Aux from "../hoc/_Aux";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import { ReactComponent as Checkmark } =  "https://d7le4ielk3ho1.cloudfront.net/src_images/checkmark.svg";
import axios from "axios";
import { globalVariable } from "../utils/global";
import { consts } from "../utils/validation";
import { useHistory } from "react-router";
import Login from "../components/LoginModal/Login";
function VerifyEmail(props) {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const [show,setShow] = useState(false);
  let [responseData, setResponseData] = React.useState("");
  let [responseDataB, setResponseDataB] = React.useState("");

  let { token } = useParams();
  const history = useHistory();
const handleShow = () => setShow(true)
  useEffect(() => {
    document.title = "Verify Email";
    console.warn({ token });
    let BaseUrl =
      globalVariable.baseURL + "manage-user/verifyEmailToken/" + token;

    fetch(BaseUrl).then((response) => {
      response.json().then((result) => {
        console.warn(result);
        if (result.code === 200) {
          setResponseData(result);
          setResponseDataB(false);
          // alert(result.message);
          // setTimeout(() => {
          //   history.push({
          //     pathname: "/",
          //   });
          // }, 5000);
        } else {
          setResponseDataB(result);
          setResponseData(false);
          // setTimeout(() => {
          //   history.push({
          //     pathname: "/sign-up",
          //   });
          // }, 5000);
          // alert(result.message);
        }
      });
    });
  }, []);

  return (
    <div>
      <Menu />
      <div className="Maincontent">
        <div className="verify-mail-container">
          <Container>
            <Row>
              <div className="col-12">
                <div className="checkmar-outer mb-4">{/* <Checkmark /> */}</div>
                <div className="verify-msg">
                  {responseData && (
                    <>
                      <blockquote style={{ color: "#14376f", fontWeight: 700 }}>
                        {responseData && responseData.message}
                        {responseData &&
                          responseData.data &&
                          responseData.message}
                      </blockquote>
                      <button
                        type="button"
                        class="btn  btn-lg"
                        style={{ background: "#14376f", color: "#fff" }}
                        onClick={handleShow}
                      >
                        Login
                      </button>
                      {show && (
                        <Login
                          show={show}
                          onClick={() => setShow(false)}
                          // onClickB={this.setAddClassb}
                          redirectUrl={"/create-brackets"}
                        />
                      )}
                    </>
                  )}
                  {responseDataB && (
                    <>
                      <blockquote style={{ color: "red", fontWeight: 700 }}>
                        {responseDataB && responseDataB.message}
                        {responseDataB &&
                          responseDataB.data &&
                          responseDataB.message}
                      </blockquote>
                      <button
                        type="button"
                        class="btn  btn-lg"
                        style={{ background: "#14376f", color: "#fff" }}
                        onClick={handleShow}
                      >
                        Login
                      </button>
                      {show && (
                        <Login
                          show={show}
                          onClick={() => setShow(false)}
                          // onClickB={this.setAddClassb}
                          redirectUrl={"/create-brackets"}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
