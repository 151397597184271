import React, { Component } from "react";
import Timer from "./timer";

class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [{ date: "May 27, 2022 23:59" }],
      newDate: "",
    };
  }
  addNewEvent = () => {
    let evt = { date: this.state.newDate };
    let newEvents = this.state.events.concat(evt);
    this.setState({
      newDate: "",
      events: newEvents,
    });
  };

  render() {
    let events = this.state.events.map((evt) => (
      <Timer key={evt.date} eventDate={evt.date} />
    ));
    return <div className="timer-outer">{events}</div>;
  }
}

export default CountdownTimer;
