import React, { useState } from 'react';
import Aux from "../hoc/_Aux";
import { withRouter, Link } from "react-router-dom";
import MemberListInfo from "./MemberListInfo";
import MailIcon from "../assets/images/email.svg";
import InviteIcon from "../assets/images/invite.svg";
import InviteFriendsModal from './groupModal/InviteFriendsModal';
import SendEmailModal from './groupModal/SendEmailModal';
 
function MemberListTable (props){
    console.log( 'memberlist data==>', props);
    const [inviteFriends, setInviteFriends] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [modalType, setmodalType] = useState(null);

    const openInviteFriends = (e)=> {
        e.preventDefault();
        setmodalType('invite');
        setSendEmail(true);
    }
    const closeInviteFriends = ()=> setInviteFriends(false);
    const openSendEmail_old = (e)=> {
        e.preventDefault();
        setmodalType('send')
        setSendEmail(true);

    }

    const openSendEmail = (e)=> {
        // debugger;
        e.preventDefault();
        let email = [];
        if( props.memberList && props.memberList.length ) {
            props.memberList.map((d, i) => {
                if( email.indexOf(d.email) === -1) {
                    email.push(d.email);
                }
            })
            if( email.length ) {
                window.location.href = `mailto:${email.join(';')}`;
                // window.open(
                //     `mailto:${email.join(';')}`,
                //     '_blank' // <- This is what makes it open in a new window.
                //   );
            }
        }
        

    }


    const closeSendEmail = ()=> {
        setSendEmail(false)
        setmodalType(null);
    };

    const callBack = (id) => {
        props.getmemberList && props.getmemberList(id)
    }

  return (
    <Aux>        
        <div className="white-box-bottom">
            <div className="memberlist-table-outer">
                <div className="memberlist-top-wrap">
                    <div className="total-members">
                        Members <span>({props.groupData ? props.groupData.member_count : 0})</span>
                        &nbsp;&nbsp;
                        Brackets <span>({props.memberList ? props.memberList.length : 0})</span>
                    </div>
                    <div className="member-right-links">
                        <span>
                            <img src={MailIcon} alt="email" />
                            <Link to="#" onClick={(e)=> openSendEmail(e)}>Send Email</Link>
                        </span>
                        <span>
                            <img src={InviteIcon} alt="Invite" />
                            <Link to="#" onClick={(e)=> openInviteFriends(e)}>Invite Friends</Link>
                        </span>
                    </div>
                </div>
                <MemberListInfo
                    memberList={props.memberList ? props.memberList : []}
                    callBack={callBack}
                />
            </div>
        </div>
        <InviteFriendsModal
            show={inviteFriends}
            hide={closeInviteFriends}
        />
        <SendEmailModal
            show={sendEmail}
            hide={closeSendEmail}
            modalType={modalType}
            groupData={props.groupData}
        />
    </Aux> 
  )
}
export default MemberListTable;