import React from "react";
import Leaderboard from "../components/Leaderboard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { GetData } from "../utils/apiRequestHandler";

class Teamscores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamsdata: [],
      slidecount: "",
    };
  }

  GetGamelist = () => {
    GetData("getLatestGames").then((response) => {
      console.log('top slider', response)
      if (response.status === true) {
        const persons = response.data.games;
        this.setState({ teamsdata: persons });
        console.warn(this.state.teamsdata);
        if (this.state.teamsdata.length < 7) {
          this.setState({ slidecount: this.state.teamsdata.length });
        } else if (this.state.teamsdata == null || this.state.teamsdata == "") {
          this.setState({ slidecount: 1 });
          return;
        } 
        else if (this.state.teamsdata < 5) {
          this.setState({ slidecount: 2 });
          return;
        } else if (this.state.teamsdata < 2) {
          this.setState({ slidecount: 1 });
          return;
        } else {
          this.setState({ slidecount: 8 });
        }
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  componentDidMount() {
    this.GetGamelist();
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 1000,
      slidesToShow: this.state.slidecount,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1489, // tablet breakpoint
          settings: {
            slidesToShow: this.state.slidecount < 6 ? 3 : 8,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1320, // tablet breakpoint
          settings: {
            slidesToShow: this.state.slidecount < 6 ? 3 : 6,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1180, // tablet breakpoint
          settings: {
            slidesToShow: this.state.slidecount < 6 ? 3 : 5,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1004, // tablet breakpoint
          settings: {
            slidesToShow: this.state.slidecount < 6 ? 3 : 4,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 900, // tablet breakpoint
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 700, // tablet breakpoint
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 528, // tablet breakpoint
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 300, // mobile breakpoint
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        {this.state.teamsdata.length !== 0 ? 
          <div
            className={`leaderord ${
              this.state.slidecount < 4 ? "leaderbord50" : "leaderbord100"
            }`}
          >
            <div className="slider">
              <Slider {...settings}>
                {this.state.teamsdata.map((team) => {                
                  if (
                    this.state.slidecount !== null ||
                    this.state.slidecount !== ""
                  ) {
                    return (
                      <div className="slider">
                        <div className="adminleaderbord">
                          <Leaderboard
                            // imgA={pointsimgA}
                            // imgB={pointsimgB}
                            looserteamA={
                              team.team1.score < team.team2.score
                                ? "looserteam"
                                : ""
                            }
                            looserteamB={
                              team.team2.score < team.team1.score
                                ? "looserteam"
                                : ""
                            }
                            teamOneName={team.team1.name}
                            teamOneScore={team.team1.score}
                            teamTwoName={team.team2.name}
                            teamTwoScore={team.team2.score}
                            gender={team.gender}
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="scorebordspace">
                        <div className="lead-img">
                          <p>PLACE YOUR LEADERBOARD AD HERE</p>
                        </div>
                      </div>
                    );
                  }
                })}
              </Slider>
            </div>
            <div className="allscore" style={{ display: "none" }}>
              <button className="btn btn-lg allscrebtn">ALL SCORE</button>
            </div>
          </div>
        
        : null}
        
      </>
    );
  }
}

export default Teamscores;
