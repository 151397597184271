import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Input from "../Input";
import Bluebtn from "../Bluebtn";
import { globalVariable } from "../../utils/global";
import { consts } from "../../utils/validation";
import axios from "axios";

const eyeopen =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const eyeclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eyestate: false,
      eyecurrentstate: false,
      eyeconfirmstate: false,
      inputcurentpasswordtype: false,
      inputpasswordtype: false,
      inputconfirmpasswordtype: false,
      currentPassword: null,
      errcurrentPassword: "",
      newPassword: null,
      oldPassword: null,
      erroldPassword: "",
      succesMessage: "",
      errorMessage: "",
      showSpinner: false,
      confirmPassword: null,
      errconfirmPassword: "",
      errpassword: "",
      errmessage: "",
      token: null,
    };
    this.passwordshowhide = this.passwordshowhide.bind(this);
    this.confirmpasswordshowhide = this.confirmpasswordshowhide.bind(this);
    this.currentpasswordshowhide = this.currentpasswordshowhide.bind(this);
  }

  currentpasswordshowhide() {
    this.setState((prevState) => ({
      inputcurentpasswordtype: !prevState.inputcurentpasswordtype,
    }));
    this.setState((prevState) => ({
      eyecurrentstate: !prevState.eyecurrentstate,
    }));
  }
  passwordshowhide() {
    this.setState((prevState) => ({ eyestate: !prevState.eyestate }));
    this.setState((prevState) => ({
      inputpasswordtype: !prevState.inputpasswordtype,
    }));
  }
  confirmpasswordshowhide() {
    this.setState((prevState) => ({
      inputconfirmpasswordtype: !prevState.inputconfirmpasswordtype,
    }));
    this.setState((prevState) => ({
      eyeconfirmstate: !prevState.eyeconfirmstate,
    }));
  }
  handleeyestatecurrent() {
    this.setState({ eyecurrentstate: false, eyecurrentstate: false });
  }
  handleeyestate() {
    this.setState({ eyeconfirmstate: false, eyeconfirmstate: false });
  }
  handleeyestateA() {
    this.setState({ eyestate: false, eyestate: false });
  }
  validation() {
    let formIsValid = true;

    if (
      !consts.passwordRegex.test(this.state.newPassword) ||
      this.state.newPassword == null
    ) {
      formIsValid = false;
      this.setState({
        showSpinner: false,
        errpassword:
          "Password must contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
      });
    }
    if (this.state.oldPassword == null || this.state.oldPassword == "") {
      formIsValid = false;
      this.setState({
        errcurrentPassword: "Please fill current password",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    }
    if (this.state.newPassword == null || this.state.newPassword == "") {
      formIsValid = false;
      this.setState({
        errpassword: "Please fill password",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    }
    if (
      this.state.confirmPassword == null ||
      this.state.confirmPassword == ""
    ) {
      formIsValid = false;
      this.setState({
        errconfirmPassword: "Please fill confirm password",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    }
    if (this.state.confirmPassword !== this.state.newPassword) {
      formIsValid = false;
      this.setState({
        errconfirmPassword: "Password and Confirm Password must match",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    }

    return formIsValid;
  }
  submitchangepassword() {
    if (this.validation()) {
      this.setState({
        showSpinner: true,
      });
      let token = JSON.parse(localStorage.getItem("login"));
      const accesstoken = token.login;
      console.warn(accesstoken);

      const data = {
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
        oldPassword: this.state.oldPassword,
      };

      let BaseUrl = globalVariable.baseURL + "manage-user/changePassword";

      axios
        .post(BaseUrl, data, {
          headers: {
            access_token: accesstoken,
          },
        })
        .then((resp) => {
          console.warn("resp", resp);
          console.warn(resp);
          if (resp.data.code === 200) {
            // alert(resp.data.message);
            this.setState({
              succesMessage: resp.data.message,
              showSpinner: false,
              errcurrentPassword: false,
            });
            this.setState({ errorMessage: false });
          }
        })
        .catch((err) => {
          // alert(err.response.data.message);
          this.setState({
            succesMessage: false,
            showSpinner: false,
            errcurrentPassword: false,
          });
          this.setState({ errorMessage: err.response.data.message });
        });
    }
  }

  render() {
    return (
      <>
        <div>
          <div className="changePass">
            <Row>
              <Col style={{ padding: "0" }}>
                <Form.Group as={Col} md="8">
                  <div className="password_b">
                    {" "}
                    <Input
                      type={
                        this.state.inputcurentpasswordtype ? "text" : "password"
                      }
                      label="Current Password"
                      placeholder="Current Password"
                      onChange={(event) => {
                        this.setState({
                          oldPassword: event.target.value,
                          erroldPassword: "",
                        });
                      }}
                    />
                    <span
                      className="password__show"
                      onClick={this.currentpasswordshowhide}
                    >
                      {this.state.eyecurrentstate ? (
                        <img
                          onBlur={this.handleeyestatecurrent.bind(this)}
                          src={eyeopen}
                          default="open"
                        />
                      ) : (
                        <img
                          onBlur={this.handleeyestatecurrent.bind(this)}
                          src={eyeclose}
                          default="close"
                        />
                      )}{" "}
                    </span>
                  </div>

                  <Form.Text
                    style={{ marginBottom: "10px" }}
                    className="text-muted"
                  >
                    <div className="error">{this.state.errcurrentPassword}</div>
                  </Form.Text>
                </Form.Group>

                <Form.Group as={Col} md="8">
                  <div className="password_b">
                    <Input
                      type={this.state.inputpasswordtype ? "text" : "password"}
                      label="New Password"
                      placeholder="New Password"
                      onChange={(event) => {
                        this.setState({
                          newPassword: event.target.value,
                          errpassword: "",
                        });
                      }}
                    />
                    <span
                      className="password__show"
                      onClick={this.passwordshowhide}
                    >
                      {this.state.eyestate ? (
                        <img
                          onBlur={this.handleeyestateA.bind(this)}
                          src={eyeopen}
                          default="open"
                        />
                      ) : (
                        <img
                          onBlur={this.handleeyestateA.bind(this)}
                          src={eyeclose}
                          default="close"
                        />
                      )}{" "}
                    </span>
                  </div>

                  <Form.Text
                    style={{ marginBottom: "10px" }}
                    className="text-muted"
                  >
                    <div className="error">{this.state.errpassword}</div>
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} md="8">
                  <div className="password_b">
                    <Input
                      type={
                        this.state.inputconfirmpasswordtype
                          ? "text"
                          : "password"
                      }
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      onChange={(event) => {
                        this.setState({
                          confirmPassword: event.target.value,
                          errconfirmPassword: "",
                        });
                      }}
                    />
                    <span
                      className="password__show"
                      onClick={this.confirmpasswordshowhide}
                    >
                      {this.state.eyeconfirmstate ? (
                        <img
                          onBlur={this.handleeyestate.bind(this)}
                          src={eyeopen}
                          default="open"
                        />
                      ) : (
                        <img
                          onBlur={this.handleeyestate.bind(this)}
                          src={eyeclose}
                          default="close"
                        />
                      )}{" "}
                    </span>
                  </div>

                  <Form.Text
                    style={{ marginBottom: "10px" }}
                    className="text-muted"
                  >
                    <div className="error">{this.state.errconfirmPassword}</div>
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: "0" }}>
                <Form.Text
                  style={{ marginBottom: "10px" }}
                  className="text-muted"
                >
                  {this.state.errorMessage && (
                    <div style={{ color: "red" }} className="error">
                      {this.state.errorMessage}
                    </div>
                  )}
                  {this.state.succesMessage && (
                    <div style={{ color: "green" }} className="error">
                      {this.state.succesMessage}
                    </div>
                  )}
                </Form.Text>
                <div className="frombtn">
                  <Bluebtn
                    value="Save"
                    onClick={() => {
                      this.submitchangepassword();
                    }}
                    spinner={
                      this.state.showSpinner && (
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default ChangePassword;
