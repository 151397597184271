import React, { Component } from "react";
import GoogleAd from './GoogleAdsense'
class Placeleaderbox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{display:'none'}}>
        <div className="box">
          <GoogleAd slot="1065601590" classNames="page-top" />
        </div>
        <div className="box">
          <GoogleAd slot="4585495460" classNames="page-top" />
        </div>
        <div className="box">
          <GoogleAd slot="4010780398" classNames="page-top" />
        </div>
      </div>
    );
  }
}

export default Placeleaderbox;
