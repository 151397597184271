import React, { Component } from "react";
import { Row } from "react-bootstrap";
import CountdownTimer from "./countdown-timer";
const RugbyWhite =
  "https://d7le4ielk3ho1.cloudfront.net/src_images/logo/rugby-white.png";

class TimerBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="timer-banner-wrap">
        <div className="container">
          <Row>
            <div className="col-12">
              <div className="timer-banner-inner">
                <div className="tm-banner-logo">
                  <img src={RugbyWhite} alt="deafult" />
                </div>
                <div className="tm-banner-timer">
                  <CountdownTimer />
                </div>
                <div className="tm-banner-text">
                  NATIONAL 7s RUGBY
                  <br />
                  BRACKET CHALLENGE 2024
                </div>
              </div>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default TimerBanner;
