import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import RegisterImg from "../assets/images/register-img.jpg";

class Homemidloginbtn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="cardover">
          <Row className="no-gutters card-reverse align-items-center">
            <Col className="card1">
              <img src={RegisterImg} alt="Register" />
            </Col>
            <Col className="card2">
              <div className="log-reg">
                <h3>National 7s RUGBY </h3>
                <h4>BRACKET CHALLENGE 2022</h4>
                
                <div className="registerbtn mt-4">
                  <Link className="btn regis-btn btn-lg" to="/sign-up">
                    Register
                  </Link>
                </div>
                <div className="loginbtn">
                  <button
                    className="btn login-btn btn-lg"
                    onClick={this.props.onClick}
                  >
                    Login
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Homemidloginbtn;
