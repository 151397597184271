import React, { Component } from "react";
import { Form } from "react-bootstrap";

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Form.Label>
          {this.props.label} <span className="star">{this.props.require}</span>
        </Form.Label>
        <Form.Control
          onChange={this.props.onChange}
          type={this.props.type}
          placeholder={this.props.placeholder}
          onBlur={this.props.onBlur}
          maxlength={this.props.maxlength}
          minlength={this.props.minlength}
          pattern={this.props.pattern}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          readOnly={this.props.readOnly}
          className={this.props.className}
        />
      </>
    );
  }
}

export default Input;
