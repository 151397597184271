import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

class Midsectionimg extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Row>
          <Col md="12">
            <div className="wht-bg-box">
              <p>Become a CRC Super Fan</p>
              <div className="bg-img">
                <div className="bg-content">
                  <p>
                    Win four Premier Pass tickets to the 2025 tournament by
                    winning the overall men's or women's bracket.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default Midsectionimg;
