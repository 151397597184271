import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import TimerBanner from "../components/timer-banner";
import CountdownTimer from "../components/countdown-timer";
const men =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/men-tourna.png";
const women =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/women-tourna.png";
const email =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/email-remind.svg";

class FillBracket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = "NCR Rugby | Fill Bracket";
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent invite-friend-wrap fillbracket">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <TimerBanner />
            <div className="invite-body-outer">
              <Container>
                <Row>
                  <div className="col-12">
                    <Tabs defaultActiveKey="profile" transition={false}>
                      <Tab
                        className="tab1"
                        eventKey="profile"
                        title="CREATE BRACKET"
                      >
                        <div className="menswomenpremier">
                          <div className="gendertornament">
                            <div className="button1">
                              <button className="btn btn-mentournament active">
                                <img src={men} alt="deafult" />
                                MEN’S Tournament
                              </button>
                            </div>
                            <div className="button2">
                              <button className="btn btn-womentournament">
                                <img src={women} alt="deafult" />
                                WOMEN’S Tournament
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="createbracket">
                          <div className="inner-body">
                            <h2>THE COUNTDOWN BEGINS</h2>
                            <h4>For Tournament Team Selection</h4>
                            <div className="fillbracet">
                              <div className="countdowntimer">
                                <CountdownTimer />
                              </div>

                              <p>
                                Return to your bracket when the time runs out to
                                make your selections!
                              </p>

                              <div className="emailerremainder">
                                <div className="emailimg">
                                  <img alt="deafult" src={email} />
                                </div>

                                <h5>Need an E-Mail Reminder?</h5>

                                <p>
                                  Once the game is available, we'll send you a
                                  reminder via e-mail.
                                </p>

                                <div className="remidbutton">
                                  <button className="btn btn-remind">
                                    Remind Me!
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="ChangePassword" title="LEADERBOARD">
                        <div className="leaderboard"></div>
                      </Tab>
                    </Tabs>
                  </div>
                </Row>
              </Container>
            </div>
            <Placeaddsm />
          </div>
        </div>
      </Aux>
    );
  }
}
export default FillBracket;
