import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Placeadd from "../components/Placeadd";
import Modal from "../components/Modal";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeleaderbox from "../components/Placeleaderbox";
import TimerBanner from "../components/timer-banner";
import { ReactComponent as InviteIcon } from "../../src/assets/images/Images_Assets/invite-friend-green.svg";
import FaqInvite from "../components/accordion-invite";
import Placebigadd from "../components/Placeyouraddbigbox";
import { globalVariable } from "../utils/global";
import { consts } from "../utils/validation";
import axios from "axios";
import Bluebtn from "../components/Bluebtn";

class InviteFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tag: "",
      showLogin: false,
      comment: "",
      modalIsOpen: false,
      type: "input",
      errTags: "",
      errorMessage: "",
      succesMessage: "",
      errComment: "",
      showSpinner: false,
    };
    this.showHide = () => this.showHide.bind(this);
  }
  componentDidMount() {
    document.title = "NCR Rugby | Invite Friend";
  }
  toggleModal = () =>
    this.setState({
      showLogin: !this.state.showLogin,
    });

  handleChange = (tags) => {
    this.setState({ tags });
    console.warn(tags);
    this.setState({
      errTags: "",
    });
  };
  handleChangeInput = (tag) => {
    this.setState({ tag });
    console.warn(tag);
  };

  invitefrens() {
    this.setState({
      showSpinner: true,
    });

    let token = JSON.parse(localStorage.getItem("login"));
    const accesstoken = token.login;
    console.warn(accesstoken);
    const reqData = {
      mail_to: this.state.tags,
      comment: this.state.comment,
    };

    const regex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";

    if (this.state.tags == null || this.state.tags == "") {
      this.setState({
        errTags: "Please fill email ID",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    } else if (
      !consts.multiplemailRegex.test(this.state.tags) ||
      this.state.tags == null
    ) {
      this.setState({
        errTags: "Please fill valid email ID",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    } else if (this.state.comment == null || this.state.comment == "") {
      this.setState({
        errComment: "Please fill comment box",
        showSpinner: false,
      });
      this.setState({ errTags: false });
    } else {
      let BaseUrl = globalVariable.baseURL + "sendInvite";

      fetch(BaseUrl, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          access_token: accesstoken,
        },
        body: JSON.stringify(reqData),
      })
        .then((data) => data.json())
        .then((resp) => {
          console.log("resp", resp);
          console.warn("resp", resp);
          if (resp.code === 200) {
            this.setState({
              succesMessage: resp.message,
              showSpinner: false,
            });
            this.setState({ errorMessage: false });
          } else {
            this.setState({ errorMessage: resp.message });
            this.setState({ succesMessage: false });
            this.setState({
              showSpinner: false,
            });
          }
          console.warn({ resp });
        });
    }
  }

  render() {
    return (
      <Aux>
        <Menu />
        <div
          className={`Maincontent invite-friend-wrap ${
            this.state.showLogin ? "modal-open" : ""
          }`}
        >
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <TimerBanner />
            <div className="invite-body-outer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="invite-body-inner">
                      {/* Left container */}
                      <div
                        onClick={this.toggleModal}
                        className="invite-inner-left"
                      >
                        <div className="invite-icon">
                          <InviteIcon />
                        </div>
                        <h1>Invite Your Friends</h1>
                        <div className="invite-text">
                          Invite your friends to play the bracket challenge. The
                          brackets are open until midnight on 28 May.
                        </div>
                        <div className="invite-message-box">
                          <Row className="mb-4 mb-lg-5 fromgrid">
                            <div className="col-lg-3">From:</div>
                            <div className="col-lg-9">
                              mm7brackets@ncr.rugby
                            </div>
                          </Row>
                          <Row className="mb-4 mb-lg-5 togrid">
                            <div className="col-lg-3 mb-2 mb-lg-0">To:</div>
                            <div className="col-lg-9">
                              {localStorage.getItem("login") ? (
                                <TagsInput
                                  id="addtags"
                                  addOnBlur={() =>
                                    document.getElementById("addtags").click()
                                  }
                                  value={this.state.tags}
                                  onChange={this.handleChange}
                                  inputValue={this.state.tag}
                                  inputProps={{
                                    placeholder:
                                      "eg. xyz@domainname.com, zzz@domain.com",
                                  }}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="input-invite"
                                  placeholder="eg. xyz@domainname.com, zzz@domain.com"
                                />
                              )}

                              <Form.Text
                                style={{ marginBottom: "10px" }}
                                className="text-muted"
                              >
                                <div className="error">
                                  {this.state.errTags}
                                </div>
                              </Form.Text>
                            </div>
                          </Row>
                          <Row className="mb-4 mb-lg-5">
                            <div className="col-lg-3 mb-2 mb-lg-0">
                              Message:
                            </div>
                            <div className="col-lg-9">
                              <p>
                                “[name] is inviting you to play the National 7s
                                Rugby Bracket Challenge. Sign up and complete
                                your bracket before midnight on 28 May.
                              </p>

                              {/* <p>
                                Get in the action now:
                                <br />
                                https://rugby.com/tournament-challenge-bracket/2021/en/?redirect=tcmen%3A%2F%2Fx-callback-url&ex_cid=tcmen2021_email&inviteuser=e0YyRTc0MzgyLTZGRDEtNDM0MC05OUM0LTdERDg2QzlBOUI2RH0%3D&invitesource=email
                              </p> */}
                            </div>
                          </Row>
                          <Row className="mb-4 mb-lg-5">
                            <div className="col-lg-3 mb-2 mb-lg-0">
                              Your comments:
                            </div>
                            <div className="col-lg-9">
                              <textarea
                                id="address"
                                name="address"
                                className="input-invite textarea-invite"
                                placeholder=" "
                                maxlength="500"
                                type="text"
                                onChange={(event) => {
                                  this.setState({
                                    comment: event.target.value,
                                    errComment: "",
                                  });
                                }}
                              ></textarea>
                              <Form.Text
                                style={{ marginBottom: "10px" }}
                                className="text-muted"
                              >
                                <div className="error">
                                  {this.state.errComment}
                                </div>
                              </Form.Text>
                            </div>
                          </Row>
                          <Row>
                            <div className="col-lg-9 offset-lg-3">
                              <Form.Text
                                style={{
                                  marginBottom: "20px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                                className="text-muted"
                              >
                                {this.state.errorMessage && (
                                  <div
                                    style={{ color: "red" }}
                                    className="error"
                                  >
                                    {this.state.errorMessage}
                                  </div>
                                )}
                                {this.state.succesMessage && (
                                  <div
                                    style={{
                                      color: "green",
                                      textAlign: "left",
                                    }}
                                    className="succesmsg"
                                  >
                                    {this.state.succesMessage}
                                  </div>
                                )}
                              </Form.Text>
                              {localStorage.getItem("login") ? (
                                <button
                                  type="button"
                                  className="invite-submit"
                                  value="SEND INVITATION"
                                  onClick={() => {
                                    this.invitefrens();
                                  }}
                                >
                                  SEND INVITATION
                                  {this.state.showSpinner && (
                                    <i className="fa fa-circle-o-notch fa-spin"></i>
                                  )}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="invite-submit"
                                  value="SEND INVITATION"
                                  onClick={this.toggleModal}
                                >
                                  SEND INVITATION
                                </button>
                              )}
                            </div>

                            <div className="col-lg-9 offset-lg-3"></div>
                          </Row>
                        </div>
                      </div>
                      {/* right container */}
                      <div className="invite-inner-right">
                        <div className="invite-right-top" style={{display:'none'}}>
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/invite-right-img.jpg"
                            alt="deafult"
                          />
                        </div>
                        <div>
                          <Placebigadd />
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="placeleaderbox">
              <Placeleaderbox />
            </div>
            {localStorage.getItem("login") ? null : (
              <Modal
                invitefrend={this.state.showLogin}
                toggle={this.toggleModal}
                showModal={this.state.showLogin}
                title="You will have to login to invite your friends"
                className="invitefrendpopover"
                btnvalue="LOGIN"
              />
            )}
          </div>
        </div>
      </Aux>
    );
  }
}
export default InviteFriend;
