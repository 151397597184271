import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import UploadLogo from "../../assets/images/logo-upload.jpg";
import InputValidation from "../../utils/validationNew";
import Multiselect from 'multiselect-react-dropdown';
import { GetData, PostData, PutData } from "../../utils/apiRequestHandler";
import {consts} from "../../utils/global";

const loginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function JoinGroupModal(props) {
    const [picture, setPicture] = useState(null);
    const [imgData, setImgData] = useState(null);
    const [errFileSize, setErrFileSize] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const {emailRegex} = consts; // -- 
    const [selectedBracket, setselectedBracket] = useState({
        arrList: [],
        objList: []
    })
    
    const [tags, setTags] = useState([]);
    const [tag, setTag] = useState('');
    const [errorMsg, seterrorMsg] = useState('');
    const [successMsg, setsuccessMsg] = useState('');
    const [bracketData, setbracketData] = useState([]);
    const selectBracketErrorMsg = ['Select your bracket'];
    const selectTypeErrorMsg = ['Select your type'];

    const [errors, setErrors] = useState({
        eSelectBracket: false,
        eSelectType: false,
    });

    const [userData, setUserData] = useState({
        selectBracket: {
            value: '',
            validation: [],
            errorMsg: selectBracketErrorMsg,
            errorKey: 'eSelectBracket',
        },
        selectType: {
            value: '',
            validation: [],
            errorMsg: selectTypeErrorMsg,
            errorKey: 'eSelectType',
        }
    });

    useEffect(() => {
        let tempData = [];
        seterrorMsg('');
        setsuccessMsg('');
        tempData = props.bracketData.filter((d) => {
            if (d.type === props.selectedGender) {
                return true;
            }
        });
        // debugger;
        if( props.edit ) {
            GetData(`groups/member/details?id=${props.group_id}`).then((response) => {
                if (response.status && response.data && response.data.rows) {
                    let temparrList = [], tempobjList = [], alreadyAddedBracket = [];
                    response.data.rows.map((d) => {
                        alreadyAddedBracket.push(d.user_bracket_id);
                    })

                    if( tempData && tempData.length ) {
                        tempData.map((d) => {
                            if(alreadyAddedBracket.indexOf(d.id) > '-1') {
                                temparrList.push(d.id);
                                tempobjList.push(d);
                            }
                        })
                    }
                    setbracketData([...tempData]);
                    setselectedBracket({ 
                        arrList: temparrList,
                        objList: tempobjList
                    });

                } else {
                    setselectedBracket({ 
                        arrList: [],
                        objList: []
                    });
                    setbracketData([...tempData]);
                }
            });
        } else {
            setselectedBracket({ 
                arrList: [],
                objList: []
            });
            setbracketData([...tempData]);
        }
    }, [props])

    const onBracketSelect = (sl, si) => {
        let tempList = selectedBracket.arrList;
        setselectedBracket({ 
            arrList: [...tempList, si.id],
            objList: sl
        });
        
    }

    const onBracketRemove = (sl, ri) => {
        let tempList = selectedBracket.arrList;
        let index = tempList.indexOf(ri.id);
        if( index > '-1' ) {
            tempList.splice(index, 1)
        } 
        setselectedBracket({ 
            arrList: [...tempList],
            objList: sl
        });
    }

    const handleCreateGroup = () => {
        setShowSpinner(true);
        seterrorMsg('');
        setsuccessMsg('');
        let tempInvite = [];
        const validationResult = InputValidation(userData);
        // console.log( 'validationResult-->',validationResult )
        if (Object.keys(validationResult).length > 0) {
            setErrors({ ...errors, ...validationResult });
        } else {
            const formData = new FormData();
            // formData.append("tournament_type", userData.selectType.value.trim());
            formData.append("bracket_id", selectedBracket.arrList.join(','));
            formData.append("group_id", props.group_id);
            let url = 'groups/member';
            if( props.edit ) {
                url = 'groups/member/update';
            }
            PostData(url, formData).then((response) => {
                setShowSpinner(true);
                if (response.status === true) {
                    setShowSpinner(false);
                    seterrorMsg('');
                    setsuccessMsg(props.edit ? 'Group Updated Successfuly' :'Group Joined Successfuly');
                    setTimeout(() => {
                        props.hide && props.hide(response.status);
                    }, 500)
                } else {
                    setShowSpinner(false);
                    setsuccessMsg('');
                    seterrorMsg(response.message);
                }
            });
        }
    }


    return (

        <Aux>
            <Modal
                show={props.show}
                centered
                onHide={() => { props.hide && props.hide(false) }}
                animation={false}
                className="modal-style-groups"
            >
                <Modal.Header closeButton className="header-style-modal">
                    <Button onClick={props.hide} className="btn closebtn">
                        <img src={loginclose} alt="deafult" />
                    </Button>
                    <Modal.Title>JOIN GROUP</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    {/* <Form.Group>
                <label className="form-label">Select Your Tournament type </label>
                <select
                className="form-control"
                value={userData.selectType.value}
                onClick={()=> setErrors({...errors, ...{eSelectType: false}})}
                onFocus={()=> setErrors({...errors, ...{eSelectType: false}})}
                onChange={(e)=>setUserData({
                    ...userData,
                    ...{
                        selectType: {
                            value: e.currentTarget.value,
                            validation: ['blank'],
                            errorMsg: selectTypeErrorMsg,
                            errorKey: 'eSelectType',
                            },
                        }
                    })
                }
                >
                    <option value="0">Select Tournament Type</option>
                    <option value="male">Men's</option>
                    <option value="female">Women's</option>
                </select>
                <div className="err-paragraph">
                    {errors.eSelectType}
                </div>
            </Form.Group> */}

                    <Form.Group>
                        <label className="form-label">Select Your Bracket (optional)</label>
                        {/* <select
                    className="form-control"
                    value={userData.selectBracket.value}
                    onClick={()=> setErrors({...errors, ...{eSelectBracket: false}})}
                    onFocus={()=> setErrors({...errors, ...{eSelectBracket: false}})}
                    onChange={(e)=>setUserData({
                        ...userData,
                        ...{
                            selectBracket: {
                                value: e.currentTarget.value,
                                validation: [],
                                errorMsg: selectBracketErrorMsg,
                                errorKey: 'eSelectBracket',
                                },
                            }
                        })
                    }
                >
                    <option value="">Select</option>
                    {
                        bracketData.map((d) =>
                           <option value={d.id}>{d.name}</option>
                        )
                    }
                </select> */}
                        <Multiselect
                            options={bracketData} // Options to display in the dropdown
                            selectedValues={selectedBracket.objList} // Preselected value to persist in dropdown
                            onSelect={onBracketSelect} // Function will trigger on select event
                            onRemove={onBracketRemove} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            selectionLimit={5}
                            placeholder="Select Bracket"
                        />
                        <div className="err-paragraph">
                            {errors.eSelectBracket}
                        </div>
                    </Form.Group>
                    <div className="error-msg-left">
                        {errorMsg}
                    </div>
                    <div className="success-msg-left">
                        {successMsg}
                    </div>
                    <div className="footer-btn">
                        <Button
                            onClick={handleCreateGroup}
                            variant="primary"
                            type="submit"
                        >
                            {showSpinner && (
                                <i className="fa fa-circle-o-notch fa-spin"></i>
                            )}
                            Join
                        </Button>
                        <Button className="cancel-btn"
                            onClick={() => { props.hide && props.hide(false) }}
                        >
                            CANCEL
                        </Button>
                    </div>

                </Modal.Body>

            </Modal>

        </Aux>
    )
}

