import React, { Component } from "react";
import { Form } from "react-bootstrap";
import calender from "../assets/socilicons/calendaricon.jpg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Datepicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {/* <div className="form-dob-icon">
          <DatePicker
            ref={(r) => {
              this.component = r;
            }}
            selected={this.props.selected}
            onChange={this.props.onChange}
            className="form-control form-dob"
            placeholder="Date of Birth"
            maxDate={new Date()}
            placeholderText="Select Date Of Birth"
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          <span onClick={() => this.toggle()} className="claendaricon">
            <img src={calender} alt="calendar" />
          </span>
        </div> */}
      </>
    );
  }
}

export default Datepicker;
