import React, { Component } from "react";
import { Col } from "react-bootstrap";

class Profile_hero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Col className="profilemd" md="12">
          <div className="Profile-banner">
            <div className="profile-hero">
              <img
                className="profile-hero-pc"
                src={this.props.img}
                alt="deafult"
              />
              <img
                className="profile-hero-mobile"
                src={this.props.imgmobile}
                alt="deafult"
              />
              <p>{this.props.text}</p>
            </div>
          </div>
        </Col>
      </>
    );
  }
}

export default Profile_hero;
