import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { withRouter } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import Placebigadd from "../components/Placeyouraddbigbox";
import TimerBanner from "../components/timer-banner";
import { PostData, GetData } from "../utils/apiRequestHandler";
import Loader from "../components/Loader";

const mensIcon = "https://d7le4ielk3ho1.cloudfront.net/src_images/men-icon.png";
const womensIcon = "https://d7le4ielk3ho1.cloudfront.net/src_images/women-icon.png";
const menD = "https://d7le4ielk3ho1.cloudfront.net/src_images/new/men-default1.png";
const rightImage = "https://d7le4ielk3ho1.cloudfront.net/src_images/invite-right-img.jpg";

class LeaderBoardMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      modalIsOpen: false,
      maleRanks: [],
      femaleRanks: [],
      ranks: [],
      rank: "N/A",
      currentTab: this.props.match.params.id,
      premierTotalScore: 0,
      survivorTotalScore: 0,
      challengeTotalScore: 0,
      championTotalScore: 0,
      type: "input",
      totalScore: 0,
      premierRoundWiseScore: [],
      survivorRoundWiseScore: [],
      challengeRoundWiseScore: [],
      championRoundWiseScore: [],
      totalRoundsScore: 0,
      bracketData: [],
      femaleBracket: [],
      maleBracket: [],
      selectedBracket: '',
      tournamentStart: true
    };
    this._maleBracketId = "";
    this._femaleBracketId = "";
    this.showHide = () => this.showHide.bind(this);
    this._currentPage = 0;
    this._stopScroll = false;
    this._currentTab = this.props.match.params.id;
  }

  componentDidMount() {
    document.title = "NCR Rugby | Leaderboard Main";
    let tournamenttime = JSON.parse(localStorage.getItem("tournamenttime"));
      if (tournamenttime) {
          this.setState({tournamentStart:tournamenttime.gameStarted})
      } 
    if (!localStorage.tp) {
      this.props.history.push("/mens-score");
    } else {
      this.getAllBracket();
    }
  }
  getAllBracket = () => {
    this.setState({ loader: true });
    GetData("manage-user-bracket/getAllBracket").then((response) => {
      console.log("Response", response);
      let femaleBracket = [], maleBracket = []
      if (response.status && response.data) {
        if (response.data && response.data.length) {
          response.data.map((d) => {
            if (d.type === 'male') {
              maleBracket.push(d)
            } else {
              femaleBracket.push(d)
            }
          })
        }
        // this.setState({ removeButton: true });
        this.setState({
          loader: false,
          bracketData: response.data,
          maleBracket: maleBracket,
          femaleBracket: femaleBracket
        }, () => {
          if (!this.props.subPage) {
            this.getUserBrackets(this.props.match.params.id);
            this.getScores();
          } else {
            this.handleTab(this.props.gender ? this.props.gender : "mens" );
            this.getUserBrackets("mens");
          }
        });
      } else {
        this.setState({
          bracketData: [],
          loader: false,
          maleBracket: maleBracket,
          femaleBracket: femaleBracket
        }, () => {
          if (!this.props.subPage) {
            this.getUserBrackets(this.props.match.params.id);
            this.getScores();
          } else {
            this.handleTab("mens")
            this.getUserBrackets("mens");
          }
        });
      }
    });
  }
  getUserBrackets = (t) => {
    GetData("leaderboard/getUserBrackets").then((response) => {
      if (response.status === true) {
        if (response.data.userBrackets[0]) {
          this._maleBracketId = response.data.userBrackets.find(
            (x) => x.gender === "male"
          )
            ? response.data.userBrackets.find((x) => x.gender === "male")
              .user_bracket_id
            : 0;
          this._femaleBracketId = response.data.userBrackets.find(
            (x) => x.gender === "female"
          )
            ? response.data.userBrackets.find((x) => x.gender === "female")
              .user_bracket_id
            : 0;
          this.getRoundWiseScore(t);
        }
      }
    });
  };

  getRoundWiseScore = (t, bracketId = false) => {
    let formData = new FormData();
    if (bracketId) {
      formData.append(
        "user_bracket_id",
        bracketId
      );
    } else {
      formData.append(
        "user_bracket_id",
        t === "mens" ? this._maleBracketId : this._femaleBracketId
      );
    }

    PostData("leaderboard/getRoundWiseScore", formData).then((response) => {
      if (response.status === true) {
        if (response.data.userRankData[0]) {
          this.setState({
            totalScore: response.data.userRankData[0].score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ,
            rank: response.data.userRankData[0].rank,
          });
        } else {
          this.setState({
            totalScore: "N/A",
            rank: "N/A",
          });
        }
        let tournamentKey = "Women_League";
        if (this.state.currentTab === "mens") {
          tournamentKey = "Men_League";
        }
        if (response.data.roundWiseScoreObject[tournamentKey]) {
          const totalPremior = response.data.roundWiseScoreObject[
            tournamentKey
          ].reduce(
            (prevValue, currentValue) =>
              prevValue + parseInt(currentValue.score),
            0
          );
          const totalSurvivor = response.data.roundWiseScoreObject[
            "Survivor_Cup"
          ].reduce(
            (prevValue, currentValue) =>
              prevValue + parseInt(currentValue.score),
            0
          );
          const totalChallenge = response.data.roundWiseScoreObject[
            "Challenge_Cup"
          ].reduce(
            (prevValue, currentValue) =>
              prevValue + parseInt(currentValue.score),
            0
          );
          const totalChampion = response.data.roundWiseScoreObject[
            "Champions_Cup"
          ].reduce(
            (prevValue, currentValue) =>
              prevValue + parseInt(currentValue.score),
            0
          );

          let totalRScore =
            totalPremior + totalSurvivor + totalChallenge + totalChampion;
          this.setState({
            prefix: response.data.image_prefix,
            premierTotalScore: totalPremior,
            survivorTotalScore: totalSurvivor,
            challengeTotalScore: totalChallenge,
            championTotalScore: totalChampion,
            totalRoundsScore: totalRScore,
            premierRoundWiseScore:
              response.data.roundWiseScoreObject[tournamentKey],
            survivorRoundWiseScore:
              response.data.roundWiseScoreObject["Survivor_Cup"],
            challengeRoundWiseScore:
              response.data.roundWiseScoreObject["Challenge_Cup"],
            championRoundWiseScore:
              response.data.roundWiseScoreObject["Champions_Cup"],
          });
        }
      }
    });
  };

  getScores = () => {
    this.setState({ loader: true });
    let start = this._currentPage * 20;
    let gender = "female";
    if (this._currentTab === "mens") {
      gender = "male";
    }
    GetData(
      "leaderboard/getTopRanks?gender=" + gender + "&start=" + start + "&end=20"
    ).then((response) => {
      this.setState({ loader: false });
      if (response.status === true) {
        let ranks = this.state.ranks;

        if (response.data.count.count < start + 20) {
          this._stopScroll = true;
        }

        response.data.allRank.map((ret) => {
          ranks.push(ret);
        });
        this.setState({
          ranks: ranks,
          prefix: response.data.image_prefix,
        });
      }
    });
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };
  changeBracket = (data) => {
    if (data.target.value) {
      this.getRoundWiseScore('', data.target.value);
    }
  }
  handleTab = (k) => {
    this.setState({ ranks: [] });
    this._stopScroll = false;
    this._currentPage = 0;
    if (k === "mens") {
      this._currentTab = "mens";
      this.getRoundWiseScore("mens");
    } else {
      this._currentTab = "womens";
      this.getRoundWiseScore("womens");
    }
    this.getScores();
    this.setState({ currentTab: k });
  };

  checkScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && this._stopScroll === false) {
      this._currentPage++;
      this.getScores();
    }
  };

  render() {

    return this.props.subPage ? (
      <Aux>
        <div className="invite-body-outer">
          <Row>
            <div className="col-12">
              <div className="leader-board-toplinks">
                <Row>
                  <div className="col-12">
                    {/* <ul>
                        <li>
                          <div
                            onClick={() => this.handleTab("mens")}
                            className={
                              this.state.currentTab === "mens"
                                ? "premier-links active"
                                : "premier-links"
                            }
                          >
                            <img
                              src={mensIcon}
                              className="premier-icon"
                              alt="deafult"
                            />{" "}
                            MEN’S
                          </div>
                          <div
                            onClick={() => this.handleTab("womens")}
                            className={
                              this.state.currentTab === "womens"
                                ? "premier-links active"
                                : "premier-links"
                            }
                          >
                            <img
                              src={womensIcon}
                              className="premier-icon"
                              alt="deafult"
                            />{" "}
                            WOMEN’S
                          </div>
                        </li>
                      </ul> */}
                    <div className="bracket-top-tab">
                      <span
                        onClick={() => this.handleTab("mens")}
                        className={
                          this.state.currentTab === "mens" ? "active" : ""
                        }
                      >
                        MEN'S
                      </span>
                      <span
                        onClick={() => this.handleTab("womens")}
                        className={
                          this.state.currentTab === "womens" ? "active" : ""
                        }
                      >
                        WOMEN'S
                      </span>
                    </div>
                  </div>
                </Row>
              </div>
              <div className="invite-body-inner">
                {/* Left container */}
                <div className="invite-inner-left subPage">
                  {/* Leader board table top */}
                  <div className="leaderboard-leftinner mb-5">
                    <div className="table-scroll">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Rank</th>
                            <th>Username</th>
                            <th>Champion</th>
                            <th>Score</th>
                          </tr>
                        </thead>
                        <tbody onScroll={(e) => this.checkScroll(e)}>
                          {this.state.ranks.length &&
                          this.state.tournamentStart ? (
                            this.state.ranks.map((data, key) => {
                              return (
                                <tr key={key}>
                                  <td>{data.rank}</td>
                                  <td>
                                    <div className="leader-profileimg leader-board-bktname">
                                      {/*<span className="imgbox">
                                              <img
                                                style={{ width: "40px" }}
                                                src={
                                                  data.image_path
                                                    ? this.state.prefix +
                                                      data.image_path
                                                    : menD
                                                }
                                                alt="deafult"
                                              />
                                              </span>*/}
                                      {data.bracket_name ? (
                                        <span className="bracket-name">
                                          {data.bracket_name}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {data.userName}
                                    </div>
                                  </td>
                                  <td>{data.winner_team}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.score
                                      ? data.score
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      : ""}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={3}
                                align="center"
                                style={{ textAlign: "center" }}
                              >
                                {this.state.tournamentStart
                                  ? "No Data Available"
                                  : "The tournament has not started yet"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="leaderboard-leftinner">
                    <div className="table-scroll-points">
                      <h1>
                        <span>My Points</span>
                        <span className="select-bkt">
                          <select
                            className="form-control"
                            onChange={this.changeBracket}
                          >
                            {this.state.currentTab === "mens" ? (
                              <>
                                {this.state.maleBracket.map((d) => (
                                  <option value={d.id}>{d.name}</option>
                                ))}
                              </>
                            ) : (
                              <>
                                {this.state.femaleBracket.map((d) => (
                                  <option value={d.id}>{d.name}</option>
                                ))}
                              </>
                            )}
                          </select>
                        </span>
                        <span>{this.state.totalRoundsScore}</span>
                      </h1>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Round</th>
                            <th>Points</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* First point table */}
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="red">PREMIER CUP</span>
                            </td>
                          </tr>
                          {this.state.premierRoundWiseScore.length ? (
                            this.state.premierRoundWiseScore.map(
                              (data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{data.title}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {data.score}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan={2}>Data Not Available</td>
                            </tr>
                          )}

                          <tr>
                            <td className="points-total">Total</td>
                            <td
                              className="points-total"
                              style={{ textAlign: "center" }}
                            >
                              {this.state.premierTotalScore}
                            </td>
                          </tr>
                          {/* Third point table */}
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="blue"> PLATE</span>
                            </td>
                          </tr>
                          {this.state.championRoundWiseScore.length ? (
                            this.state.championRoundWiseScore.map(
                              (data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{data.title}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {data.score}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan={2}>Data Not Available</td>
                            </tr>
                          )}
                          <tr>
                            <td className="points-total">Total</td>
                            <td
                              className="points-total"
                              style={{ textAlign: "center" }}
                            >
                              {this.state.championTotalScore}
                            </td>
                          </tr>
                          {/* second point table */}
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="green"> BOWL</span>
                            </td>
                          </tr>
                          {this.state.survivorRoundWiseScore.length ? (
                            this.state.survivorRoundWiseScore.map(
                              (data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{data.title}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {data.score}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan={2}>Data Not Available</td>
                            </tr>
                          )}
                          <tr>
                            <td className="points-total">Total</td>
                            <td
                              className="points-total"
                              style={{ textAlign: "center" }}
                            >
                              {this.state.survivorTotalScore}
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="blue"> SHIELD</span>
                            </td>
                          </tr>
                          {this.state.challengeRoundWiseScore.length ? (
                            this.state.challengeRoundWiseScore.map(
                              (data, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{data.title}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {data.score}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan={2}>Data Not Available</td>
                            </tr>
                          )}
                          <tr>
                            <td className="points-total">Total</td>
                            <td
                              className="points-total"
                              style={{ textAlign: "center" }}
                            >
                              {this.state.challengeTotalScore}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* right container */}
                <div className="invite-inner-right subPage">
                  <div className="Pointsbox">
                    <div className="points">
                      <p className="text">
                        Total{" "}
                        {this.state.currentTab === "womens"
                          ? "Women's"
                          : "Men's"}{" "}
                        Tournament Points
                      </p>
                      <p className="number">{this.state.totalScore}</p>
                    </div>
                    <div className="Pointsbox pointbox2">
                      <div className="points">
                        <p className="text">Rank</p>
                        <p
                          className={`number ${
                            this.state.rank < 0 ? "red" : "green"
                          }`}
                        >
                          {this.state.rank === -1 ? "N/A" : this.state.rank}
                        </p>
                      </div>
                    </div>
                    {/* <a
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(
                                this.state.currentTab === "womens"
                                  ? "/womens-bracket"
                                  : "/mens-bracket"
                              );
                            }}
                            href="#"
                          >
                            VIEW BRACKET
                          </a> */}
                  </div>
                  <div className="invite-right-top" style={{ display: "none" }}>
                    <img alt="deafult" src={rightImage} />
                  </div>
                  {/* <div className="invite-faq-right">
                          <Faqaccordian
                            heading1="How do I create an entry ?"
                            content1="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                            heading2="What is the deadline for entries ?"
                            content2="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                          />
                        </div> */}

                  <Placebigadd />
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Aux>
    ) : (
      <Aux>
        <Loader show={this.state.loader} />
        <Menu />
        <div className="Maincontent leader-board-wrap">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <TimerBanner />
            <div className="leader-board-toplinks">
              <Container>
                <Row>
                  <div className="col-12">
                    <ul>
                      <li>
                        <div
                          className="tab-links-leader"
                          onClick={() =>
                            this.props.history.push("/create-brackets")
                          }
                        >
                          BRACKET
                        </div>
                        <div className="tab-links-leader active">
                          LEADERBOARD
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => this.handleTab("mens")}
                          className={
                            this.state.currentTab === "mens"
                              ? "premier-links active"
                              : "premier-links"
                          }
                        >
                          <img
                            src={mensIcon}
                            className="premier-icon"
                            alt="deafult"
                          />{" "}
                          MEN’S
                        </div>
                        <div
                          onClick={() => this.handleTab("womens")}
                          className={
                            this.state.currentTab === "womens"
                              ? "premier-links active"
                              : "premier-links"
                          }
                        >
                          <img
                            src={womensIcon}
                            className="premier-icon"
                            alt="deafult"
                          />{" "}
                          WOMEN’S
                        </div>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="invite-body-outer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="invite-body-inner">
                      {/* Left container */}
                      <div className="invite-inner-left">
                        {/* Leader board table top */}
                        <div className="leaderboard-leftinner mb-5">
                          <div className="table-scroll">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Rank</th>
                                  <th>Username</th>
                                  <th>Champion</th>
                                  <th>Score</th>
                                </tr>
                              </thead>
                              <tbody onScroll={(e) => this.checkScroll(e)}>
                                {this.state.ranks.length &&
                                this.state.tournamentStart ? (
                                  this.state.ranks.map((data, key) => {
                                    return (
                                      <tr key={key}>
                                        <td>{data.rank}</td>
                                        <td>
                                          <div className="leader-profileimg leader-board-bktname">
                                            {/*<span className="imgbox">
                                              <img
                                                style={{ width: "40px" }}
                                                src={
                                                  data.image_path
                                                    ? this.state.prefix +
                                                      data.image_path
                                                    : menD
                                                }
                                                alt="deafult"
                                              />
                                              </span>*/}
                                            {data.bracket_name ? (
                                              <span className="bracket-name">
                                                {data.bracket_name}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            {data.userName}
                                          </div>
                                        </td>
                                        <td>{data.winner_team}</td>
                                        <td style={{ textAlign: "center" }}>
                                          {data.score}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      align="center"
                                      style={{ textAlign: "center" }}
                                    >
                                      {this.state.tournamentStart
                                        ? "No Data Available"
                                        : "The tournament has not started yet"}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="leaderboard-leftinner">
                          <div className="table-scroll-points">
                            <h1>
                              <span>My Points</span>
                              <span className="select-bkt">
                                <select
                                  className="form-control"
                                  onChange={this.changeBracket}
                                >
                                  {this.state.currentTab === "mens" ? (
                                    <>
                                      {this.state.maleBracket.map((d) => (
                                        <option value={d.id}>{d.name}</option>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      {this.state.femaleBracket.map((d) => (
                                        <option value={d.id}>{d.name}</option>
                                      ))}
                                    </>
                                  )}
                                </select>
                              </span>
                              <span>{this.state.totalRoundsScore}</span>
                            </h1>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Round</th>
                                  <th>Points</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* First point table */}
                                <tr>
                                  <td colSpan="2" className="greybg">
                                    <span className="red">PREMIER CUP</span>
                                  </td>
                                </tr>
                                {this.state.premierRoundWiseScore.length ? (
                                  this.state.premierRoundWiseScore.map(
                                    (data, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{data.title}</td>
                                          <td style={{ textAlign: "center" }}>
                                            {data.score}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={2}>Data Not Available</td>
                                  </tr>
                                )}

                                <tr>
                                  <td className="points-total">Total</td>
                                  <td
                                    className="points-total"
                                    style={{ textAlign: "center" }}
                                  >
                                    {this.state.premierTotalScore}
                                  </td>
                                </tr>

                                {/* Third point table */}
                                <tr>
                                  <td colSpan="2" className="greybg">
                                    <span className="blue">PLATE</span>
                                  </td>
                                </tr>
                                {this.state.championRoundWiseScore.length ? (
                                  this.state.championRoundWiseScore.map(
                                    (data, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{data.title}</td>
                                          <td style={{ textAlign: "center" }}>
                                            {data.score}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={2}>Data Not Available</td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="points-total">Total</td>
                                  <td
                                    className="points-total"
                                    style={{ textAlign: "center" }}
                                  >
                                    {this.state.championTotalScore}
                                  </td>
                                </tr>
                                {/* second point table */}
                                <tr>
                                  <td colSpan="2" className="greybg">
                                    <span className="green">BOWL</span>
                                  </td>
                                </tr>
                                {this.state.survivorRoundWiseScore.length ? (
                                  this.state.survivorRoundWiseScore.map(
                                    (data, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{data.title}</td>
                                          <td style={{ textAlign: "center" }}>
                                            {data.score}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={2}>Data Not Available</td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="points-total">Total</td>
                                  <td
                                    className="points-total"
                                    style={{ textAlign: "center" }}
                                  >
                                    {this.state.survivorTotalScore}
                                  </td>
                                </tr>

                                <tr>
                                  <td colSpan="2" className="greybg">
                                    <span className="blue">SHIELD</span>
                                  </td>
                                </tr>
                                {this.state.challengeRoundWiseScore.length ? (
                                  this.state.challengeRoundWiseScore.map(
                                    (data, key) => {
                                      return (
                                        <tr key={key}>
                                          <td>{data.title}</td>
                                          <td style={{ textAlign: "center" }}>
                                            {data.score}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={2}>Data Not Available</td>
                                  </tr>
                                )}
                                <tr>
                                  <td className="points-total">Total</td>
                                  <td
                                    className="points-total"
                                    style={{ textAlign: "center" }}
                                  >
                                    {this.state.challengeTotalScore}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* right container */}
                      <div className="invite-inner-right">
                        <div className="Pointsbox">
                          <div className="points">
                            <p className="text">
                              Total{" "}
                              {this.state.currentTab === "womens"
                                ? "Women's"
                                : "Men's"}{" "}
                              Tournament Points
                            </p>
                            <p className="number">{this.state.totalScore}</p>
                          </div>
                          <div className="Pointsbox pointbox2">
                            <div className="points">
                              <p className="text">Rank</p>
                              <p
                                className={`number ${
                                  this.state.rank < 0 ? "red" : "green"
                                }`}
                              >
                                {this.state.rank === -1
                                  ? "N/A"
                                  : this.state.rank}
                              </p>
                            </div>
                          </div>
                          {/* <a
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(
                                this.state.currentTab === "womens"
                                  ? "/womens-bracket"
                                  : "/mens-bracket"
                              );
                            }}
                            href="#"
                          >
                            VIEW BRACKET
                          </a> */}
                        </div>
                        <div
                          className="invite-right-top"
                          style={{ display: "none" }}
                        >
                          <img alt="deafult" src={rightImage} />
                        </div>
                        {/* <div className="invite-faq-right">
                          <Faqaccordian
                            heading1="How do I create an entry ?"
                            content1="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                            heading2="What is the deadline for entries ?"
                            content2="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                          />
                        </div> */}

                        <Placebigadd />
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <Placeaddsm />
          </div>
        </div>
      </Aux>
    );
  }
}
export default withRouter(LeaderBoardMain);
