import { faSlidersH } from "@fortawesome/fontawesome-free-solid";
import React, { Component } from "react";
import { Col } from "react-bootstrap";

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playvideo: false,
    };
  }

  render() {
    return (
      <>
        <Col md={this.props.md} className={this.props.class}>
          <div playyoutube={this.props.playyoutube} className="videosection">
            {this.props.playvideo && !this.state.playvideo ? (
              <iframe
                autoPlay="1"
                id={this.props.id}
                max-width="100%"
                width="100%"
                height="376px"
                src={this.props.src}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                playsInline="1"
                loop="1"
              ></iframe>
            ) : (
              <>
                <img src={this.props.imgsrc} alt="deafult" />
                <a type="button" onClick={this.props.onClick}></a>{" "}
              </>
            )}
          </div>
          <div className="video-text">
            <p onClick={this.props.onHide}>{this.props.title}</p>
          </div>
        </Col>
      </>
    );
  }
}

export default Video;
