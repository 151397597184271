import React, {useState, useEffect} from 'react';
import Aux from "../hoc/_Aux";
import { withRouter, Link } from "react-router-dom";
import TeamUserImg from "../assets/images/team-user-img.jpg";
import DeleteIcon from "../assets/images/delete-icon.svg";
import DeleteGroupMember from './groupModal/DeleteGroupMember';
 
function MemberListInfo (props){
  
 const [modalData, setModalData] = useState({
     show: false,
     group_id: '',
     deleteId : ''
 })

 const closeModal = (id) => {
     if( id ) {
         props.callBack && props.callBack(id);
     }
     setModalData({
        show: false,
        deleteId: '',
        member_id : ''
    })
 }

 const openModal = (d) => {
    setModalData({
        show: true,
        deleteId: d.group_id,
        member_id : d.user_id,
        hide: closeModal,
    })
 }
    
  return (
    <Aux>        
        <div className="table-member-list">
            {
                props.memberList.map((d,i) => (
                    <div className="table-member-box">
                        <div className="table-member-left">
                            <div className="user-team-box">
                                <div className="usr-team-img"><img src={TeamUserImg} alt="image" /></div>
                                <span className="list-names">{d.firstName ? d.firstName : ''} {d.lastName ? d.lastName : ''}</span> <span className="list-bkt-name"> {d.bracket_name ? `- ( ${d.bracket_name} )` : ''} </span>
                                {d.is_admin && d.is_admin > 0 ? <span className="admin-style">Admin</span> : ''}
                                
                            </div>
                        </div>
                        <div className={d.is_admin && d.is_admin > 0 ? 'admin table-member-right table-member-center': 'table-member-right table-member-center'}>
                            { d.bracketCompleted ? 'Complete' : 'Incomplete'}
                        </div>
                        {!d.is_admin ? 
                        <div className="table-member-right">
                            <img src={DeleteIcon} alt="Delete" onClick={()=> {openModal(d)}} className="dt-icon" />
                        </div> : '' }
                    </div>
                    )
                )
            }
        </div>
        <DeleteGroupMember
            {...modalData}
        />
    </Aux> 
  )
}
export default MemberListInfo;