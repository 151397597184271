import React from "react";
import { Toast } from "react-bootstrap";
import shareIcon from '../assets/images/shareIcon.svg'
import facebookIcon from '../assets/images/facebook.png'
import twitterkIcon from '../assets/images/twitter-x.png'
import copyIcon from '../assets/images/copy.png'
import mailIcon from '../assets/images/gmail.png'

class ShareButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showShareBox: false,
      showToast: false,
    };
    this.shareBoxRef = React.createRef();
  }

  toggleShareBox = () => {
    this.setState((prevState) => ({
      showShareBox: !prevState.showShareBox,
    }));
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.shareBoxRef.current &&
      !this.shareBoxRef.current.contains(event.target)
    ) {
      this.setState({ showShareBox: false });
    }
  };

  shareOnSocialMedia = (platform) => {
    const { url } = this.props;
    let shareUrl = "";
    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${url}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=Check%20this%20out&body=${url}`;
        break;
      case "copy":
        navigator.clipboard.writeText(url);
        this.setState({ showToast: true });
        //   alert('URL copied to clipboard');
        break;
      default:
        break;
    }
    if (platform !== "copy") {
      window.open(shareUrl, "_blank");
    }
    this.setState({ showShareBox: false });
  };

  render() {
    const { showShareBox } = this.state;

    return (
      <div className="wrapper-share" ref={this.shareBoxRef}>
        <button onClick={this.toggleShareBox}>
          <img src={shareIcon} alt="share" />
        </button>
        {showShareBox && (
          <div className= {`share-box ${this.props.type === 'v2' ? 'v2' : ''}`}>
            <button onClick={() => this.shareOnSocialMedia("facebook")}>
              <img src={facebookIcon} alt="facebook" />
            </button>
            <button onClick={() => this.shareOnSocialMedia("twitter")}>
              <img src={twitterkIcon} alt="twitter" />
            </button>
            <button onClick={() => this.shareOnSocialMedia("email")}>
              <img src={mailIcon} alt="email" />
            </button>
            <button onClick={() => this.shareOnSocialMedia("copy")}>
              <img src={copyIcon} alt="copy" />
            </button>
          </div>
        )}
        <Toast
          onClose={() => this.setState({ showToast: false })}
          show={this.state.showToast}
          bg="success"
          delay={1500}
          autohide
          style={{
            position: "fixed",
            top: "35%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Toast.Body className="bg-success text-white text-capitalize">
            Copied to clipboard!
          </Toast.Body>
        </Toast>
      </div>
    );
  }
}

export default ShareButton;
