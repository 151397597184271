import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import { PostData, GetData } from "../../utils/apiRequestHandler";
import { consts } from "../../utils/global";

const loginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function CreateBracketModal(props) {
  const [bracketName, setBracketName] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorBracketName, setErrorBracketName] = useState('');
  const [successBracketName, setsuccessBracketName] = useState('');
  const [bracketError, setBracketError] = useState(true);
  const { alphanumericRegex } = consts;
  let history = useHistory();
  const bracketType = props.type ? props.type : 'male';

  useEffect(() => {
    setErrorBracketName('');
    setsuccessBracketName('');
  }, [])

  const bracketSubmit = () => {
    setShowSpinner(true);
    setsuccessBracketName('');
    setErrorBracketName('');
    if (bracketName == null || bracketName == "") {
      setErrorBracketName('Please enter bracket name');
      setShowSpinner(false);
    } else if (!alphanumericRegex.test(bracketName)) {
      setErrorBracketName('Please enter valid bracket name');
      setShowSpinner(false);
    } else if (bracketError) {
      setErrorBracketName('Try something different, this bracket name already exists');
      setShowSpinner(false);
    } else {
      const formData = new FormData();
      formData.append("bracketType", bracketType);
      formData.append("bracketName", bracketName);
      // const id = formData;
      PostData("manage-user-bracket/create", formData).then((response) => {
        if (response.status === true) {
          setShowSpinner(false);
          let url = bracketType === 'male' ? `/mens-bracket/${response.data.bracket.name}/${response.data.bracket.id}` : `/womens-bracket/${response.data.bracket.name}/${response.data.bracket.id}`
          history.push(url);

        } else {
          setShowSpinner(false);
          setErrorBracketName(response.message);
        }
      });
    }
  }

  const checkBracketName = (bname) => {
    if( bname && bname.length > 2) {
      setShowSpinner(true);
      GetData(`manage-user-bracket/verifyBracket?name=${bname}`).then((response) => {
        if (response.status && response.data && !response.data.isbracketCreated) {
          setErrorBracketName('');
          bracketName!== "" ? setsuccessBracketName('Bracket name available') : setsuccessBracketName("");
          setBracketError(false);
        } else {
          setBracketError(true);
          setsuccessBracketName('');
          setErrorBracketName('Try something different, this bracket name already exists');
        }
        setShowSpinner(false);
      });
    } else {
      setsuccessBracketName('');
      setShowSpinner(false);
      setBracketError(true);
    }
  }

  return (
    <Aux>
      <Modal
        show={props.show}
        centered
        onHide={props.hide}
        animation={false}
        className="modal-style-groups"
      >
        <Modal.Header closeButton className="header-style-modal">
          <Button onClick={()=>{setBracketName(""); setsuccessBracketName(""); props.hide();}} className="btn closebtn">
            <img src={loginclose} alt="deafult" />
          </Button>
          <Modal.Title>SET BRACKET NAME</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-small">
          <Form.Group>
            <Form.Label>Bracket Name</Form.Label>
            <Form.Control
              defaultValue={bracketName}
              onChange={
                (e) => { setBracketName(e.target.value); 
                          setErrorBracketName("")
                          checkBracketName(e.target.value)

                        }

              }
              onBlur={
                (e) => {
                  checkBracketName(e.target.value); setErrorBracketName("");
                }
              }
              type="text"
              placeholder="Enter bracket name"
            />
            <div className="error-msg-left">
              {errorBracketName}
            </div>
            <div className="success-msg-left">
              {successBracketName}
            </div>
          </Form.Group>

          <div className="footer-btn">
            {
              !bracketError ? <Button
                onClick={bracketSubmit}
                variant="primary"
                type="submit"
              >
                {showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                PROCEED
              </Button> : <Button
                variant="primary"
                type="submit"
                className="disabled"
              >
                {showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                PROCEED
              </Button>
            }
            <Button className="cancel-btn"
              onClick={()=>{
                setBracketName('');
                setsuccessBracketName('');
                props.hide();}}
            >
              CANCEL
            </Button>
          </div>
        </Modal.Body>

      </Modal>
    </Aux>
  )
}