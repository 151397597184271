import React, { Component } from "react";
import { Button } from "react-bootstrap";

class Bluebtn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Button
          onClick={this.props.onClick}
          className="bluebtn"
          variant="primary"
          type={this.props.type}
          onKeyDown={this.props.onKeyDown}
          disable={this.props.disable}
        >
          {this.props.value}
          {this.props.spinner}
        </Button>
      </>
    );
  }
}

export default Bluebtn;
