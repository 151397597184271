import React, { Component } from "react";

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <div className="loaderContainer" style={this.props.show ? {display:'block'} : {display:'none'} }>
            <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
  }

}
export default Loader;