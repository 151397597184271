import React, { Component } from "react";

class BracketBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editState : false
        };
    }

    
    setWinner = (e,match,team,index) => {
        e.preventDefault()
        let teamData = match.team1
        let losingTeam = match.team2
        if(team === 'team2'){
            teamData = match.team2
            losingTeam = match.team1
        }
        this.props.handleClick(match,this.props.stateKey,teamData,losingTeam,index);
    }
    
    setScore = (e,match,team,key) => {
        this.props.setScore(e,match,team,key,this.props.stateKey)
    }

    getParsedTeamName = (teamName) => {
        let string = teamName.split(" ");
        let teamString = ""
        for(let k=0;k<string.length;k++){
            teamString+=string[k][0].toUpperCase()
        }
        return teamName
    }

      render() {
          return(
            this.props.data.length > 0 ?
                this.props.type === 2 ?
                    this.props.data.map((match,key)=>{
                        return(
                            match.round === this.props.round && this.props.position.includes(match.position) ?
                            <div id={'match-'+match.game_id} key={match.game_id} className={"matchup m_"+match.game_id}>
                                <div className="slots">
                                <div className="slots-inner">
                                    {
                                        match.team1.name ?
                                            <>
                                                <a href="#" tabIndex={-1} onClick={(e)=>this.props.edit === "true" ? e.preventDefault() : this.setWinner(e,match,'team1',key)} 
                                                className={
                                                    match.actual_winner_id ? 
                                                            match.actual_winner_id === match.team1.team_id && match.winner_id === match.team1.team_id ? 
                                                                's_green' 
                                                            : 's_pink' 
                                                    : 'grey'}>
                                                
                                                <span  className={(match.selected === match.team1.team_id || match.winner_id === match.team1.team_id) ? 'active teamTitle' : 'inactive teamTitle'}>
                                                {
                                                    match.actual_team_1_id ?
                                                        match.actual_team_1_id === match.team1.team_id ?
                                                        <span className={match.team1.name.length > 12 ? "tooltip-1" : "tooltip-1 disable-tooltip"}>
                                                            <span className={this.props.loserIds ? this.props.loserIds.includes(parseInt(match.team1.team_id)) ? "strikeThrough" : "" : ""}>{match.team1.name.length > 12 ? match.team1.division_teamid+' '+this.getParsedTeamName(match.team1.name) : match.team1.division_teamid+' '+match.team1.name}</span>
                                                            <span className="tooltiptext">{match.team1.name}</span>
                                                        </span>
                                                        :
                                                        <span className="tooltip-1">
                                                            <span className="losingTeam">
                                                                <span className="team-1">{match.team1.name.length > 12 ? match.team1.division_teamid+' '+this.getParsedTeamName(match.team1.name) : match.team1.division_teamid+' '+match.team1.name}</span>
                                                                <span className="team-2">{match.actual_team_1_name.length > 12 ? match.actual_team_1_division_teamid+' '+this.getParsedTeamName(match.actual_team_1_name) : match.actual_team_1_division_teamid+' '+match.actual_team_1_name}</span>
                                                            </span>
                                                            <span className="tooltiptext">
                                                                <span className="team-1">{match.team1.name}</span>
                                                                <span className="team-2">{match.actual_team_1_name}</span>
                                                            </span>
                                                        </span>
                                                    :
                                                    <span className={match.team1.name.length > 12 ? "tooltip-1" : "tooltip-1 disable-tooltip"}>
                                                        <span className={this.props.loserIds ? this.props.loserIds.includes(parseInt(match.team1.team_id)) ? "strikeThrough" : "" : ""}>{match.team1.name.length > 12 ? match.team1.division_teamid+' '+this.getParsedTeamName(match.team1.name) : match.team1.division_teamid+' '+match.team1.name}</span>
                                                        <span className="tooltiptext">{match.team1.name}</span>
                                                    </span>
                                                }
                                                </span>
                                                {
                                                    this.props.edit === "true" ?
                                                        <span className="scoreBox"><input type="text" onChange={(e)=>this.setScore(e,match,'team1',key)} value={match.team1_score || match.team1_score === 0 ? match.team1_score : ''}/></span>    
                                                    :
                                                        <span>{match.team1_score}</span>    
                                                }
                                                </a>
                                            </>
                                        :
                                        ""
                                    }
                                </div>
                                <div className="slots-inner">
                                    {
                                        match.team2.name ?
                                        <>
                                            <a href="#" tabIndex={-1} onClick={(e)=>this.props.edit === "true" ? e.preventDefault() : this.setWinner(e,match,'team2',key)} className={
                                                                match.actual_winner_id ? 
                                                                    match.actual_winner_id === match.team2.team_id && match.winner_id === match.team2.team_id ? 
                                                                        's_green' 
                                                                    : 's_pink' 
                                                                : ''}>
                                                <span  className={(match.selected === match.team2.team_id || match.winner_id === match.team2.team_id) ? 'active teamTitle' : 'inactive teamTitle'}>
                                                {
                                                    match.actual_team_2_id ?
                                                        match.actual_team_2_id === match.team2.team_id ?
                                                        <span className={match.team2.name.length > 12 ? "tooltip-1" : "tooltip-1 disable-tooltip"}>
                                                            <span className={this.props.loserIds ? this.props.loserIds.includes(parseInt(match.team2.team_id)) ? "strikeThrough" : "" : ""}>
                                                                {match.team2.name.length > 12 ? match.team2.division_teamid+' '+this.getParsedTeamName(match.team2.name) :  match.team2.division_teamid+' '+match.team2.name}
                                                            </span>
                                                            <span className="tooltiptext">{match.team2.name}</span>
                                                        </span>
                                                        :
                                                        <span className="tooltip-1">
                                                            <span className="losingTeam">
                                                                <span className="team-1">{match.team2.name.length > 12 ? match.team2.division_teamid+' '+this.getParsedTeamName(match.team2.name) : match.team2.division_teamid+' '+match.team2.name}</span>
                                                                <span className="team-2">{match.actual_team_2_name.length > 12 ? match.actual_team_2_division_teamid+' '+this.getParsedTeamName(match.actual_team_2_name) : match.actual_team_2_division_teamid+' '+match.actual_team_2_name}</span>
                                                            </span>
                                                            <span className="tooltiptext">
                                                                <span className="team-1">{match.team2.name}</span>
                                                                <span className="team-2">{match.actual_team_2_name}</span>
                                                            </span>
                                                        </span>
                                                    :
                                                    <span className={match.team2.name.length > 12 ? "tooltip-1" : "tooltip-1 disable-tooltip"}>
                                                        <span className={this.props.loserIds ? this.props.loserIds.includes(parseInt(match.team2.team_id)) ? "strikeThrough" : "" : ""}>
                                                            {match.team2.name.length > 12 ?  match.team2.division_teamid+' '+this.getParsedTeamName(match.team2.name) :  match.team2.division_teamid+' '+match.team2.name}
                                                        </span>
                                                        <span className="tooltiptext">{match.team2.name}</span>
                                                    </span>
                                                }
                                                </span>
                                                {
                                                        this.props.edit === "true" ?
                                                        <span className="scoreBox"><input type="text" onChange={(e)=>this.setScore(e,match,'team2',key)} value={match.team2_score || match.team2_score === 0 ? match.team2_score : ''}/></span>    
                                                        :
                                                            <span>{match.team2_score}</span>    
                                                }
                                            </a>
                                        </>
                                        :
                                        ""
                                    }
                                </div>
                                </div>
                            </div>
                            : 
                            ""
                        )
                    })  
                :
                    this.props.data.map((match,key)=>{
                            return(
                                match.round === this.props.round && this.props.position.includes(match.position) ?
                                    <div id={'match-'+match.game_id} key={match.game_id} className={"matchup m_"+match.game_id}>
                                        <div className="slots">
                                            <div className="slots-inner">
                                                {
                                                    match.team1.name ?
                                                        <>
                                                            <div className="bluebox">{match.team1.division_teamid ? match.team1.division_teamid : '0'}</div>
                                                            <a tabIndex={-1} href="#/" onClick={(e)=>this.props.edit === "true" ? e.preventDefault() : this.setWinner(e,match,'team1',key)} className={
                                                                                match.actual_winner_id ? 
                                                                                    match.actual_winner_id === match.team1.team_id && match.winner_id === match.team1.team_id ? 's_green' : 's_pink' 
                                                                                : 'grey'}>
                                                                <span  className={(match.selected === match.team1.team_id || match.winner_id === match.team1.team_id) ? 'active teamTitle' : 'inactive teamTitle'}>
                                                                    {
                                                                        match.actual_team_1_id ?
                                                                            match.actual_team_1_id === match.team1.team_id ?
                                                                            <span className={match.team1.name.length > 12 ? "tooltip-1" : "tooltip-1 disable-tooltip"}>{match.team1.name.length > 12 ? this.getParsedTeamName(match.team1.name) : match.team1.name}<span className="tooltiptext">{match.team1.name}</span></span>
                                                                            :
                                                                            <span className="tooltip-1">
                                                                                <span className="losingTeam">
                                                                                    <span className="team-1">{match.team1.name.length > 12 ? this.getParsedTeamName(match.team1.name) : match.team1.name}</span>
                                                                                    <span className="team-2">{match.actual_team_1_name > 12 ? this.getParsedTeamName(match.actual_team_1_name) : match.actual_team_1_name}</span>
                                                                                </span>
                                                                                <span className="tooltiptext">
                                                                                    <span className="team-1">{match.team1.name}</span>
                                                                                    <span className="team-2">{match.actual_team_1_name}</span>
                                                                                </span>
                                                                            </span>
                                                                        :
                                                                        <span className={match.team1.name.length > 12 ? "tooltip-1" : "tooltip-1 disable-tooltip"}>{match.team1.name.length > 12 ? this.getParsedTeamName(match.team1.name) : match.team1.name}<span className="tooltiptext">{match.team1.name}</span></span>
                                                                    }
                                                                </span>
                                                                {
                                                                    this.props.edit === "true" ?
                                                                        <span className="scoreBox"><input  type="text" onChange={(e)=>this.setScore(e,match,'team1',key)} value={match.team1_score || match.team1_score === 0 ? match.team1_score : ''}/></span>    
                                                                    :
                                                                        <span>{match.team1_score}</span>    
                                                                }
                                                                
                                                            </a>
                                                        </>
                                                    :
                                                    ""
                                                }
                                            </div>
                                            <div className="slots-inner">
                                                {
                                                    match.team2.name ?
                                                    <>
                                                        <div className="bluebox">{match.team2.division_teamid ? match.team2.division_teamid : '0'}</div>
                                                        <a tabIndex={-1} href="#/" onClick={(e)=>this.props.edit === "true" ? e.preventDefault() : this.setWinner(e,match,'team2',key)} className={
                                                                    match.actual_winner_id ? 
                                                                        match.actual_winner_id === match.team2.team_id && match.winner_id === match.team2.team_id ? 
                                                                            's_green' 
                                                                        : 's_pink' 
                                                                    : ''}>
                                                            <span  className={(match.selected === match.team2.team_id || match.winner_id === match.team2.team_id) ? 'active teamTitle' : 'inactive teamTitle'}>
                                                                {
                                                                    match.actual_team_2_id ?
                                                                        match.actual_team_2_id === match.team2.team_id ?
                                                                        <span className={match.team2.name.length > 12 ? "tooltip-1" : "tooltip-1 disable-tooltip"}>{match.team2.name.length > 12 ? this.getParsedTeamName(match.team2.name) : match.team2.name}<span className="tooltiptext">{match.team2.name}</span></span>
                                                                        :
                                                                        <span className="tooltip-1">
                                                                            <span className="losingTeam">
                                                                                <span className="team-1">{match.team2.name.length > 12 ? this.getParsedTeamName(match.team2.name) : match.team2.name}</span>
                                                                                <span className="team-2">{match.actual_team_2_name > 12 ? this.getParsedTeamName(match.actual_team_2_name) : match.actual_team_2_name}</span>
                                                                            </span>
                                                                            <span className="tooltiptext">
                                                                                <span className="team-1">{match.team2.name}</span>
                                                                                <span className="team-2">{match.actual_team_2_name}</span>
                                                                            </span>
                                                                        </span>
                                                                    :
                                                                    <span className="tooltip-1">{match.team2.name.length > 12 ? this.getParsedTeamName(match.team2.name) : match.team2.name}<span className="tooltiptext">{match.team2.name}</span></span>
                                                                }
                                                            </span>
                                                            {
                                                                    this.props.edit === "true" ?
                                                                    <span className="scoreBox"><input  type="text" onChange={(e)=>this.setScore(e,match,'team2',key)} value={match.team2_score || match.team2_score === 0 ? match.team2_score : ''}/></span>    
                                                                    :
                                                                        <span>{match.team2_score}</span>    
                                                            }
                                                        </a>
                                                    </>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                : 
                                ""
                            )
                        })  
                    :
                <>
                    <div className="matchup m_19">
                        <div className="slots">
                        <div className="slots-inner"></div>
                        <div className="slots-inner"></div>
                        </div>
                    </div>
                    <div className="matchup m_20">
                        <div className="slots">
                        <div className="slots-inner"></div>
                        <div className="slots-inner"></div>
                        </div>
                    </div>
                </>
                )
        }
}

export default BracketBox;