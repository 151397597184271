import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import channel1 from '../assets/images/TRN.jpg'
import channel2 from '../assets/images/cst.jpg'
import channel3 from '../assets/images/USA-Sevens.jpg'

class WhereToWatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: "input",
    };
    this.showHide = () => this.showHide.bind(this);
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };

  componentDidMount() {
    document.title = "NCR Rugby | Where to watch";
  }

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent invite-friend-wrap howto-play-wrap">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <div className="invite-body-outer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="invite-body-inner">
                      {/* Left container */}
                      <div className="watchBanners">
                        <a href="https://www.therugbynetwork.com/" target="_blank" rel="noreferrer">
                            <img src={channel1} alt="channel 1" />
                            <span>The Rugby Network</span>
                        </a>
                        <a href="https://coxsportstv.com/" target="_blank" rel="noreferrer">
                            <img src={channel2} alt="channel 2" />
                            <span>Cox Sports TV</span>
                        </a>
                        <a href="https://www.facebook.com/USASevens" target="_blank" rel="noreferrer">
                            <img src={channel3} alt="channel 3" />
                            <span>USA Sevens Facebook</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <Placeaddsm />
          </div>
        </div>
      </Aux>
    );
  }
}
export default WhereToWatch;
