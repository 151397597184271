import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { withRouter } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import Placebigadd from "../components/Placeyouraddbigbox";
import { PostData, GetData } from "../utils/apiRequestHandler";
import Loader from "../components/Loader";

const mensIcon = "https://d7le4ielk3ho1.cloudfront.net/src_images/men-icon.png";
const womensIcon = "https://d7le4ielk3ho1.cloudfront.net/src_images/women-icon.png";
const menD = "https://d7le4ielk3ho1.cloudfront.net/src_images/new/men-default1.png";
const rightImage = "https://d7le4ielk3ho1.cloudfront.net/src_images/invite-right-img.jpg";

class LeaderBoardRoundWiseScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            modalIsOpen: false,
            maleRanks: [],
            femaleRanks: [],
            ranks: [],
            rank: "N/A",
            premierTotalScore: 0,
            survivorTotalScore: 0,
            challengeTotalScore: 0,
            championTotalScore: 0,
            type: "input",
            totalScore: 0,
            premierRoundWiseScore: [],
            survivorRoundWiseScore: [],
            challengeRoundWiseScore: [],
            championRoundWiseScore: [],
            totalRoundsScore: 0,
            bracketData: [],
            maleBracket: [],
            femaleBracket: [],
            currentBracketId: ''
        };
        this._maleBracketId = "";
        this._femaleBracketId = "";
        this.showHide = () => this.showHide.bind(this);
        this._currentPage = 0;
        this._stopScroll = false;
    }

    componentDidMount() {
        this.getAllBracket();


    }

    getAllBracket = () => {
        this.setState({ loader: true });
        GetData("manage-user-bracket/getAllBracket").then((response) => {
            console.log("Response", response);
            let femaleBracket = [], maleBracket = [], bracketId = '', joinedBracketIds = [];
            if (response.status && response.data) {
                GetData(`groups/member/details?id=${this.props.groupData.id}`).then((res) => {
                    if (res.data && res.data && res.data.rows) {
                        res.data.rows.map((d) => {
                            joinedBracketIds.push(d.user_bracket_id);
                        })

                        if( joinedBracketIds.length ) {
                            response.data.map((d) => {
                                if (d.type === 'male') {
                                    if( joinedBracketIds.indexOf(d.id) > '-1') {
                                        maleBracket.push(d)
                                    }
                                    if (!bracketId && this.props.groupData.tournament_type === 'male' && joinedBracketIds.indexOf(d.id) > '-1') {
                                        bracketId = d.id;
                                    }
                                } else {
                                    if( joinedBracketIds.indexOf(d.id) > '-1') {
                                        femaleBracket.push(d)
                                    }
                                    if (!bracketId && this.props.groupData.tournament_type === 'female' && joinedBracketIds.indexOf(d.id) > '-1') {
                                        bracketId = d.id;
                                    }
                                }
                            })
                            this.setState({
                                loader: false,
                                bracketData: response.data,
                                maleBracket: maleBracket,
                                femaleBracket: femaleBracket,
                                currentBracketId: bracketId
                            }, () => {
                                this.getRoundWiseScore();
                            });
                        } else {
                            this.setState({
                                bracketData: [],
                                loader: false,
                                maleBracket: maleBracket,
                                femaleBracket: femaleBracket,
                                currentBracketId: bracketId
                            }, () => {
                                this.getRoundWiseScore();
                            });
                        }
                    } else {
                        this.setState({
                            bracketData: [],
                            loader: false,
                            maleBracket: maleBracket,
                            femaleBracket: femaleBracket,
                            currentBracketId: bracketId
                        }, () => {
                            this.getRoundWiseScore();
                        });
                    }

                })
                // this.setState({ removeButton: true });
                
            } else {
                this.setState({
                    bracketData: [],
                    loader: false,
                    maleBracket: maleBracket,
                    femaleBracket: femaleBracket,
                    currentBracketId: bracketId
                }, () => {
                    this.getRoundWiseScore();
                });
            }
        });
    }
    changeBracket = (data) => {
        if (data.target.value) {
            this.setState({
                ...this.state,
                currentBracketId: data.target.value
            }, () => {
                this.getRoundWiseScore();
            })

        }
    }
    getRoundWiseScore = () => {
        if (!this.state.currentBracketId) {
            return false;
        }
        let formData = new FormData();
        formData.append(
            "user_bracket_id", this.state.currentBracketId
        );
        formData.append(
            "user_id", this.props.groupData.user_id ? this.props.groupData.user_id : ''
        );
        formData.append(
            "group_id", this.props.groupData.id
        );
        PostData("leaderboard/getRoundWiseScore", formData).then((response) => {
            // response = {"code":200,"status":true,"message":"Round Wise Score fetched successfully","data":{"roundWiseScoreObject":{"Men_League":[{"score":"195","title":"1st ROUND"},{"score":0,"title":"2nd ROUND"},{"score":0,"title":"QUARTERFINALS"},{"score":0,"title":"SEMI FINALS"},{"score":0,"title":"National Championship"},{"score":0,"title":"3rd Place"}],"Survivor_Cup":[{"score":"150","title":"2nd ROUND"},{"score":"120","title":"QUARTERFINALS"},{"score":0,"title":"SEMI FINALS"},{"score":0,"title":"National Championship"},{"score":0,"title":"3rd Place"}],"Champions_Cup":[{"score":0,"title":"QUARTERFINALS"},{"score":0,"title":"SEMI FINALS"},{"score":0,"title":"National Championship"},{"score":0,"title":"3rd Place"}],"Challenge_Cup":[{"score":"240","title":"QUARTERFINALS"},{"score":0,"title":"SEMI FINALS"},{"score":0,"title":"National Championship"},{"score":0,"title":"3rd Place"}]},"userRankData":[{"id":618,"userId":14,"userName":"arora","bracketType":"male","score":705,"rank":3,"createdAt":"2022-03-25T15:06:15.000Z","updatedAt":"2022-03-25T15:06:15.000Z","winner_id":106,"user_bracket_id":106}],"image_prefix":"https://ncrstaging.s3.us-west-2.amazonaws.com/"}};
            if (response.status === true) {
                if (response.data.userRankData[0]) {
                    this.setState({
                        totalScore: response.data.userRankData[0].score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        rank: response.data.userRankData[0].rank,
                    });
                } else {
                    this.setState({
                        totalScore: "N/A",
                        rank: "N/A",
                    });
                }
                let tournamentKey = "Women_League";
                if (this.props.groupData.tournament_type === "male") {
                    tournamentKey = "Men_League";
                }
                if (response.data.roundWiseScoreObject[tournamentKey]) {
                    const totalPremior = response.data.roundWiseScoreObject[
                        tournamentKey
                    ].reduce(
                        (prevValue, currentValue) =>
                            prevValue + parseInt(currentValue.score),
                        0
                    );
                    const totalSurvivor = response.data.roundWiseScoreObject[
                        "Survivor_Cup"
                    ].reduce(
                        (prevValue, currentValue) =>
                            prevValue + parseInt(currentValue.score),
                        0
                    );
                    const totalChallenge = response.data.roundWiseScoreObject[
                        "Challenge_Cup"
                    ].reduce(
                        (prevValue, currentValue) =>
                            prevValue + parseInt(currentValue.score),
                        0
                    );
                    const totalChampion = response.data.roundWiseScoreObject[
                        "Champions_Cup"
                    ].reduce(
                        (prevValue, currentValue) =>
                            prevValue + parseInt(currentValue.score),
                        0
                    );

                    let totalRScore =
                        totalPremior + totalSurvivor + totalChallenge + totalChampion;

                    this.setState({
                        prefix: response.data.image_prefix,
                        premierTotalScore: totalPremior,
                        survivorTotalScore: totalSurvivor,
                        challengeTotalScore: totalChallenge,
                        championTotalScore: totalChampion,
                        totalRoundsScore: totalRScore,
                        premierRoundWiseScore:
                            response.data.roundWiseScoreObject[tournamentKey],
                        survivorRoundWiseScore:
                            response.data.roundWiseScoreObject["Survivor_Cup"],
                        challengeRoundWiseScore:
                            response.data.roundWiseScoreObject["Challenge_Cup"],
                        championRoundWiseScore:
                            response.data.roundWiseScoreObject["Champions_Cup"],
                    });
                }
            }
        });
    };


    render() {
        return (
            <Aux>
                <Loader show={this.state.loader} />
                <div className="leaderboard-leftinner">
                    <div className="table-scroll-points">
                        <h1>
                            <span>My Points</span>
                            <span className="select-bkt">
                                <select className="form-control" onChange={this.changeBracket}>
                                    {
                                        this.props.groupData.tournament_type === 'male' ? <>
                                            {
                                                this.state.maleBracket.map((d) =>
                                                    <option value={d.id}>{d.name}</option>

                                                )
                                            }
                                        </> : <>
                                            {
                                                this.state.femaleBracket.map((d) =>
                                                    <option value={d.id}>{d.name}</option>

                                                )
                                            }
                                        </>
                                    }
                                </select>
                            </span>
                            <span>{this.state.totalRoundsScore}</span>
                        </h1>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Round</th>
                                    <th>Points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* First point table */}
                                <tr>
                                    <td colSpan="2" className="greybg">
                                        <span className="red">
                                            PREMIER CUP
                                        </span>
                                    </td>
                                </tr>
                                {this.state.premierRoundWiseScore.length ? (
                                    this.state.premierRoundWiseScore.map(
                                        (data, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{data.title}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {data.score}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={2}>Data Not Available</td>
                                    </tr>
                                )}

                                <tr>
                                    <td className="points-total">Total</td>
                                    <td
                                        className="points-total"
                                        style={{ textAlign: "center" }}
                                    >
                                        {this.state.premierTotalScore}
                                    </td>
                                </tr>

                                {/* second point table */}
                                <tr>
                                    <td colSpan="2" className="greybg">
                                        <span className="green">
                                            SURVIVORS BOWL
                                        </span>
                                    </td>
                                </tr>
                                {this.state.survivorRoundWiseScore.length ? (
                                    this.state.survivorRoundWiseScore.map(
                                        (data, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{data.title}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {data.score}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={2}>Data Not Available</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="points-total">Total</td>
                                    <td
                                        className="points-total"
                                        style={{ textAlign: "center" }}
                                    >
                                        {this.state.survivorTotalScore}
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2" className="greybg">
                                        <span className="blue">
                                            CHALLENGE  SHIELD
                                        </span>
                                    </td>
                                </tr>
                                {this.state.challengeRoundWiseScore.length ? (
                                    this.state.challengeRoundWiseScore.map(
                                        (data, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{data.title}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {data.score}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={2}>Data Not Available</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="points-total">Total</td>
                                    <td
                                        className="points-total"
                                        style={{ textAlign: "center" }}
                                    >
                                        {this.state.challengeTotalScore}
                                    </td>
                                </tr>

                                {/* Third point table */}
                                <tr>
                                    <td colSpan="2" className="greybg">
                                        <span className="blue">
                                            CONTENDER PLATE
                                        </span>
                                    </td>
                                </tr>
                                {this.state.championRoundWiseScore.length ? (
                                    this.state.championRoundWiseScore.map(
                                        (data, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{data.title}</td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {data.score}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={2}>Data Not Available</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="points-total">Total</td>
                                    <td
                                        className="points-total"
                                        style={{ textAlign: "center" }}
                                    >
                                        {this.state.championTotalScore}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Aux>
        );
    }
}
export default LeaderBoardRoundWiseScore;
