import React from 'react';

const InputValidaton = Props => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const numberRegex = /^[0-9]+$/;
  const nameRegex = /^[a-zA-Z ]*$/;
  const descriptionRegex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;
  const alphanumericRegex = /^[^'][a-zA-Z0-9' ]*[^']$/;
  const passwordRegex = /^(?=.*\d)(?=.*[a-zA-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  const weblinkRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const cardRegex = /^\(?([0-9]{4})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})[-. ]?([0-9]{4,5})$/;

  let errorList = {};
  const checkValidataion = (key, value, validation, errorMsg, errorKey) => {

    if (validation && validation.length) {
      var vc;
      for (vc = 0; vc < validation.length; vc++) {
        if (typeof validation[vc] === 'string') {
          let validateResult = validateValue(value, validation[vc]);
          if (validateResult) {
            errorList = { ...errorList, ...{ [errorKey]: errorMsg[vc] } };
            break;
          }
        } else {
          for (const key in validation[vc]) {

            let validateResult = validateValue(
              value,
              key,
              validation[vc][key],
            );
            if (validateResult) {
              errorList = { ...errorList, ...{ [errorKey]: errorMsg[vc] } };
              break;
            }
          }
        }
      }
    }

  };

  // eslint-disable-next-line no-undef
  const validateValue = (value, type, obj = {}) => {
    switch (type) {
      case 'blank':
        if (value == '' || value === undefined) {
          return true;
        } else {
          return false;
        }
      case 'expiry':
        if (value == '' || value === undefined) {
          return true;
        } else {
          let arr = value.split('/');
          if (arr.length == 2) {
            let month = parseInt(arr[0]);
            let year = parseInt(arr[1]);
            let currentyear = new Date().getFullYear();
            let currentmonth = new Date().getMonth() + 1;
            if (month && year && month > 0 && month < 13 && year >= currentyear) {
              if (currentyear == year && month < currentmonth) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
            console.log(month, year);
          } else {
            return true;
          }
          console.log('arr-expiry', arr);
          return false;
        }
      case 'email':
        if (value !== '' && value !== undefined && !emailRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      case 'weblink':
        if (value !== '' && value !== undefined && !weblinkRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      case 'name':
        if (!nameRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      case 'description':
        if (value !== '' && value !== undefined && !descriptionRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      case 'alphanumeric':
        if (value !== '' && value !== undefined && !alphanumericRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      case 'emailorphone':
        if (!emailRegex.test(value) && (!numberRegex.test(value) || value.length != '10')) {
          return true;
        } else {
          return false;
        }
      case 'number':
        if (!numberRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      case 'indianmobile':
        if (!phoneRegex.test(value) && value != '') {
          return true;
        } else {
          return false;
        }
      case 'cvv':
        if (!numberRegex.test(value) || value.length != '3') {
          return true;
        } else {
          return false;
        }
      case 'card':
        if (!cardRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      case 'password':
        if (value !== '' && value !== undefined && !passwordRegex.test(value)) {
          return true;
        } else {
          return false;
        }
      case 'arrayempty':
        if (!value.length) {
          return true;
        } else {
          return false;
        }
      case 'objectempty':
        if (Object.keys(value).length <= 0) {
          return true;
        } else {
          return false;
        }
      case 'image':


        console.log('image console ', value, '--->', obj.old_image);
        if (value && value.name !== null && value.name !== undefined) {
          alert('aa');
          console.log('image console ', value.size);
          try {
            if (obj.img_type !== undefined && typeof obj.img_type == 'object' && obj.img_type.length) {
              let ext;
              ext = value.name.split('.');
              ext = ext[ext.length - 1].toLowerCase();
              if (obj.img_type.indexOf(ext) == '-1') {
                return true;
              }
            }
            if (obj.img_min_size !== undefined && obj.img_min_size > value.size) {
              return true;
            }
            if (obj.img_max_size !== undefined && obj.img_max_size < value.size) {
              return true;
            }
            return false;

          } catch (e) {
            console.log('image upload error', e);
            return true;
          }

        } else if (obj.old_image !== undefined && obj.old_image != '' && obj.old_image != null) {
          alert('bb');
          return false;
        } else {
          alert('cc');
          return true;
        }
      case 'confirmPassword':
        if (obj.password != value) {
          return true;
        } else {
          return false;
        }
      case 'confirmEmail':
        if (obj.email != value) {
          return true;
        } else {
          return false;
        }
      case 'compareDate':
        if (obj.startDate > value) {
          return true;
        } else {
          return false;
        }
    }
  };
  let validationResult = {};
  for (const key in Props) {

    if (typeof Props[key].value === 'string') {
      Props[key].value = Props[key].value.trim();
    }
    Props[key].validation &&
      Props[key].validation.length &&
      checkValidataion(
        key,
        Props[key].value,
        Props[key].validation,
        Props[key].errorMsg,
        Props[key].errorKey,
      );
  }
  return errorList;



};
export default InputValidaton;