import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import InputValidation from "../../utils/validationNew";
import { GetData, PostData, PutData } from "../../utils/apiRequestHandler";
import {consts} from "../../utils/global";

const Openeye = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const Closeye = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";
const loginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function SendEmailModal(props) {

  // console.log('props on send email/invite', props);
  const [showSpinner, setShowSpinner] = useState(false);
  // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const {emailRegex} = consts; // -- 
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState('');
  const [errorMsg, seterrorMsg] = useState('');
  const [successMsg, setsuccessMsg] = useState('');

  const [errors, setErrors] = useState({
    eGroupMessage: false,
    einviteFriends: false
  });
  const groupMessageErrorMsg = ['Enter message'];

  const [userData, setUserData] = useState({
    groupMessage: {
      value: '',
      validation: ['blank'],
      errorMsg: groupMessageErrorMsg,
      errorKey: 'eGroupMessage',
    }
  });

  useEffect(() => {
    setShowSpinner(false);
    seterrorMsg('');
    setsuccessMsg('');
    setTags([]);
    setTag('');
    setUserData({
      groupMessage: {
        value: '',
        validation: [],
        errorMsg: groupMessageErrorMsg,
        errorKey: 'eGroupMessage',
      }
    })
    setErrors({
      eGroupMessage: false,
      einviteFriends: false
    })
    return (() => {
      setShowSpinner(false);
      seterrorMsg('');
      setsuccessMsg('');
      setTags([]);
      setTag('');
      setUserData({
        groupMessage: {
          value: '',
          validation: ['blank'],
          errorMsg: groupMessageErrorMsg,
          errorKey: 'eGroupMessage',
        }
      })
      setErrors({
        eGroupMessage: false,
        einviteFriends: false
      })
    })
  }, [props])

  const handleCreateGroup = () => {
    setShowSpinner(true);
    seterrorMsg('');
    setsuccessMsg('');
    let tempInvite = [];
    const validationResult = InputValidation(userData);
    // console.log( 'validationResult-->',validationResult )
    if (Object.keys(validationResult).length > 0) {
      setErrors({ ...errors, ...validationResult });
    } else {
      if (!tags || !tags.length) {
        setShowSpinner(false);
        setErrors({ ...errors, einviteFriends: 'Please enter emails' });
        return false;
      }
      tempInvite = tags.filter((d) => {
        if (!emailRegex.test(d)) {
          return true;
        }
      })
      if (tempInvite.length) {
        setShowSpinner(false);
        setErrors({ ...errors, einviteFriends: 'Please enter valid emails' });
        return false;
      }
      const formData = new FormData();
      formData.append("message", userData.groupMessage.value.trim());
      formData.append("group_id", props.groupData.id);
      formData.append("emails", tags.join(','));
      if (props.modalType && props.modalType === 'send') {
        PostData("groups/sendmail", formData).then((response) => {
          if (response.status === true) {
            setShowSpinner(false);
            seterrorMsg('');
            setsuccessMsg(response.message);
            setTimeout(() => {
              props.hide && props.hide(true);
            }, 1500)
          } else {
            console.log("Error while fetching data");
            setsuccessMsg('');
            seterrorMsg(response.message);

          }
        });
      } else {
        formData.append("url", `${window.location.origin}/public-groups?name=${props.groupData.group_name}&type=${props.groupData.tournament_type}`);
        PostData("groups/sendInvite", formData).then((response) => {
          setShowSpinner(true);
          if (response.status === true) {
            setShowSpinner(false);
            seterrorMsg('');
            setsuccessMsg(response.message);
            setTimeout(() => {
              props.hide && props.hide(response.status);
            }, 1500)
          } else {
            setShowSpinner(false);
            console.log("Error while fetching data");
            setsuccessMsg('');
            seterrorMsg(response.message);

          }
        });
      }
    }
  }

  const handleChange = (tg) => {
    setErrors({ ...errors, einviteFriends: false });
    let mtg = tg;
    if( mtg && mtg.length ) {
      mtg[mtg.length-1] = mtg[mtg.length-1].trim()
    }
    setTags(mtg);
  };

  const handleChangeInput = (tag) => {
    setErrors({ ...errors, einviteFriends: false });
    if(tag.indexOf(',') > -1) {
      let temptg = tags, emparr = [],
      temptag = tag.split(',');
      emparr = temptag.filter((d)=> {
        if( d && d != '') {
          return true;
        }
      })
      if( emparr.length ) {
        setTags([...temptg, ...emparr]);
        setTag('');
      }
    } else {
      setTag(tag);
    }
  };

  return (
    <Aux>
      <Modal
        show={props.show}
        centered
        onHide={props.hide}
        animation={false}
        className="modal-style-groups"
      >
        <Modal.Header closeButton className="header-style-modal">
          <Button onClick={props.hide} className="btn closebtn">
            <img src={loginclose} alt="deafult" />
          </Button>
          <Modal.Title>{props.modalType && props.modalType === 'send' ? 'SEND EMAIL' : 'INVITE FRIEND'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-small">
          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <TagsInput
              id="addtags"
              addOnBlur={() =>
                document.getElementById("addtags").click()
              }
              value={tags}
              onChange={handleChange}
              inputValue={tag}
              onChangeInput={handleChangeInput}
              inputProps={{
                placeholder:
                  "eg. xyz@domainname.com, zzz@domain.com",
              }}
            />
            <div className="err-paragraph">
              {errors.einviteFriends}
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Message
            </Form.Label>
            <Form.Control
              className="textarea-modal-send"
              as="textarea"
              maxLength={500}
              rows={6}
              placeholder="Enter message"
              value={userData.groupMessage.value}
              onClick={() => setErrors({ ...errors, ...{ eGroupMessage: false } })}
              onFocus={() => setErrors({ ...errors, ...{ eGroupMessage: false } })}
              onChange={(e) => setUserData({
                ...userData,
                ...{
                  groupMessage: {
                    value: e.currentTarget.value,
                    validation: ['blank'],
                    errorMsg: groupMessageErrorMsg,
                    errorKey: 'eGroupMessage',
                  },
                }
              })
              }
            />
          </Form.Group>
          <div className="error-msg-left">
            {errorMsg}
          </div>
          <div className="success-msg-left">
            {successMsg}
          </div>
          <div className="footer-btn">
            <Button
              onClick={handleCreateGroup}
              variant="primary"
              type="submit"
            >
              {showSpinner && (
                <i className="fa fa-circle-o-notch fa-spin"></i>
              )}
              {props.modalType && props.modalType === 'send' ? 'SEND' : 'INVITE'}
            </Button>
            <Button className="cancel-btn"
              onClick={props.hide}
            >
              CANCEL
            </Button>
          </div>
        </Modal.Body>

      </Modal>
    </Aux>
  )
}