import React, { Component } from "react";
import GoogleAd from './GoogleAdsense'
const advtImage =  "https://d7le4ielk3ho1.cloudfront.net/src_images/MLR-970-90.png";
const advtImageSmall =  "https://d7le4ielk3ho1.cloudfront.net/src_images/MLR-300-90.png";

class Placeadd extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {/*<GoogleAd slot="7442758947" classNames="page-top" />*/}
        <a href="https://www.therugbynetwork.com/join-free" target="_blank" style={{display:'block'}} rel="noreferrer">
          <img className="bigBanner" src={advtImage} alt="Flowers"/>
          <img className="smallBanner" src={advtImageSmall} alt="Flowers"/>
        </a>
      </div>
    );
  }
}

export default Placeadd;
