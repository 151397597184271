import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import ProfileBaner from "../components/Profile-hero";
import Faqaccordian from "../components/Faqs";
const Team =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/term-banner.jpg";
const profileheromobile =  "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/privacy banner mobile.jpg";

class PrivatePolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: "input",
    };
    this.showHide = () => this.showHide.bind(this);
  }
  componentDidMount() {
    document.title = "NCR Rugby | Privacy Policy";
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent privacypolicy">
          <Row>
            <Col md="12">
              <div className="leaderord headerAdvt">
                <Placeadd />
              </div>
            </Col>
          </Row>
          <Row>
            <ProfileBaner
              imgmobile={profileheromobile}
              img={Team}
              text="PRIVACY POLICY"
              alt="deafult"
            />
          </Row>
          <Row className="deatilstext" style={{ backgroundColor: "#eeeeee" }}>
            <Col className="container">
              <div className="textandadd">
                <div style={{ width: "100%" }} className="Aboutus">
                  <p className="p-green-bold">WEBSITE PRIVACY POLICY</p>
                  <p className="blackpad">Effective Date: March 1, 2020</p>
                  <p className="blackpad">
                    Thank you for visiting NCR.rugby. National Collegiate Rugby
                    Organization (the “Organization”) has updated its privacy
                    policy (“Privacy Policy”) in light of the California
                    Consumer Privacy Act, which requires the Organization to
                    provide certain information about how it collects and uses
                    the personal information of California residents and to
                    grant those California residents certain rights with respect
                    to their data.
                  </p>
                  <p className="blackpad">
                    This Privacy Policy sets out how the Organization and
                    certain of its partners may collect and use any personal
                    information which you provide through the services. This
                    Privacy Policy explains our online information practices and
                    the choices you can make about the way your information is
                    collected and used by this digital service, which includes
                    any online or mobile websites applications or digital
                    services owned, operated or offered by, on behalf of, or in
                    conjunction with the Organization, including any online or
                    mobile website, application or digital service installation
                    through which this Privacy Policy appears (each a “Service,”
                    collectively the “Services”). “Users” are persons that use
                    the Services.{" "}
                  </p>

                  <p className="blackpad">
                    California law requires us to provide certain information
                    about how we collect and use the personal information of
                    California residents and to grant those California residents
                    certain rights with respect to their data. If you are a
                    California resident, please see Section VII of this Privacy
                    Policy for these disclosures and a description of your
                    privacy rights, including a right to access, delete, or opt
                    out of the sale of your information.
                  </p>
                  <p className="p-green-bold">INFORMATION COLLECTED</p>
                  <p className="blackpad">
                    The Organization collects any information that you provide
                    to the Services, including, for example, when you register
                    for an account and provide your email address, a password,
                    and your name. Depending on how you use the Services, the
                    Organization may also collect:
                  </p>
                  <div className="text-list">
                    <ul>
                      <li>
                        Contact information such as address and phone number of
                        players and family members;&nbsp;
                      </li>
                      <li>
                        Team member information, including where applicable
                        information about your children;&nbsp;
                      </li>
                      <li>
                        Messages and chat you send via the services;&nbsp;
                      </li>
                      <li>
                        Team information, such as rosters, schedules,
                        attendance, statistics, and scores;&nbsp;
                      </li>
                      <li>
                        Activity information you upload, including photos, news,
                        information and other content;&nbsp;
                      </li>
                      <li>Payment information;&nbsp;</li>
                      <li>
                        Messages and feedback you send to the Organization;
                        and&nbsp;
                      </li>
                      <li>Transactional information.</li>
                    </ul>
                  </div>

                  <p className="p-green-bold">
                    AUTOMATICALLY COLLECTED INFORMATION
                  </p>
                  <p className="blackpad">
                    The Organization may also collect information from your
                    device when you use the Services, including:
                  </p>
                  <ul>
                    <li>
                      Information about your computer, mobile phone, tablet, and
                      all other similar electronic or mobile devices, including
                      your IP address, operating system and browser type, device
                      identifiers, device settings, device attributes, browser
                      settings, the web pages of the Services you have visited,
                      Services visited before and after you visit a Service, and
                      the content and advertisements you have accessed, seen,
                      forwarded and/or clicked-on;
                    </li>
                    <li>
                      Social Media Information. If you engage with the Services
                      through social media services or other third-party
                      platforms, such as Facebook, the Organization may have
                      access to certain Information in your profile depending on
                      your privacy settings; and
                    </li>
                    <li>
                      When the Organization interacts with you through or
                      content on third party websites, applications,
                      integrations or platforms, we may obtain information
                      regarding the content you have viewed, and information
                      about advertisements within the content you have been
                      shown or may have clicked on.
                    </li>
                  </ul>
                  <p className="p-green-bold">USE OF INFORMATION</p>
                  <p className="blackpad">
                    Your information may be used by the Organization to:
                  </p>
                  <ul>
                    <li>Provide the Services;&nbsp;</li>
                    <li>
                      Provide and communicate with you about the Services or
                      your account with us;&nbsp;
                    </li>
                    <li>
                      Fulfill your requests regarding the Services, including
                      without limitation requests for newsletters and
                      notifications;&nbsp;
                    </li>
                    <li>Respond to your inquires;</li>
                    <li>
                      Enforce legal terms that govern your use of our Services,
                      and/or for the purposes for which you provided the
                      information;
                    </li>
                    <li>Provide technical support for the Services;</li>
                    <li>Send you information;&nbsp;</li>
                    <li>
                      Prevent fraud or potentially illegal activities
                      (including, without limitation, copyright infringement) on
                      or through then Services and defend legal rights;
                    </li>
                    <li>Protect the safety of our Users;</li>
                    <li>
                      Customize or personalize ads, offers and content made
                      available to you based on your visits to and/or usage of
                      the Services or other online or mobile websites,
                      applications, platforms or services, and analyze the
                      performance of those ads, offers and content, as well as
                      your interaction with them;
                    </li>
                    <li>
                      Perform analysis regarding how you use the Services or any
                      part thereof;&nbsp; and
                    </li>
                    <li>Comply with applicable law.</li>
                  </ul>
                  <p className="blackpad">
                    When you provide information from your social media account,
                    it can help enable the Organization to do things like (1)
                    give you exclusive content, (2) personalize your online
                    experience with us within and outside our Services, (3)
                    contact you through the social media service or directly by
                    sending you the latest news, special offerings, and rewards,
                    and (4) enable you to share your experience and content via
                    social media services. When you provide information about
                    yourself to us through an application, through our Services,
                    or on social media services or other third-party platforms,
                    it may be publicly viewed by other members of these services
                    and we cannot prevent further use of the information by
                    third parties.
                  </p>
                  <p className="blackpad">
                    The Organization may use “cookies” or similar technologies
                    to associate certain of the information with a unique
                    identifier that then associates the information with your
                    device or browser. The Organization may combine any of the
                    information it collects, for any of the above purposes, and
                    may sometimes enhance the information with other information
                    that we obtain from third party sources. Please also be
                    aware that if you choose to submit information or content
                    for publication (e.g., a letter to our editors, a posting to
                    a blog or a discussion board, or a video), we may publish
                    that information and content, along with other information
                    about you (such as your name, screen name, or location). If
                    you register and create a profile with our Service, the
                    screen name you select as well as other content you submit
                    to your profile (e.g., photos, comments, video, reviews)
                    will be displayed publicly on the Service, is searchable,
                    can be found by third parties and may be reused and
                    redistributed by us in our sole discretion.{" "}
                  </p>
                  <p className="p-green-bold">DISCLOSURE OF INFORMATION</p>
                  <p className="blackpad">
                    The Organization may share your information as follows:{" "}
                  </p>
                  <ul>
                    <li>
                      To Service providers that perform certain business-related
                      functions on its behalf;&nbsp;
                    </li>
                    <li>
                      To Social Networks: if you use social networks through the
                      services;&nbsp;
                    </li>
                    <li>
                      To other Users: depending on how you use the Services your
                      information may be available to other members your
                      league;&nbsp;
                    </li>
                    <li>
                      As required by law in with Law Enforcement Agencies or
                      Regulators;
                    </li>
                    <li>
                      In response to legal process, for example, in response to
                      a court order or a subpoena, a law enforcement or
                      government’s agency’s request or similar request;
                    </li>
                    <li>
                      National Governing Bodies: The Organization may share your
                      information with the following National Governing Body of
                      your sport, for instance, to confirm your eligibility to
                      participate in a sport;
                    </li>
                    <li>
                      With third parties in order to investigate, prevent, or
                      take action (in our sole discretion) regarding potentially
                      illegal activities, suspected fraud, situations involving
                      potential threats to any person, us, or the Services, or
                      violations of our policies, the law, to verify or enforce
                      compliance with the policies governing your Services;
                    </li>
                    <li>
                      We may transfer some or all of your information if the
                      Organization undergoes a business transition, like a
                      merger, acquisition by another company, or sale of all or
                      part of our assets, or if a substantial portion of our or
                      of a business unit’s assets is sold or merged in this way;
                      and
                    </li>
                    <li>
                      We may share the information with affiliated and
                      unaffiliated third parties (e.g., or third-party’s service
                      providers, advertisers, advertising networks and
                      platforms, agencies, other marketers, magazine publishers,
                      retailers, participatory databases, and non-profit
                      organizations) that wish to market products or services to
                      you.&nbsp; If you wish to opt out from such sharing please
                      visit the&nbsp;
                      <strong>
                        How to Contact Us/Opting Out of Marketing Communications
                      </strong>
                      &nbsp;section below for further instructions.
                    </li>
                  </ul>
                  <p className="blackpad">
                    Please note that the Services covered by this Privacy Policy
                    may offer content (e.g., contests, sweepstakes, promotions,
                    games, application, or social network integrations) that is
                    sponsored by or co-branded with identified third parties. By
                    virtue of these relationships, the third parties may obtain
                    information from their visitors. The Organization has no
                    control over these third parties’ use of this information,
                    which is subject to their own privacy policies.{" "}
                  </p>
                  <p className="p-green-bold">
                    OTHER INFORMATION ABOUT YOUR PRIVACY
                  </p>
                  <ol>
                    <li>
                      <strong>Linked Services.</strong>&nbsp; Some of the
                      Services contain links to or integrations with other
                      services such as Facebook, Twitter, LinkedIn, and other
                      media services and platforms whose information practices
                      may be different than ours. Visitors should consult these
                      other services’ privacy notices as we have no control over
                      information that is submitted to, or collected by, these
                      third parties.
                    </li>
                    <li>
                      <strong>International Transfer.</strong>&nbsp;This Service
                      is governed by and operated in accordance with US law. If
                      you are located outside of the US, you use this Service at
                      your own risk. The Organization may have Users who live
                      outside of the United States, so it may be necessary to
                      transfer your information internationally. In particular,
                      your Information will be transferred to and processed in
                      the United States where many of our databases operate. By
                      using this Service, you acknowledge that the data
                      protection and other laws of other countries, such as the
                      United States, may provide a less comprehensive or
                      protective standard of protection than those in your
                      country (e.g., the European Union’s General Data
                      Protection Regulation), and consent to your information
                      being collected, processed and transferred as set forth in
                      this Privacy Policy and US law.&nbsp; NCR’s privacy
                      protections are aggressive and thorough. That being said,
                      you privacy information held with NCR are only used to
                      further the charitable mission of NCR.
                    </li>
                  </ol>
                  <p className="p-black-bold greenpad">
                    How You Can Access or Correct Information. Access to certain
                    personal Information that is collected from our Services and
                    that we maintain may be available to you. For example, if
                    you created a password-protected account within our Service,
                    you can access that account to review certain information
                    you provided. You may also send an e-mail or letter to the
                    following e-mail or street address requesting access to or
                    correction of your personal information. Please include your
                    registration information for such service such as first
                    name, last name and e-mail address in the request. We may
                    ask you to provide additional information for identity
                    verification purposes, or to verify that you are in
                    possession of an applicable email account.
                  </p>
                  <p>National Collegiate Rugby Organization</p>
                  <p style={{ padding: "0" }} className="blackpad">
                    230 N. Maple Ave., Suite B1 #295
                  </p>
                  <p className="blackpad">Marlton, NJ 08053-9412</p>
                  <p className="blackpad">
                    <p>
                      <strong>
                        How to Contact Us/Opting Out of Marketing
                        Communications.
                      </strong>
                      &nbsp;If you have any questions or concerns about the
                      online Privacy Policy for this Service or its
                      implementation, or if you would like to opt out from our
                      sharing of your personal Information with unaffiliated
                      third parties for the third parties’ direct marketing
                      purposes you may contact our Privacy Policy Coordinator at
                      the above address or&nbsp;
                      <a href="mailto:privacy@ncr.rugby">privacy@ncr.rugby</a>
                      &nbsp;and request that we opt you out of such sharing. We
                      may ask you to provide additional information for identity
                      verification purposes, or to verify that you are in
                      possession of an applicable email account.
                    </p>
                  </p>
                  <p className="blackpad">
                    <p>
                      If you have signed up to receive our e-mails and prefer
                      not to receive marketing information from this Service,
                      follow the “unsubscribe” instructions provided on any
                      marketing e-mail you receive from this Service. If you
                      have signed up to receive text messages from us and no
                      longer wish to receive such messages, you may follow the
                      instructions to stop the delivery of such messages, which
                      may include by replying “STOP” to the received text
                      message.
                    </p>
                  </p>
                  <p className="p-green-bold">
                    CHILDREN’S ONLINE PRIVACY PROTECTION ACT (“COPPA”)
                  </p>
                  <p className="blackpad">
                    COPPA, the Children’s Online Privacy Protection Act, applies
                    to individually identifiable information about a child that
                    is collected online, such as full name, home address, email
                    address, telephone number or any other information that
                    would allow someone to identify or contact the child. The
                    Act and Rule also cover other types of information — for
                    example, hobbies, interests and information collected
                    through cookies or other types of tracking mechanisms — when
                    they are tied to individually identifiable information.
                  </p>
                  <p className="blackpad">
                    To comply with COPPA, the Organization will require parental
                    consent for children under the age of thirteen (13) to
                    register or provide other information to the Organization
                    through the use of the internet website, NCR.rugby. If, at
                    any time, this Privacy Policy changes to allow for the
                    collection of information from children under the age of 13,
                    the changes will be notified in this document, and
                    additional information will be provided pursuant to COPPA,
                    in the form of: (a) names and contact information of
                    operators employed by the Organization to collect this data;
                    (b) types of information and reasons for the Organization to
                    obtain this information from children; (c) procedures by
                    which parents can review a child’s personal information.
                  </p>
                  <p className="p-green-bold">CHILDREN 13 & UNDER</p>
                  <p className="blackpad">
                    Please pay close attention to the information outlined in
                    this document. You are required to have your parents
                    understanding and consent before you enter any personally
                    identifiable information into any forms on the
                    Organization’s website.
                  </p>
                  <p className="p-green-bold">PARENTS</p>
                  <p className="blackpad">
                    Please be sure to read this Privacy Policy. The Organization
                    requires your consent prior to any child thirteen (13) or
                    under entering any personally identifiable information into
                    the Organization’s website. Please notify the Organization
                    immediately if you have any concerns regarding the
                    Organization and COPPA. We encourage parents and guardians
                    to spend time online with their children, and to take an
                    active role to protect the privacy of their children,
                    including, but not limited to, instructing their children
                    not to disclose any personal information over the Internet
                    without your prior permission. For additional information
                    concerning COPPA, parents and guardians are encouraged to
                    visit www.coppa.org.
                  </p>
                  <p className="p-green-bold">
                    CALIFORNIA AND CCPA PRIVACRY RIGHTS AND DISCLOSURES
                  </p>
                  <p className="blackpad">
                    This section addresses legal obligations and rights laid out
                    in the California Consumer Privacy Act, or CCPA. These
                    obligations and rights apply to businesses doing business in
                    California and to California residents and information that
                    identifies, relates to, describes, is reasonably capable of
                    being associated with, or could reasonably be linked,
                    directly or indirectly, with California consumers or
                    households (“California Information”). The information
                    contained in this section applies to California Information
                    collected and used by NCR (for the purposes of this section,
                    “we” or “us”). If you are a California resident and would
                    like to learn more about this information, please review the
                    following sections.
                  </p>
                  <p className="p-green-bold">
                    California Information We Collected and Shared
                  </p>
                  <p className="blackpad">
                    This section provides the information California residents
                    need to exercise their rights over their California
                    Information. Here is information about the California
                    Information we have collected from and shared about
                    consumers over the twelve months prior to the Effective Date
                    of this Privacy Policy.
                  </p>
                  <p className="p-green-bold">
                    CALIFORNIA INFORMATION WE COLLECTED
                  </p>
                  <ul>
                    <li>
                      Address and other identifiers – such as name, postal
                      address, zip code, email address, account name, Social
                      Security number, driver’s license number, payment card
                      numbers, passport number, or other similar identifiers
                    </li>
                    <li>
                      Unique and online identifiers – such as IP address, device
                      IDs, or other similar identifiers
                    </li>
                    <li>
                      Characteristics of protected classifications – such as
                      race, ethnicity, or sexual orientation
                    </li>
                    <li>
                      Commercial information – such as products or services
                      purchased, obtained, or considered, or other purchasing or
                      consuming histories or tendencies
                    </li>
                    <li>
                      Internet, gaming or other electronic network activity
                      information – such as browsing history, search history and
                      information regarding an individual’s interaction with an
                      internet website, application, or advertisement
                    </li>
                    <li>Professional or educational Information&nbsp;</li>
                    <li>Biometric information</li>
                    <li>
                      Video footage (e.g., CCTV); Audio recordings; Photographs;
                      Calendar information
                    </li>
                    <li>
                      Location information – (e.g. if you access our Sites on
                      your mobile device we may collect Information about your
                      device’s precise location.)
                    </li>
                    <li>
                      In game or online viewing activities (e.g., videos viewed,
                      pages viewed)
                    </li>
                    <li>
                      Inferences drawn from California Information, such as
                      individual profiles, preferences, characteristics,
                      behaviors.
                    </li>
                  </ul>
                  <p className="blackpad">
                    We collected these categories of California Information for
                    the following purposes:
                  </p>
                  <ul>
                    <li>
                      Performing services on behalf of the business, such as
                      customer service, processing or fulfilling orders,
                      providing content recommendations, and processing payments
                    </li>
                    <li>Auditing customer transactions</li>
                    <li>Fraud and crime prevention</li>
                    <li>Debugging errors in systems</li>
                    <li>Marketing and advertising</li>
                    <li>
                      Internal research, analytics and development – e.g.,
                      user-preference analytics
                    </li>
                    <li>
                      Developing, maintaining, provisioning or upgrading
                      networks, products, services, or devices.
                    </li>
                  </ul>
                  <p className="blackpad">
                    We obtained California Information from a variety of
                    sources, including:
                  </p>
                  <ul>
                    <li>
                      Directly from you, including technical and usage
                      information when you use our Sites
                    </li>
                    <li>
                      Linked sites, such as Social Media Sites, and third-party
                      platforms
                    </li>
                    <li>
                      Our joint-ventures and promotional and strategic
                      partners&nbsp;
                    </li>
                    <li>Information suppliers</li>
                    <li>Distributors and other vendors</li>
                    <li>Marketing mailing lists</li>
                    <li>
                      Other users submitting California Information about you,
                      such as to invite you to participate in an offering, make
                      recommendations, or share content
                    </li>
                    <li>Publicly available sources.</li>
                  </ul>
                  <p className="p-green-bold">
                    CALIFORNIA INFORMATION WE SHARED
                  </p>
                  <ul>
                    <li>
                      Address and other identifiers – such as name, postal
                      address, email address, account name, Social Security
                      number, driver’s license number, payment card numbers
                      passport number, or other similar identifiers
                    </li>
                    <li>
                      Unique and online identifiers – IP address, device IDs, or
                      other similar identifiers
                    </li>
                    <li>
                      Commercial information – such as records of personal
                      property, products or services purchased, obtained, or
                      considered, or other purchasing or consuming histories or
                      tendencies
                    </li>
                    <li>
                      Internet, gaming or other electronic network activity
                      information – such as browsing history, search history,
                      and information regarding an individual’s interaction with
                      an internet website, application, or advertisement
                    </li>
                    <li>Professional or educational Information&nbsp;</li>
                    <li>Biometric information</li>
                    <li>
                      Video footage (e.g., CCTV); Audio recordings; Photographs;
                      Calendar information&nbsp;
                    </li>
                    <li>
                      Location information – (e.g. if you access our Sites on
                      your mobile device we may collect Information about your
                      device’s precise location.)
                    </li>
                    <li>
                      Online viewing activities (e.g., videos viewed, pages
                      viewed)
                    </li>
                    <li>
                      Inferences drawn from California Information, such as
                      individual preferences, characteristics, behaviors.
                    </li>
                  </ul>
                  <p className="blackpad">
                    The California Consumer Privacy Act defines “sale” very
                    broadly. It includes the sharing of California Information
                    in exchange for anything of value. According to this broad
                    definition, in the year before the date this policy was
                    issued, we also sold the following categories of California
                    Information:
                  </p>
                  <ul>
                    <li>
                      Address and other identifiers – such as name, postal
                      address, email address, account name, Social Security
                      number, driver’s license number, passport number, or other
                      similar identifiers
                    </li>
                    <li>
                      Unique and online identifiers – IP address, device IDs, or
                      other similar identifiers
                    </li>
                    <li>
                      Commercial information – such as records of personal
                      property, products or services purchased, obtained, or
                      considered, or other purchasing or consuming histories or
                      tendencies
                    </li>
                    <li>
                      Internet, gaming or other electronic network activity
                      information – such as browsing history, search history,
                      and information regarding an individual’s interaction with
                      an internet website, application, or advertisement
                    </li>
                    <li>
                      Location information – (e.g. if you access our Sites on
                      your mobile device we may collect Information about your
                      device’s precise location.)
                    </li>
                    <li>
                      Inferences drawn from California Information, such as
                      individual profiles, preferences, characteristics,
                      behaviors.
                    </li>
                  </ul>
                  <p className="p-black-bold greenpad">
                    Your California Privacy Rights to Request Disclosure of
                    Information We Collect and Share about You{" "}
                  </p>

                  <p className="blackpad">
                    In addition, if you are a California resident, the CCPA
                    grants you the right to request certain information about
                    our practices with respect to California Information. In
                    particular, you can request the following:{" "}
                  </p>
                  <ul>
                    <li>
                      The categories and specific pieces of your California
                      Information that we’ve collected.
                    </li>
                    <li>
                      The categories of sources from which we collected
                      California Information.
                    </li>
                    <li>
                      The business or commercial purposes for which we collected
                      or sold California Information.
                    </li>
                    <li>
                      The categories of third parties with which we shared
                      California Information.
                    </li>
                  </ul>
                  <p className="blackpad">
                    You can submit a request to us for the following additional
                    information:{" "}
                  </p>
                  <ul>
                    <li>
                      The categories of third parties to which we’ve sold
                      California Information, and the category or categories of
                      California Information sold to each
                    </li>
                    <li>
                      The categories of California Information that we’ve shared
                      with service providers who provide services for us
                    </li>
                  </ul>
                  <p className="p-black-bold greenpad">
                    Your Right to Request the Deletion of California Information
                  </p>
                  <p className="blackpad">
                    Upon your request, we will delete the California Information
                    we have collected about you, except for situations when that
                    information is necessary for us to: provide you with a
                    product or service that you requested; perform a contract we
                    entered into with you; maintain the functionality or
                    security of our systems; comply with or exercise rights
                    provided by the law; or use the information internally in
                    ways that are compatible with the context in which you
                    provided the information to us, or that are reasonably
                    aligned with your expectations based on your relationship
                    with us.
                  </p>
                  <p className="p-black-bold greenpad">
                    Your Right to Ask Us Not to Sell Your California Information
                  </p>
                  <p className="blackpad">
                    You can always tell us not to sell your California
                    Information by visiting here: Do Not Sell My Personal
                    Information.
                  </p>
                  <p className="blackpad">
                    Once we receive and process your request, we will not sell
                    your California Information unless you later allow us to do
                    so.
                  </p>
                  <p className="p-black-bold greenpad">Do Not Track Notice</p>
                  <p className="blackpad">
                    We do not currently take actions to respond to Do Not Track
                    signals and similar signals because a uniform technological
                    standard has not yet been developed. We continue to review
                    new technologies and may adopt a standard once one is
                    created.
                  </p>
                  <p className="blackpad">
                    See the Your Choices section to learn how to control data
                    collection for certain purposes.
                  </p>
                  <p className="p-black-bold greenpad">
                    Our Support for the Exercise of Your Data Rights
                  </p>
                  <p className="blackpad">
                    We are committed to providing you control over your
                    California Information. If you exercise any of these rights
                    explained in this section of the Privacy Policy, we will not
                    disadvantage you. You will not be denied or charged
                    different prices or rates for goods or services or provided
                    a different level or quality of goods or services.
                  </p>
                  <p className="p-black-bold greenpad">
                    Consumers Under 16 Years Old
                  </p>
                  <p className="blackpad">
                    CCPA has specific rules regarding the use of California
                    Information from consumers under 16 years of age. In
                    particular, consistent with the CCPA, if we have the
                    California Information of a consumer under the age of 16, we
                    will not sell the information unless we receive affirmative
                    permission to do so. If the consumer is between the ages of
                    13 and 16 years of age, the consumer may provide that
                    permission; if the consumer is under the age of 13, the
                    consumer’s parent or guardian must provide the permission.
                  </p>

                  <p className="blackpad">
                    <p>
                      If you would like further information on how we handle
                      California Information from consumers under the age of 16
                      years of age, or if you have questions about these
                      information practices, you may contact us at&nbsp;
                      <a href="mailto:privacy@ncr.rugby">
                        privacy@ncr.rugby&nbsp;
                      </a>
                      or at NCR, 230 N. Maple Ave., Suite B1 #295, Marlton, NJ
                      08053-9412.
                    </p>
                  </p>
                  <p className="p-green-bold">
                    LIST OF NCR’S AFFLITATES OR SPONSORS
                  </p>
                  <p className="blackpad">
                    <p>
                      Please visit&nbsp;
                      <a
                        target="_blank"
                        href="https://www.ncrugby.org/page/show/6132185-partners#_ga=2.225074328.309522095.1619622103-1633324883.1617037433"
                      >
                        here
                      </a>
                      &nbsp;to see a representative list of the Organizations
                      sponsors and affiliates that currently may have access to
                      the Information collected from Users on our Services as
                      set forth in this Privacy Policy. This list also includes
                      companies that are affiliated with the Organization, with
                      whom we may share Information we collect on our Services.
                      We may update this list from time-to-time, so please check
                      back periodically to keep up-to-date.
                    </p>
                  </p>
                  <p className="p-black-bold greenpad">UPDATES</p>
                  <p className="blackpad">
                    From time to time, we may update this Privacy Policy. We
                    will notify you about material changes by either sending an
                    email message to the email address you most recently
                    provided to us or by posting a notice on our Service. We
                    encourage you to periodically check back and review this
                    policy so that you always will know what information we
                    collect, how we use it, and with whom we share it.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Placeaddsm />
            </Col>
          </Row>
        </div>
      </Aux>
    );
  }
}
export default PrivatePolicy;
