import React, { Component } from "react";

class Chkleaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <tr>
          <td className="profile-rank">{this.props.rank}</td>
          <td>
            {/* <abbr title={this.props.name}>{this.props.name}</abbr> */}
            {/* <span className="profile-img">
              <img className="user-profile-image" src={this.props.img} alt="dp" />
            </span> */}


            {/* {
              this.props.bracket_name ? <span className="bracket-name">{this.props.bracket_name}</span> : ''
            }
            {this.props.userName}  */}
            
            {
              this.props.bracket_name ? 
              <><div className="profile-nme bracket-name">
              {this.props.bracket_name}<div className="username-style">{this.props.name}</div></div> </>
              : ''
            }
            
          </td>
          <td>
            <abbr>{this.props.team}</abbr>
          </td>
          <td className="profile-score">{this.props.score}</td>
        </tr>
      </>
    );
  }
}

export default Chkleaderboard;
