import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

class slider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      appendDots: (dots) => (
        <div>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: (i) => <div>{i + 1 + "/" + 3}</div>,
    };
    return (
      <>
        <a rel="noreferrer" href="https://collegiaterugbychampionships.showare.com/default.asp" target="_blank"><div className="points_earned"></div></a>
      </>
    );
  }
}

export default slider;
