import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import { Link, NavLink } from "react-router-dom";
import Faqaccordian from "../components/Faqs";
import Placebigadd from "../components/Placeyouraddbigbox";
import PDF from '../assets/pdf/CRC-FINAL-SCHEDULE-3.0-3.pdf'

class MatchTimes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: "input",
    };
    this.showHide = () => this.showHide.bind(this);
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };

  componentDidMount() {
    document.title = "NCR Rugby | How To Play";
  }

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent invite-friend-wrap howto-play-wrap">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <div className="invite-body-outer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="invite-body-inner">
                      {/* Left container */}
                      <div className="outer-pdf">
                          <div className="pdf">
                              <iframe className="desktop-pdf" scrolling="auto" src={PDF} width="100%" height="600px" type='application/pdf' title="Title">
                                  <p ><em>There is content being displayed here that your browser doesn't support.</em> <a href={PDF} target="_blank"> Please click here to attempt to view the information in a separate browser window. </a> Thanks for your patience!</p>
                              </iframe>
                              <iframe className="mobile-pdf" scrolling="auto" src={"https://drive.google.com/viewerng/viewer?embedded=true&url="+window.location.protocol + "//" + window.location.host+PDF} width="100%" height="600px" type='application/pdf' title="Title">
                                  <p><em>There is content being displayed here that your browser doesn't support.</em> <a href={PDF} target="_blank"> Please click here to attempt to view the information in a separate browser window. </a> Thanks for your patience!</p>
                              </iframe>
                          </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <Placeaddsm />
          </div>
        </div>
      </Aux>
    );
  }
}
export default MatchTimes;
