import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import { DeleteData } from "../../utils/apiRequestHandler";
import { globalVariable } from "../../utils/global";
import { consts } from "../../utils/validation";
import UploadLogo from "../../assets/images/logo-upload.jpg";

const Openeye =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const Closeye =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";
const loginclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function DeleteGroupMember(props){
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMsg, seterrorMsg] = useState('');
  const [successMsg, setsuccessMsg] = useState('');
  useEffect(() => {
    setsuccessMsg('');
    seterrorMsg('');
  },[props])

  const leaveGroup = () => {
    setShowSpinner(true);
    setsuccessMsg('');
    seterrorMsg('');
    let bid = props.deleteId;
    let member_id = props.member_id;
    let formData = {group_id : bid, member_id: member_id};
    DeleteData(`groups/member`, formData).then((response) => {
      setShowSpinner(false);
      if (response.status) {
        setsuccessMsg(response.message);
        setTimeout(() => {
          props.hide && props.hide(bid);
        },1000)
          
      } else {
        seterrorMsg(response.message);
      }
  });
  }


  return(
    <Aux>
        <Modal
          show={props.show}
          centered
          onHide={() => {props.hide && props.hide(false)}}
          show1={props.show2}
          animation={false}
          className="modal-style-groups"
        >
        <Modal.Header closeButton className="header-style-modal">
            <Button onClick={props.hide} className="btn closebtn">
              <img src={loginclose} alt="deafult" />
            </Button>
            <Modal.Title>DELETE MEMBER GROUP</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-small">
            <div className="delete-grp-text">
                Are you sure you want to delete member from this group?
            </div>  
            <div className="error-msg">{errorMsg}</div>
            <div className="success-msg">{successMsg}</div>
            <div className="footer-btn footer-btn-delete-modal">
            <Button
                onClick={()=> {leaveGroup()}}
                variant="primary"
                type="submit"
                className="delete-grp-member"
              >
                {showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                DELETE MEMBER
              </Button>
              <Button className="cancel-btn transfer-btn"
                onClick={() => {props.hide && props.hide(false)}}
              >
                CANCEL
              </Button>
              
            </div>
          </Modal.Body>
         
        </Modal>
    </Aux>
  )
}