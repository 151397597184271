import React from "react";
import Aux from "../hoc/_Aux";
import { Link, NavLink } from "react-router-dom";
import Menu from "../screens/includes/Navbar";
import { withRouter } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import Midimg from "../components/Midsectionimg";
import Banner from "../components/homepageBanner";
import Chkleaderboard from "../components/Chkleaderboard";
import Championship from "../components/Championship";
import Videosection from "../components/Video";
import Placeadd from "../components/Placeadd";
import Placeleaderbox from "../components/Placeleaderbox";
import Teamscores from "../components/Teamscores";
import Placebigadd from "../components/Placeyouraddbigbox";
import { GetData } from "../utils/apiRequestHandler";
import CrsLogo from "../assets/images/Images_Assets/cbs-sports-logo.png";

const demoimg =  "https://d7le4ielk3ho1.cloudfront.net/src_images/bg-images/video1imgbew.jpg";
const demoimg2 =  "https://d7le4ielk3ho1.cloudfront.net/src_images/new/video2.jpg";
const menD =  "https://d7le4ielk3ho1.cloudfront.net/src_images/new/men-default1.png";
const womenD =  "https://d7le4ielk3ho1.cloudfront.net/src_images/new/BitmapCopy9.png";


class UserHome extends React.Component {
  constructor(props) {
    var today = new Date(),
      time = today.toISOString();
    super(props);
    this.state = {
      currentTime: time,
      tournamenttime: localStorage.getItem("SatrtTime"),
      localtoken: localStorage.getItem("login"),
      type: "input",
      autoplayone: "",
      activeAccordion: null,
      active: false,
      prefix: "",
      autoplaytwo: "",
      playvideo: "",
      playsecondvideo: "",
      maleRanks: [],
      femaleRanks: [],
      tournamentStart: true
    };
    this.handleplayvideo = this.handleplayvideo.bind(this);
    this.handlesecondvid = this.handlesecondvid.bind(this);
  }
  setActiveAccordion(activename) {
    this.setState({ activeAccordion: activename });
  }
  componentDidMount() {
    document.title = "NCR Rugby | Home";
    let tournamenttime = JSON.parse(localStorage.getItem("tournamenttime"));
    if (tournamenttime) {
        this.setState({tournamentStart:tournamenttime.gameStarted})
    } 
    this.getRankedUsers();
  }

  getRankedUsers = () => {
    GetData("leaderboard/getTopRanks?gender=male&start=0&end=10").then(
      (response) => {
        if (response.status === true) {
          this.setState({
            maleRanks: response.data.allRank,
            prefix: response.data.image_prefix,
          });
        }
      }
    );
    GetData("leaderboard/getTopRanks?gender=female&start=0&end=10").then(
      (response) => {
        if (response.status === true) {
          this.setState({
            femaleRanks: response.data.allRank,
            prefix: response.data.image_prefix,
          });
        }
      }
    );
  };
  handleplayvideo() {
    this.setState({ playvideo: true, playsecondvideo: false });
    let youtubeurl =
      "https://www.youtube.com/embed/Gdzc1tJpsys?rel=0&amp;autoplay=1&amp;controls=0&amp;showinfo=0";
    this.setState({ autoplayone: youtubeurl });
  }
  handlesecondvid() {
    this.setState({ playsecondvideo: true, playvideo: false });
    let youtubeurl =
      "https://www.youtube.com/embed/B3E2u4oz044?rel=0&amp;;autoplay=1&amp;controls=0&amp;showinfo=0";
    this.setState({ autoplaytwo: youtubeurl });
  }

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent userhome">
          <Row>
            <Col md="12">
              <div className="leaderord headerAdvt">
                <Placeadd />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="teamparent" md="12">
              <Teamscores />
            </Col>
          </Row>
          <Row>
            <Banner />
          </Row>          
          <Row>
            <Col md="12">
              <div className="checkyourl">
                <p>
                  {this.state.tournamenttime == "true" ? (
                    <span style={{ borderBottom: "2px solid #000000" }}>
                      Brackets Challenge Leaderboards
                    </span>
                  ) : (
                    <>
                      <span style={{ borderBottom: "2px solid #000000" }}>
                        Leaderboards
                      </span>
                    </>
                  )}
                </p>
                <Row>
                  <Col md="6">
                    <div className="conatiner1">
                      <div className="detailbox">
                        <p style={{ padding: "0" }}>
                          <span className="textL">Men's Tournament</span>
                          <span className="textR">
                            {localStorage.getItem("login") ? (
                              <button
                                className="btn btn-sm btn-view"
                                onClick={() =>
                                  this.props.history.push(
                                    "/create-brackets/leaderboard/mens"
                                  )
                                }
                              >
                                VIEW ALL
                              </button>
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                        <Table>
                          <thead>
                            <tr className="leadbordhead">
                              <td>Rank</td>
                              <td>Username</td>
                              <td>Champion</td>
                              <td>Score</td>
                            </tr>
                          </thead>
                        </Table>
                        <div className="detailbox2">
                          <Table>
                            <tbody>
                              {this.state.maleRanks.length ? (
                                this.state.maleRanks.map((data) => {
                                  return (
                                    <Chkleaderboard
                                      img={
                                        data.image_path
                                          ? this.state.prefix + data.image_path
                                          : menD
                                      }
                                      name={data.userName}
                                      bracket_name={data.bracket_name ? data.bracket_name : ''}
                                      rank={data.rank}
                                      alt="deafult"
                                      score={data.score.toLocaleString()}
                                      team={data.winner_team}
                                    />
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={3} className="profile-rank" align="center" style={{textAlign:"center"}}>
                                  { this.state.tournamentStart ? 'No Data Available' : 'The tournament has not started yet'}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="conatiner2">
                      <div className="detailbox">
                        <p style={{ padding: "0" }}>
                          <span className="textL">Women’s Tournament</span>
                          <span className="textR">
                            {localStorage.getItem("login") ? (
                              <button
                                className="btn btn-sm btn-view"
                                onClick={() =>
                                  this.props.history.push(
                                    "/create-brackets/leaderboard/womens"
                                  )
                                }
                              >
                                VIEW ALL
                              </button>
                            ) : (
                              ""
                            )}
                          </span>
                        </p>
                        <Table>
                          <thead>
                            <tr className="leadbordhead">
                              <td>Rank</td>
                              <td>Username</td>
                              <td>Champion</td>
                              <td>Score</td>
                            </tr>
                          </thead>
                        </Table>
                        <div className="detailbox2">
                          <Table>
                            <tbody>
                              {this.state.femaleRanks.length ? (
                                this.state.femaleRanks.map((data) => {
                                  return (
                                    <Chkleaderboard
                                      img={
                                        data.image_path
                                          ? this.state.prefix + data.image_path
                                          : womenD
                                      }
                                      name={data.userName}
                                      bracket_name={data.bracket_name ? data.bracket_name : ''}
                                      rank={data.rank}
                                      alt="deafult"
                                      team={data.winner_team}
                                      score={data.score.toLocaleString()}
                                    />
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={3} className="profile-rank" align="center" style={{textAlign:"center"}}>
                                  { this.state.tournamentStart ? 'No Data Available' : 'The tournament has not started yet'}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md="12">
                <div className="wht-bg-box">
                      <div className="src-logo-box">
                        <span>CRC signs with</span>
                        <span><img src={CrsLogo} alt="crs logo"/></span>
                      </div>
                </div>
            </Col>
          </Row> */}
          {/* <Row>
            <Col md="12">
              <div className="wht-bg-box">
                <p>
                  Conference Representative Side 10’s Tournament
                  <br/>
                  <h5>Scroll below to see the match results</h5>
                  </p>                
                <iframe
                  id="tourneymachine"
                  style={{ display: "block", overflow: "scroll" }}
                  src="https://tourneymachine.com/Public/Results/DivisionEmbed.aspx?IDTournament=h2021050520232355898835981a08143&IDDivision=h2021050520232357407270e93478342"
                  style={{ width: "100%", height: 300 }}
                  allowfullscreen=""
                  frameborder="0"
                  scrolling="yes"
                ></iframe>
              </div>
            </Col>
          </Row> */}
          <Midimg />
          {/* <Championship /> */}
          {/* <Row>
            <Videosection
              md="6"
              playvideo={this.state.playvideo}
              className="vid-l"
              id="video1"
              imgsrc={demoimg}
              src={this.state.autoplayone}
              title="CRC 2021 Location Reveal"
              onClick={this.handleplayvideo}
              onHide={() => this.setState({ playvideo: false })}
            />
            <Videosection
              md="6"
              id="video2"
              playvideo={this.state.playsecondvideo}
              onClick={this.handlesecondvid}
              onHide={() => this.setState({ playsecondvideo: false })}
              className="vid-r"
              src={this.state.autoplaytwo}
              imgsrc={demoimg2}
              href="https://youtu.be/Gdzc1tJpsys"
              title="Collegiate Rugby Championship 2021"
            />
          </Row> */}
          <Row>
            <Col md="12">
              <div className="faq-box">
                {/* <div className="faq-inerbox">
                  <p>Rugby Bracket FAQs</p>
                </div>

                <div className="accordion__section_main">
                  <div>
                    <div
                      className=""
                      onClick={() => {
                        this.setState({
                          activeAccordion: "accordion1",
                          active:
                            this.state.activeAccordion != "accordion1"
                              ? true
                              : !this.state.active,
                        });
                      }}
                    >
                      <div className="accordion_heading">
                        {" "}
                        What is Bracket Challenge?
                      </div>
                      <div className="accordion_chevron">
                        {this.state.activeAccordion == "accordion1" &&
                        this.state.active ? (
                          <Chevron
                            className="accordion__icon rotate"
                            width={10}
                            fill={"#777"}
                          />
                        ) : (
                          <Chevron
                            className="accordion__icon"
                            width={10}
                            fill={"#777"}
                          />
                        )}
                      </div>
                    </div>
                    {this.state.activeAccordion == "accordion1" &&
                      this.state.active && (
                        <div className="accordion_content">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries,
                        </div>
                      )}
                  </div>
                </div>

                <div className="accordion__section_main">
                  <div>
                    <div
                      className=""
                      onClick={() => {
                        this.setState({
                          activeAccordion: "accordion2",
                          active:
                            this.state.activeAccordion != "accordion2"
                              ? true
                              : !this.state.active,
                        });
                      }}
                    >
                      <div className="accordion_heading">
                        {" "}
                        How Can Earn the Points?{" "}
                      </div>
                      <div className="accordion_chevron">
                        {this.state.activeAccordion == "accordion2" &&
                        this.state.active ? (
                          <Chevron
                            className="accordion__icon rotate"
                            width={10}
                            fill={"#777"}
                          />
                        ) : (
                          <Chevron
                            className="accordion__icon"
                            width={10}
                            fill={"#777"}
                          />
                        )}
                      </div>
                    </div>
                    {this.state.activeAccordion == "accordion2" &&
                      this.state.active && (
                        <div className="accordion_content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </div>
                      )}
                  </div>
                </div>

                <div className="accordion__section_main">
                  <div>
                    <div
                      className=""
                      onClick={() => {
                        this.setState({
                          activeAccordion: "accordion3",
                          active:
                            this.state.activeAccordion != "accordion3"
                              ? true
                              : !this.state.active,
                        });
                      }}
                    >
                      <div className="accordion_heading">
                        {" "}
                        Where does it come from?{" "}
                      </div>
                      <div className="accordion_chevron">
                        {this.state.activeAccordion == "accordion3" &&
                        this.state.active ? (
                          <Chevron
                            className="accordion__icon rotate"
                            width={10}
                            fill={"#777"}
                          />
                        ) : (
                          <Chevron
                            className="accordion__icon"
                            width={10}
                            fill={"#777"}
                          />
                        )}
                      </div>
                    </div>
                    {this.state.activeAccordion == "accordion3" &&
                      this.state.active && (
                        <div className="accordion_content">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </div>
                      )}
                  </div>
                </div> */}

                <div className="faq-button">
                  <p>
                    <br />
                    <br />
                    No purchase necessary to enter, win or claim a prize.
                  </p>
                  <p className="readmemore">
                    <Link to="/read-more">Read More</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="placeleaderbox">
                <Placeleaderbox />
              </div>
            </Col>
          </Row>
        </div>
      </Aux>
    );
  }
}
export default withRouter(UserHome);
