import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "../assets/style.scss";

class Championship extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Row>
          <Col className="champmob" md="12">
            <div className="championship-box">
              <Row className="champswaprow">
                <Col md="4">
                  <div className="champ-textbox">
                    <div className="text-box">
                      <p className="champ-bold-text">
                        The Collegiate Rugby Championship
                      </p>
                      <p className="champ-light-text">
                        The 2021 Collegiate Rugby Championship will be played
                        May 29-31 in New Orleans at The Shrine on Airline
                        stadium, home of Major League Rugby’s NOLA Gold,
                        featuring 32 men’s and 16 women’s teams vying for 7s
                        national championships.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="8">
                  <div className="champ-outer">
                    <div className="champ-img"></div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default Championship;
