import React from "react";
import { Link } from "react-router-dom";
import "../../assets/style.scss";
import { Col, Row } from "react-bootstrap";
import facebook from "../../assets/socilicons/fblogo.svg";
import twitter from "../../assets/socilicons/twitter-logo.svg";
import youtube from "../../assets/socilicons/youtube-logo.svg";
import instagram from "../../assets/socilicons/insta-logo.svg";
import foterimg from "../../assets/images/logo-footer.png";

import { Nav } from "react-bootstrap";



class footer extends React.Component {
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="footer">
              <div className="container footer-inner-width">
                <Row>
                  <Col>
                    <div className="footerimg">
                      {localStorage.getItem("login") ? (
                        <Link to="/">
                          <img
                            className="footerlogopc"
                            src={foterimg}
                            alt="default"
                          />
                        </Link>
                      ) : (
                        <Link to="/">
                          <img
                            className="footerlogopc"
                            src={foterimg}
                            alt="default"
                          />
                        </Link>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Nav className="footer-nav-links">
                      <Nav.Link target="_blank" className="nav-link" href="https://www.maymadness7s.com/event">
                        Event Info
                      </Nav.Link>
                      <Link className="nav-link" to="/how-to-play">
                        How To Play
                      </Link>
                      {/* <Nav.Link target="_blank" className="nav-link" href="https://www.maymadness7s.com/#participate">
                        Participate
                      </Nav.Link>
                      <Nav.Link target="_blank" className="nav-link" href="https://www.maymadness7s.com/#stadium">
                        Stadium
                      </Nav.Link> */}
                      <Nav.Link target="_blank" className="nav-link" href="https://www.maymadness7s.com/news">
                        News
                      </Nav.Link>
                      <Nav.Link className="nav-link" target="_blank" href="https://www.maymadness7s.com/contact-us">
                        Contact Us
                      </Nav.Link>
                    </Nav>
                  </Col>
                </Row>
                <div className="hr-line"></div>
                <Row>
                  <Col>
                    <Nav className="socialmedia">
                      <Nav.Link
                        target="_blank"
                        href=" https://www.facebook.com/nationalcollegiaterugby/"
                      >
                        <img src={facebook} alt="default" />
                      </Nav.Link>
                      <Nav.Link
                        target="_blank"
                        href="https://www.instagram.com/nationalcollegiaterugby/"
                      >
                        <img src={instagram} alt="default" />
                      </Nav.Link>
                      <Nav.Link
                        target="_blank"
                        href="https://www.youtube.com/c/NationalCollegiateRugby/featured"
                      >
                        <img src={youtube} alt="default" />
                      </Nav.Link>
                      <Nav.Link
                        target="_blank"
                        href="https://twitter.com/ncrrugby?fbclid=IwAR3tHM239yQskkNNY_4v9Jlz7NvJuRTLul7jLZnuurKp5vLEfj5NVakA2d8"
                      >
                        <img src={twitter} alt="default" />
                      </Nav.Link>                      
                    </Nav>
                  </Col>
                </Row>
                
                <Row>
                  <Col className="copyright">
                    <p>&copy; {new Date().getFullYear()} National Collegiate Rugby Organization, LLC.</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Nav className="privacy-link">
                      <Nav.Link className="nav-link" target="_blank" href="https://www.maymadness7s.com/privacy">
                        Privacy Policy
                      </Nav.Link>
                      <Nav.Link className="nav-link" href="/terms-and-conditions">
                        {'Terms & Conditions'}
                      </Nav.Link>
                    </Nav>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default footer;
