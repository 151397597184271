import React, { Component } from "react";
import Accordion from "../components/Accordion";
import { Link } from "react-router-dom";
import Chevron from "../components/Chevron";

class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="Faqs">
          <p className="header">
            <h3>FAQs</h3>
          </p>
          <div className="accordion__section_main">
            <div>
              <div
                className=""
                onClick={() => {
                  this.setState({
                    activeAccordion: "accordion1",
                    active:
                      this.state.activeAccordion != "accordion1"
                        ? true
                        : !this.state.active,
                  });
                }}
              >
                <div className="accordion_heading">{this.props.heading1}</div>
                <div className="accordion_chevron">
                  {this.state.activeAccordion == "accordion1" &&
                  this.state.active ? (
                    <Chevron
                      className="accordion__icon rotate"
                      width={10}
                      fill={"#777"}
                    />
                  ) : (
                    <Chevron
                      className="accordion__icon"
                      width={10}
                      fill={"#777"}
                    />
                  )}
                </div>
              </div>
              {this.state.activeAccordion == "accordion1" &&
                this.state.active && (
                  <div className="accordion_content">{this.props.content1}</div>
                )}
            </div>
          </div>

          <div className="accordion__section_main">
            <div>
              <div
                className=""
                onClick={() => {
                  this.setState({
                    activeAccordion: "accordion2",
                    active:
                      this.state.activeAccordion != "accordion2"
                        ? true
                        : !this.state.active,
                  });
                }}
              >
                <div className="accordion_heading">{this.props.heading2}</div>
                <div className="accordion_chevron accordion_chevron2">
                  {this.state.activeAccordion == "accordion2" &&
                  this.state.active ? (
                    <Chevron
                      className="accordion__icon rotate"
                      width={10}
                      fill={"#777"}
                    />
                  ) : (
                    <Chevron
                      className="accordion__icon"
                      width={10}
                      fill={"#777"}
                    />
                  )}
                </div>
              </div>
              {this.state.activeAccordion == "accordion2" &&
                this.state.active && (
                  <div className="accordion_content">{this.props.content2}</div>
                )}
            </div>
          </div>

          {/* <div className="accordion__section_main">
            <div>
              <div
                className=""
                onClick={() => {
                  this.setState({
                    activeAccordion: "accordion3",
                    active:
                      this.state.activeAccordion != "accordion3"
                        ? true
                        : !this.state.active,
                  });
                }}
              >
                <div className="accordion_heading">
                  {" "}
                  Where does it come from?{" "}
                </div>
                <div className="accordion_chevron">
                  {this.state.activeAccordion == "accordion3" &&
                  this.state.active ? (
                    <Chevron
                      className="accordion__icon rotate"
                      width={10}
                      fill={"#777"}
                    />
                  ) : (
                    <Chevron
                      className="accordion__icon"
                      width={10}
                      fill={"#777"}
                    />
                  )}
                </div>
              </div>
              {this.state.activeAccordion == "accordion3" &&
                this.state.active && (
                  <div className="accordion_content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </div>
                )}
            </div>
          </div> */}
        </div>
      </>
    );
  }
}

export default Faqs;
