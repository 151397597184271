import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import JoinGroupModal from "../components/groupModal/JoinGroupModal";
import Login from "../components/LoginModal/Login";
import TimerBanner from "../components/timer-banner";
import Bluebtn from "../components/Bluebtn";
import { GetData } from "../utils/apiRequestHandler";
import Loader from "../components/Loader";
import { globalVariable } from "../utils/global";
import axios from "axios";
import { hydrate } from "react-dom";
import { Form, Modal, Button } from "react-bootstrap";
import MyGroupLogo from "../assets/images/logo3.png";
import PublicIcon from "../assets/images/public.svg";
import PrivateIcon from "../assets/images/private.svg";
import CrossBtn from "../assets/images/cross-btn.png";
import CopyIcon from "../assets/images/copy.svg";

const men = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/men-tourna.png";
const women = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/women-tourna.png";

class PublicGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            bracketData: [],
            maleBracket: [],
            femaleBracket: [],
            currentBracket: "male",
            currentTabSelected: "profile",
            isPartiallyFilledMens: false,
            isPartiallyFilledWomens: false,
            isBracketEditableMens: true,
            isBracketEditableWomens: true,
            removeButton: false,
            activeTabBracket: "tabBracket1",
            activeTabMyGroups: "tabMyGroup1",
            activeTabPublicGroups: "tabPublicGroup1",
            leaderMemberTableList: false,
            showModal: false,
            deleteId: null,
            groupData: [],
            editGroupData: null,
            maleBracketEnable: true,
            femaleBracketEnable: true,
            createGroupEnable: false,
            memberList: [],
            showCopy: false,
            publicGroupMaleData: {
                rows: [],
                count: 0,
                limit: 4,
                page: 1
            },
            publicGroupFemaleData: {
                rows: [],
                count: 0,
                limit: 4,
                page: 1
            },
            joinGroupModal: {
                show: false,
                hide: this.closeJoinGroupModal
            },
            searchVal: '',
            s3URL: 'https://ncrstaging.s3.us-west-2.amazonaws.com/',
            loginmodale: false,
            show: "",
            tournamentStart: true
        };
        this._currentBracket = "male";
    }


    changeLimit = (event) => {

        let page = parseInt(event.selected) + 1;
        if (this.state.activeTabPublicGroups === 'tabPublicGroup1') {
            this.setState({
                publicGroupMaleData: { ...this.state.publicGroupMaleData, ...{ page: page } }
            }, () => {
                this.getPublicGroups();
            });
        } else {
            this.setState({
                publicGroupFemaleData: { ...this.state.publicGroupFemaleData, ...{ page: page } }
            }, () => {
                this.getPublicGroups();
            });
        }

    };
    getPublicGroups = (searhValue = '') => {
        this.setState({ loader: true });
        let gender = this.state.activeTabPublicGroups === 'tabPublicGroup1' ? 'male' : 'female';
        let limit = gender === 'male' ? this.state.publicGroupMaleData.limit : this.state.publicGroupFemaleData.limit;
        let page = gender === 'male' ? this.state.publicGroupMaleData.page : this.state.publicGroupFemaleData.page;
        let token = localStorage.getItem("login") ? JSON.parse(localStorage.getItem("login")) : "";
        let url;
        if (token) {
            url = 'groups/public';
        } else {
            url = 'groups/public-noauth';
        }
        url += `?type=${gender}&limit=${limit}&page=${page}`;
        if (this.state.searchVal) {
            url += `&searhValue=${this.state.searchVal}`;
        }
        GetData(url).then((response) => {
            console.log('response groups', response)
            if (response.status && response.data && response.data.rows) {
                if (gender === 'male') {
                    this.setState({
                        loader: false,
                        s3URL: response?.data?.s3url,
                        publicGroupMaleData: { ...this.state.publicGroupMaleData, ...response.data }
                    });
                } else {
                    this.setState({
                        loader: false,
                        s3URL: response?.data?.s3url,
                        publicGroupFemaleData: { ...this.state.publicGroupFemaleData, ...response.data }
                    });
                }
                const params = new URLSearchParams(window.location.search);
                let token = localStorage.getItem("login") ? JSON.parse(localStorage.getItem("login")) : "";
                let is_public = params.get('is_public');
                if( is_public && is_public === "0" && !token) {
                    this.handleShow();
                }
            } else {
                if (gender === 'male') {
                    this.setState({
                        loader: false,
                        s3URL: response?.data?.s3url,
                        publicGroupMaleData: {
                            rows: [],
                            count: 0,
                            limit: 4,
                            page: 1
                        }
                    });
                } else {
                    this.setState({
                        loader: false,
                        s3URL: response?.data?.s3url,
                        publicGroupFemaleData: {
                            rows: [],
                            count: 0,
                            limit: 4,
                            page: 1
                        }
                    });
                }
                const params = new URLSearchParams(window.location.search);
                let token = localStorage.getItem("login") ? JSON.parse(localStorage.getItem("login")) : "";
                let is_public = params.get('is_public');
                if( is_public && is_public === "0" && !token) {
                    this.handleShow();
                }
                
            }
        });
    }

    setAddClassb = () => {
        this.setState({
            addClass: false,
            show: false,
        });
    };
    handleShow = () => {
        console.log(this.state);
        this.setState({ show: true });
        this.setState({ addClass: false });
    }

    handleClose = () => {
        this.setState({ show: false });
        this.setState({ loginmodale: false });
    }
    closeJoinGroupModal = (data) => {
        this.setState({
            joinGroupModal: {
                show: false,
                hide: this.closeJoinGroupModal
            }
        }, () => {
            if (data) {
                this.getPublicGroups();
                this.getPublicGroups();
            }
        }
        );
    }



    mensPublicGroupTab1 = () => this.setState(
        { activeTabPublicGroups: "tabPublicGroup1" }, () => {
            this.getPublicGroups();
        }
    );
    womensPublicGroupTab2 = () => this.setState(
        { activeTabPublicGroups: "tabPublicGroup2" }, () => {
            this.getPublicGroups();
        }
    );

    cleanSearch = () => {
        this.setState({
            searchVal: ''
        }, () => {
            if (this.state.activeTabPublicGroups === 'tabPublicGroup1') {
                this.setState({
                    publicGroupMaleData: {
                        ...this.state.publicGroupMaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups();
                });
            } else {
                this.setState({
                    publicGroupFemaleData: {
                        ...this.state.publicGroupFemaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups();
                });
            }
        })
    }
    searchValue = (e) => {
        let value = e.target.value;
        this.setState({
            searchVal: value
        })
        if (value && value.length > 1) {
            if (this.state.activeTabPublicGroups === 'tabPublicGroup1') {
                this.setState({
                    publicGroupMaleData: {
                        ...this.state.publicGroupMaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups(value);
                });
            } else {
                this.setState({
                    publicGroupFemaleData: {
                        ...this.state.publicGroupFemaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups(value);
                });
            }
        } else if (!value || value.length === 0) {
            if (this.state.activeTabPublicGroups === 'tabPublicGroup1') {
                this.setState({
                    publicGroupMaleData: {
                        ...this.state.publicGroupMaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups();
                });
            } else {
                this.setState({
                    publicGroupFemaleData: {
                        ...this.state.publicGroupFemaleData, ...{
                            limit: 4,
                            page: 1
                        }
                    }
                }, () => {
                    this.getPublicGroups();
                });
            }
        }
    }
    cptoClipboard = (e, d) => {
        e && e.preventDefault();
        this.setState({
            showCopy: d.id
        })  
        setTimeout(() => {
            this.setState({
                showCopy: false
            }) 
          },1000) 
        window.Clipboard = (function (window, document, navigator) {
            var textArea,
                copy;

            function isOS() {
                return navigator.userAgent.match(/ipad|iphone/i);
            }

            function createTextArea(text) {
                textArea = document.createElement('textArea');
                textArea.value = text;
                document.body.appendChild(textArea);
            }

            function selectText() {
                var range,
                    selection;

                if (isOS()) {
                    range = document.createRange();
                    range.selectNodeContents(textArea);
                    selection = window.getSelection();
                    selection.removeAllRanges();
                    selection.addRange(range);
                    textArea.setSelectionRange(0, 999999);
                } else {
                    textArea.select();
                }
            }

            function copyToClipboard() {
                document.execCommand('copy');
                document.body.removeChild(textArea);
            }

            copy = function (text) {
                createTextArea(text);
                selectText();
                copyToClipboard();
            };

            return {
                copy: copy
            };
        })(window, document, navigator);

        // window.Clipboard.copy('text to be copied-121212');
        // navigator.clipboard.writeText(`${window.location.origin}/public-groups?name=${d.group_name}`);
        window.Clipboard.copy(`${window.location.origin}/public-groups?type=${d.tournament_type}&name=${d.group_name}`);
    }

    joinGroup = (e, d) => {
        e && e.preventDefault();
        let token = localStorage.getItem("login") ? JSON.parse(localStorage.getItem("login")) : "";

        if (token) {
            this.getAllBracket();
            this.setState({
                joinGroupModal: {
                    show: true,
                    hide: this.closeJoinGroupModal,
                    group_id: d.id
                }
            }, () => {
                // this.getGroups();
            }
            );
        } else {
            this.handleShow();
        }

    }

    getAllBracket = () => {
        this.setState({ loader: true });
        let token = localStorage.getItem("login") ? JSON.parse(localStorage.getItem("login")) : "";
        if (token) {
            GetData("manage-user-bracket/getAllBracket").then((response) => {
                console.log("Response", response);
                let femaleBracket = [], maleBracket = []
                if (response.status && response.data) {
                    if (response.data && response.data.length) {
                        response.data.map((d) => {
                            if (d.type === 'male') {
                                maleBracket.push(d)
                            } else {
                                femaleBracket.push(d)
                            }
                        })
                    }
                    // this.setState({ removeButton: true });
                    this.setState({
                        loader: false,
                        bracketData: response.data,
                        maleBracket: maleBracket,
                        femaleBracket: femaleBracket,
                        currentBracket: maleBracket.length < 5 ? 'male' : 'female',
                        maleBracketEnable: maleBracket.length < 5 ? true : false,
                        femaleBracketEnable: femaleBracket.length < 5 ? true : false,

                    });
                } else {
                    this.setState({
                        bracketData: [],
                        loader: false,
                        maleBracket: maleBracket,
                        femaleBracket: femaleBracket,
                        currentBracket: maleBracket.length < 5 ? 'male' : 'female',
                        maleBracketEnable: maleBracket.length < 5 ? true : false,
                        femaleBracketEnable: femaleBracket.length < 5 ? true : false,
                    });
                }
            });
        }
    }

    componentDidMount() {
        document.title = "NCR Rugby | Public Groups";
        const params = new URLSearchParams(window.location.search);
        let searchParam = params.get('name');
        let type = params.get('type');
        if( type ) {
            this.setState({
                activeTabPublicGroups: type === 'male' ? 'tabPublicGroup1' : 'tabPublicGroup2'
            })
        }
        let tournamenttime = JSON.parse(localStorage.getItem("tournamenttime"));
        if (tournamenttime) {
            this.setState({
                tournamentStart: tournamenttime.gameStarted
              }, () => {
                if (searchParam) {
                    this.setState({
                        ...this.state,
                        searchVal: searchParam
                    }, () => {
                        console.log('search value===>', this.state.searchVal);
                        this.getPublicGroups();
                    });
                } else {
                    this.getPublicGroups();
                }
                this.getAllBracket();
              })
        } else {
            if (searchParam) {
                this.setState({
                    ...this.state,
                    searchVal: searchParam
                }, () => {
                    console.log('search value===>', this.state.searchVal);
                    this.getPublicGroups();
                });
            } else {
                this.getPublicGroups();
            }
            this.getAllBracket();
        }

    }

    render() {
        return (
            <Aux>
                <Loader show={this.state.loader} />
                <Menu />
                <div className="Maincontent invite-friend-wrap fillbracket">
                    <div className="container-fluid">
                        <TimerBanner />
                        <div className="invite-body-outer">
                            <Container fluid>
                                <Row>
                                    <div className="col-12">
                                        <div className="tab-content">
                                            <div className="public-groups-wrap createbracket">
                                                <div className="my-brackets-wrap">
                                                    <div className="bracket-head-outer">
                                                        <div className="my-bracket-head">PUBLIC GROUPS</div>
                                                        <div className="bracket-top-tab">
                                                            <span onClick={this.mensPublicGroupTab1} className={this.state.activeTabPublicGroups === "tabPublicGroup1" ? "active" : ""}>MEN'S</span>
                                                            <span onClick={this.womensPublicGroupTab2} className={this.state.activeTabPublicGroups === "tabPublicGroup2" ? "active" : ""}>WOMEN'S</span>
                                                        </div>
                                                        <div className="search-box">
                                                            <Form.Control type="text" onChange={(e) => { this.searchValue(e) }} className={this.state.searchVal ? "search-group focus" : "search-group"} placeholder="Search..." value={this.state.searchVal} />
                                                            <button type="button" className="btn">
                                                                <svg width="15px" height="15px">
                                                                    <path d="M11.618 9.897l4.224 4.212c.092.09.1.23.02.312l-1.464 1.46c-.08.08-.222.072-.314-.02L9.868 11.66M6.486 10.9c-2.42 0-4.38-1.955-4.38-4.367 0-2.413 1.96-4.37 4.38-4.37s4.38 1.957 4.38 4.37c0 2.412-1.96 4.368-4.38 4.368m0-10.834C2.904.066 0 2.96 0 6.533 0 10.105 2.904 13 6.486 13s6.487-2.895 6.487-6.467c0-3.572-2.905-6.467-6.487-6.467 "></path>
                                                                </svg>
                                                            </button>
                                                            <div className={this.state.searchVal ? "cross" : "cross hide"}>
                                                                <img onClick={() => { this.cleanSearch() }} src={CrossBtn} alt="cross" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="public-group-box-outer">
                                                        {this.state.activeTabPublicGroups === "tabPublicGroup1"
                                                            ?
                                                            <div className="mens-container">
                                                                {/* white box start */}
                                                                {
                                                                    this.state.publicGroupMaleData.rows && this.state.publicGroupMaleData.rows.map((d) =>
                                                                        <div className="white-box">
                                                                            <div className="white-box-inner">
                                                                                <div className="my-group-left">
                                                                                    <div className="group-left-inner">
                                                                                        <div className="mygroup-logo">
                                                                                            <img src={d.group_image ? `${this.state.s3URL}${d.group_image}` : MyGroupLogo} alt="Logo" />
                                                                                        </div>
                                                                                        <div className="bracket-box-name">
                                                                                            <span className="name-inner">{d.group_name ? d.group_name : ''}</span>
                                                                                            <div className="public-logo-wrap">
                                                                                                <span className="mens-circle">men's</span>
                                                                                                <div className="public-icon">
                                                                                                    <img src={d.is_public ? PublicIcon : PrivateIcon} alt="Icon" />
                                                                                                    {d.is_public ? 'Public' : 'Private'} <span>({d.member_count ? d.member_count : 0})</span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="group-text">
                                                                                                {d.group_description ? d.group_description : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-btns">
                                                                                    <span className="copy-icon" title="Copy Link" onClick={(e) => { this.cptoClipboard(e, d) }}><img src={CopyIcon} alt="copy" />
                                                                                        {this.state.showCopy==d.id ? <span className="copied-text">Copied</span> : null }
                                                                                    </span>
                                                                                    {
                                                                                        this.state.tournamentStart || d.is_joined ? <><span className="mem-lead-group">
                                                                                            <Link to="#" onClick={(e) => { e.preventDefault(); }} className="member-btn disabled">Join Group</Link>
                                                                                        </span> </> : <>
                                                                                            <span className="mem-lead-group">
                                                                                                <Link to="#" onClick={(e) => { this.joinGroup(e, d) }} className="member-btn">Join Group</Link>
                                                                                            </span></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            :
                                                            <div className="womens-container">
                                                                {
                                                                    this.state.publicGroupFemaleData.rows && this.state.publicGroupFemaleData.rows.map((d) =>
                                                                        <div className="white-box">
                                                                            <div className="white-box-inner">
                                                                                <div className="my-group-left">
                                                                                    <div className="group-left-inner">
                                                                                        <div className="mygroup-logo">
                                                                                            <img src={d.group_image ? `${this.state.s3URL}${d.group_image}` : MyGroupLogo} alt="Logo" />
                                                                                        </div>
                                                                                        <div className="bracket-box-name">
                                                                                            <span className="name-inner">{d.group_name ? d.group_name : ''}</span>
                                                                                            <div className="public-logo-wrap">
                                                                                                <span className="womens-circle">women's</span>
                                                                                                <div className="public-icon">
                                                                                                    <img src={d.is_public ? PublicIcon : PrivateIcon} alt="Icon" />
                                                                                                    {d.is_public ? 'Public' : 'Private'} <span>({d.member_count ? d.member_count : 0})</span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="group-text">
                                                                                                {d.group_description ? d.group_description : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-btns">
                                                                                    <span className="copy-icon" title="Copy Link" onClick={(e) => { this.cptoClipboard(e, d) }}><img src={CopyIcon} alt="copy" />
                                                                                        {this.state.showCopy==d.id ? <span className="copied-text">Copied</span> : null }
                                                                                    </span>
                                                                                    {
                                                                                        this.state.tournamentStart || d.is_joined ? <><span className="mem-lead-group">
                                                                                            <Link to="#" onClick={(e) => { e.preventDefault(); }} className="member-btn disabled">Join Group</Link>
                                                                                        </span> </> : <>
                                                                                            <span className="mem-lead-group">
                                                                                                <Link to="#" onClick={(e) => { this.joinGroup(e, d) }} className="member-btn">Join Group</Link>
                                                                                            </span></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                                {/* end */}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="pagination-group">
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel="next"
                                                            onPageChange={this.changeLimit}
                                                            pageRangeDisplayed={4}
                                                            pageCount={this.state.activeTabPublicGroups === "tabPublicGroup1" ? this.state.publicGroupMaleData.count % 4 === 0 ? parseInt(this.state.publicGroupMaleData.count / 4) : parseInt(this.state.publicGroupMaleData.count / 4) + 1 : this.state.publicGroupFemaleData.count % 4 === 0 ? parseInt(this.state.publicGroupFemaleData.count / 4) : parseInt(this.state.publicGroupFemaleData.count / 4) + 1}
                                                            previousLabel="prev"
                                                            renderOnZeroPageCount={null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <JoinGroupModal
                        {...this.state.joinGroupModal}
                        bracketData={this.state.bracketData}
                        editGroupData={this.state.editGroupData}
                        selectedGender={this.state.activeTabPublicGroups === "tabPublicGroup1" ? 'male' : 'female'}
                    />
                    <Login
                        show={this.state.show}
                        onClick={() => this.setState({ show: false })}
                        onClickB={this.setAddClassb}
                        redirectUrl={window.location.pathname + window.location.search}
                    />
                </div>




            </Aux>
        );
    }
}
export default PublicGroup;
