import React, { Component } from "react";
import { Table } from "react-bootstrap";

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="adminled-box">
          <Table>
            <tbody style={{ width: "100%" }}>
              <tr className={`${this.props.looserteamA}`}>
                <td style={{ width: "80%" }} className="team">
                  {/* <img src={this.props.imgA} alt="teamlogo" /> */}
                  <span>{this.props.teamOneName}</span>
                </td>
                <td style={{ width: "20%" }} className="score">
                  {this.props.teamOneScore}
                </td>
              </tr>
              <tr className={`${this.props.looserteamB} "secondrow"`}>
                <td style={{ width: "80%" }} className="team">
                  {/* <img src={this.props.imgB} alt="teamlogo" /> */}
                  <span>{this.props.teamTwoName}</span>
                </td>
                <td style={{ width: "20%" }} className="score">
                  {this.props.teamTwoScore}
                </td>
              </tr>
              <tr>
                <td className="gender">{this.props.gender}</td>
                <td className="final">FINAL</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default Leaderboard;
