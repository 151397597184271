import React, { Component } from "react";
import { Form } from "react-bootstrap";

class Radio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Form.Check
          inline
          label={this.props.label}
          type="radio"
          value={this.props.value}
          id={this.props.id}
          name={this.props.name}
          onChange={this.props.onChange}
          checked={this.props.checked}
          disabled={this.props.disabled}
        />
      </>
    );
  }
}

export default Radio;
