import React from "react";
import { withRouter } from "react-router-dom";
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";
import { Form, Button, Modal } from "react-bootstrap";
import { globalVariable } from "../../utils/global";
import { consts } from "../../utils/validation";
import UploadLogo from "../../assets/images/logo-upload.jpg";

const Openeye =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const Closeye =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";
const loginclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";


export default function InviteFriendsModal(props){

  return(
    <Aux>
        <Modal
          show={props.show}
          centered
          onHide={props.hide}
          animation={false}
          className="modal-style-groups"
        >
        <Modal.Header closeButton className="header-style-modal">
            <Button onClick={props.hide} className="btn closebtn">
              <img src={loginclose} alt="deafult" />
            </Button>
            <Modal.Title>INVITE FRIENDS</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-small">            
            <Form.Group>
              <Form.Label>Invite Friends</Form.Label>
              <Form.Control
                onChange={(event) => {
                  this.setState({ userName: event.target.value });
                }}
                type="text"
                placeholder="Enter friend name"
              />
            </Form.Group>

            <Form.Group>
                <Form.Label>
                    Message
                </Form.Label>
                <Form.Control
                    className="textarea-modal-send"
                    as="textarea"
                    maxLength={500}
                    rows={6}
                    placeholder="Enter message"
                    onChange={(event) => {
                    this.setState({
                        message: event.target.value,
                        errmessage: "",
                    });
                    }}
                />
            </Form.Group>
            
            <div className="footer-btn">
              <Button
                onClick=""
                variant="primary"
                type="submit"
              >
                INVITE
              </Button>
              <Button className="cancel-btn"
                onClick={props.hide}
              >
                CANCEL
              </Button>
            </div>
          </Modal.Body>
         
        </Modal>
    </Aux>
  )
}