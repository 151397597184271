import React from "react";
import Aux from "../hoc/_Aux";
import Placeadd from "../components/Placeadd";
import { Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import ProfileBaner from "../components/Profile-hero";
import Faqaccordian from "../components/Faqs";
import Menu from "../screens/includes/Navbar";
import Placebigadd from "../components/Placeyouraddbigbox";

const profilehero =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/about-banner.jpg";
const profileheromobile =  "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/about-banner-mobile.jpg";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: "input",
    };
    this.showHide = () => this.showHide.bind(this);
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };
  componentDidMount() {
    document.title = "NCR Rugby | About Us";
  }

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent about_us">
          <Row>
            <Col md="12">
              <div className="leaderord headerAdvt">
                <Placeadd />
              </div>
            </Col>
          </Row>
          <Row>
            <ProfileBaner
              text="ABOUT US"
              imgmobile={profileheromobile}
              img={profilehero}
              alt="deafult"
            />
          </Row>
          <Row className="deatilstext" style={{ backgroundColor: "#eeeeee" }}>
            <Col className="container">
              <div className="textandadd">
                <div className="Aboutus">
                  <p className="blackpad">
                    Formed in 2020, NCR is committed to the development of
                    college rugby by providing a wide array of services and
                    governance for men’s and women’s collegiate rugby programs.
                  </p>
                  <p className="blackpad">
                    NCR evolved from the National Small College Rugby
                    Organization (NSCRO) which was founded in 2007. Originally
                    created to support the growth and development of small
                    college rugby, the organization now serves college rugby
                    programs of all sizes.
                  </p>
                  <p className="blackpad">
                    NCR is an independent Delaware Non-Stock Non-Profit
                    corporation operating under section 220526 of the Ted
                    Stevens Act as an amateur sports organization with the
                    exclusive jurisdiction over its constituent college players,
                    coaches, and teams.
                  </p>
                  <p className="blackpad">
                    USA Rugby should be contacted for matters related to
                    international amateur competition such as all matters
                    pertaining to United States participation in the Olympic
                    Games, the Paralympic Games, and the Pan-American Games,
                    including representation of the United States in the games;
                    and the organization of the Olympic Games, the Paralympic
                    Games, and the Pan-American Games when held in the United
                    States.
                  </p>
                  <p className="p-green-bold greenpad">
                    BUILDING YOUNG ADULTS INTO TOMORROW’S LEADERS
                  </p>
                  <p className="blackpad">
                    NCR provides student-athletes with opportunities,
                    inspiration and recognition.
                  </p>
                  <p className="p-green-bold">MISSION</p>
                  <p className="blackpad">
                    To foster the on and off-field growth and development of
                    collegiate rugby teams, student-athletes, referees and
                    coaches.
                  </p>
                </div>

                <div className="img-box1">
                  <div className="img-box" style={{display:'none'}}>
                    <div className="img"></div>
                  </div>
                  {/* <Faqaccordian
                    heading1="How do I create an entry ?"
                    content1="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                    heading2="What is the deadline for entries ?"
                    content2="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                  /> */}
                  <Placebigadd />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Placeaddsm />
            </Col>
          </Row>
        </div>
      </Aux>
    );
  }
}
export default AboutUs;
