import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "./includes/Navbar";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeadd from "../components/Placeadd";
import Placeaddsm from "../components/Placeaddsm";
import TimerBanner from "../components/timer-banner";
import { GetData, PostData } from "../utils/apiRequestHandler";
import BracketBox from "../components/brackets/BracketBox";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import GroupsIcon from "../assets/images/Images_Assets/icon-group.svg";

import logo from "../assets/images/logo/logo.png";
// const logo =  "https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png";
const closeIcon =
  "https://d7le4ielk3ho1.cloudfront.net/src_images/login-cross.png";

class RugbyBracket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      isEditable: true,
      partiallyWomens: false,
      tieBrackerModal: false,
      tieBrackerMatch: {},
      tiebrackerError: "",
      tieBrackerScore: { team1: 0, team2: 0 },
      modalIsOpen: false,
      partiallySaved: false,
      partiallyUpdate: false,
      modalMsg: "",
      type: "input",
      btn1value: "",
      btn2value: "",
      addClass: false,
      addClass1: false,
      addClass2: false,
      addClass3: false,
      tabNumber: 1,
      mensLeagueTopLeft: [],
      mensLeagueBottomLeft: [],
      mensLeagueTopRight: [],
      mensLeagueBottomRight: [],
      mensLeagueCenter: [],
      mensCenterText: "--",
      mensTopLeftText: "--",
      mensTopRightText: "--",
      mensBottomLeftText: "--",
      mensBottomRightText: "--",
      survivorsCenter: [],
      survivorsLeft: [],
      survivorsRight: [],
      challengeCenter: [],
      challengeLeft: [],
      challengeRight: [],
      championLeft: [],
      championRight: [],
      championCenter: [],
      checkTournamentStarted: false,
      showPremierScroll: true,
    };
    this._userBracketId = "";
    this.showHide = () => this.showHide.bind(this);
    this._popupButton = 1;
  }

  componentDidMount() {
    document.title = "NCR Rugby | Score Mens Bracket";
    this.getBrackets();
    let tournamenttime = JSON.parse(localStorage.getItem("tournamenttime"));
    if (tournamenttime) {
      this.setState({
        checkTournamentStarted: tournamenttime.gameStarted,
      });
    }
  }

  getBrackets = () => {
    this.setState({ loader: true });
    GetData("getGameLists?gender=male").then((response) => {
      this.setState({ loader: false });
      if (response.status === true) {
        const mensLeagueTopLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        if (
          mensLeagueTopLeft.games[0].winner_id ||
          mensLeagueTopLeft.games[1].winner_id
        ) {
          // this.setState({ checkTournamentStarted: true });
        } else {
          return;
        }

        const mensLeagueBottomLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "bottom_left"
        );
        const mensLeagueTopRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const mensLeagueBottomRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "bottom_right"
        );
        const mensLeagueCenter = response.data[0].brackets.find(
          (x) => x.bracket_position === "center"
        );

        const survivorsLeft = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const survivorsRight = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const survivorsCenter = response.data[1].brackets[2];

        const challengeLeft = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const challengeRight = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const challengeCenter = response.data[2].brackets.find(
          (x) => x.bracket_position === "center"
        );

        const championLeft = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const championRight = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const championCenter = response.data[3].brackets.find(
          (x) => x.bracket_position === "center"
        );

        this.setState({
          championLeft: championLeft.games,
          championRight: championRight.games,
          championCenter: championCenter.games,
          challengeLeft: challengeLeft.games,
          challengeRight: challengeRight.games,
          challengeCenter: challengeCenter.games,
          mensTopLeftText: mensLeagueTopRight.devision,
          mensTopRightText: mensLeagueTopLeft.devision,
          mensBottomLeftText: mensLeagueBottomRight.devision,
          mensBottomRightText: mensLeagueBottomLeft.devision,
          mensCenterText: mensLeagueCenter.devision,
          mensLeagueTopLeft: mensLeagueTopLeft.games,
          mensLeagueBottomLeft: mensLeagueBottomLeft.games,
          mensLeagueTopRight: mensLeagueTopRight.games,
          mensLeagueBottomRight: mensLeagueBottomRight.games,
          mensLeagueCenter: mensLeagueCenter.games,
          survivorsLeft: survivorsLeft.games,
          survivorsRight: survivorsRight.games,
          survivorsCenter: survivorsCenter.games,
        });
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  submitBracket = () => {
    let stateKeys = [
      "championLeft",
      "championRight",
      "championCenter",
      "challengeLeft",
      "challengeRight",
      "challengeCenter",
      "mensLeagueTopLeft",
      "mensLeagueBottomLeft",
      "mensLeagueTopRight",
      "mensLeagueBottomRight",
      "mensLeagueCenter",
      "survivorsLeft",
      "survivorsRight",
      "survivorsCenter",
    ];
    let formDataArray = [];
    if (
      !this.state.mensLeagueCenter[2].winner_id ||
      !this.state.mensLeagueCenter[3].winner_id
    ) {
      //mensPremierCup
      this.setState({
        modalIsOpen: true,
        btn1value: "Back",
        btn2value: "",
        modalMsg:
          "Please select winner for final and place 3 for Mens Premier Cup",
      });
      return;
    }

    if (
      !this.state.survivorsCenter[2].winner_id ||
      !this.state.survivorsCenter[3].winner_id
    ) {
      //survivorsCup
      const elmnt = document.getElementById("survivorsCup");
      elmnt.scrollIntoView();
      this.setState({
        modalIsOpen: true,
        btn1value: "Back",
        btn2value: "",
        modalMsg:
          "Please select winner for final and place 3 for Survivors Cup",
      });
      return;
    }

    if (
      !this.state.challengeCenter[2].winner_id ||
      !this.state.challengeCenter[3].winner_id
    ) {
      //challengeCup
      const elmnt = document.getElementById("challengeCup");
      elmnt.scrollIntoView();
      this.setState({
        modalIsOpen: true,
        btn1value: "Back",
        btn2value: "",
        modalMsg:
          "Please select winner for final and place 3 for Challenge Cup",
      });
      return;
    }

    if (
      !this.state.championCenter[2].winner_id ||
      !this.state.championCenter[3].winner_id
    ) {
      //championCup
      const elmnt = document.getElementById("championCup");
      elmnt.scrollIntoView();
      this.setState({
        modalIsOpen: true,
        btn1value: "Back",
        btn2value: "",
        modalMsg: "Please select winner for final and place 3 for Champion Cup",
      });
      return;
    }

    stateKeys.map((key) => {
      let stateData = this.state[key];
      stateData.map((data) => {
        formDataArray.push({
          user_bracket_id: this._userBracketId,
          game_id: data.game_id,
          team_1_id: data.team1.team_id ? data.team1.team_id : null,
          team_2_id: data.team2.team_id ? data.team2.team_id : null,
          winner_id: data.winner_id
            ? data.winner_id
            : data.winner
            ? data.winner
            : null,
          team_1_score: data.team1.score ? data.team1.score : null,
          team_2_score: data.team2.score ? data.team2.score : null,
        });
      });

      return null;
    });
    /*if(error){
          alert('Please fill all details')
        }else{*/
    let formData = new FormData();
    formData.append("userBracketDetails", JSON.stringify(formDataArray));
    this.setState({ loader: true });
    PostData("manage-user-bracket/updateDetails", formData).then((response) => {
      this.setState({
        loader: false,
        modalIsOpen: true,
        btn1value: "Back",
        btn2value: "",
        modalMsg: "Brackets has been saved successfully",
      });
      this.getBrackets();
    });
    // }
  };

  removeDependencies = (dependents) => {
    dependents.map((d) => {
      const updateState = this.state[d.state];
      let indexKey;
      if (d.position) {
        indexKey = updateState.findIndex(
          (x) => x.round === d.round && x.position === d.position
        );
      } else {
        indexKey = updateState.findIndex((x) => x.round === d.round);
      }
      updateState[indexKey]["winner_id"] = "";
      updateState[indexKey]["winner"] = "";
      updateState[indexKey]["selected"] = "";
      d.teams.map((t) => {
        updateState[indexKey][t] = {};
        updateState[indexKey][t] = {};
        return null;
      });

      return null;
    });
  };

  updateAllStates = (currentState, match) => {
    switch (currentState) {
      case "championLeft":
        var dependents = [
          { state: "championCenter", round: 3, teams: ["team1"] },
          { state: "championCenter", round: 3, position: 2, teams: ["team1"] },
        ];
        this.removeDependencies(dependents);
        break;

      case "championRight":
        var dependents = [
          { state: "championCenter", round: 3, teams: ["team2"] },
          { state: "championCenter", round: 3, position: 2, teams: ["team2"] },
        ];
        this.removeDependencies(dependents);
        break;

      case "challengeLeft":
        var dependents = [
          { state: "challengeCenter", round: 3, teams: ["team1"] },
          { state: "challengeCenter", round: 3, position: 2, teams: ["team1"] },
        ];
        this.removeDependencies(dependents);
        break;

      case "challengeRight":
        var dependents = [
          { state: "challengeCenter", round: 3, teams: ["team2"] },
          { state: "challengeCenter", round: 3, position: 2, teams: ["team2"] },
        ];
        this.removeDependencies(dependents);
        break;
      case "survivorsLeft":
        if (match.round === 1) {
          let dependents;
          if (match.position > 2) {
            dependents = [
              {
                state: "survivorsLeft",
                round: 2,
                position: 2,
                teams: match.position === 3 ? ["team1"] : ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
          } else {
            dependents = [
              {
                state: "survivorsLeft",
                round: 2,
                position: 1,
                teams: match.position === 1 ? ["team1"] : ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
          }
          this.removeDependencies(dependents);
        }
        break;

      case "survivorsRight":
        if (match.round === 1) {
          let dependents;
          if (match.position > 2) {
            dependents = [
              {
                state: "survivorsRight",
                round: 2,
                position: 2,
                teams: match.position === 3 ? ["team1"] : ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
          } else {
            dependents = [
              {
                state: "survivorsRight",
                round: 2,
                position: 1,
                teams: match.position === 1 ? ["team1"] : ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
          }
          this.removeDependencies(dependents);
        }
        break;

      case "mensLeagueTopLeft":
        if (match.round === 1) {
          if (match.position === 1 || match.position === 2) {
            let dependents = [
              { state: "mensLeagueTopLeft", round: 3, teams: ["team1"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team1"],
              },
              { state: "survivorsLeft", round: 2, teams: ["team1"] },
              {
                state: "survivorsCenter",
                position: 1,
                round: 3,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeLeft", round: 1, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championLeft",
                round: 1,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "championCenter", round: 3, teams: ["team1"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
            this.removeDependencies(dependents);
          }
          if (match.position === 3 || match.position === 4) {
            let dependents = [
              { state: "mensLeagueTopLeft", round: 3, teams: ["team2"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team1"],
              },
              { state: "survivorsLeft", round: 2, teams: ["team2"] },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeLeft", round: 1, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championLeft",
                round: 1,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 1,
                teams: ["team1"],
              },
              { state: "championCenter", round: 3, teams: ["team1"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
            this.removeDependencies(dependents);
          }
        }
        if (match.round === 2) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "championCenter",
              round: 2,
              position: 1,
              teams: ["team1"],
            },
            { state: "championCenter", round: 3, teams: ["team1"] },
            {
              state: "championCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
          ];
          this.removeDependencies(dependents);
        }
        if (match.round === 3) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team1"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;
      case "mensLeagueBottomLeft":
        if (match.round === 1) {
          if (match.position === 5 || match.position === 6) {
            let dependents = [
              { state: "mensLeagueBottomLeft", round: 3, teams: ["team1"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsLeft",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeLeft",
                round: 1,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championLeft",
                round: 1,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "championCenter", round: 3, teams: ["team1"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
            this.removeDependencies(dependents);
          }
          if (match.position === 7 || match.position === 8) {
            let dependents = [
              { state: "mensLeagueBottomLeft", round: 3, teams: ["team2"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsLeft",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeLeft",
                round: 1,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championLeft",
                round: 1,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 1,
                teams: ["team2"],
              },
              { state: "championCenter", round: 3, teams: ["team1"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
            ];
            this.removeDependencies(dependents);
          }
        }
        if (match.round === 2) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "championCenter",
              round: 2,
              position: 1,
              teams: ["team2"],
            },
            { state: "championCenter", round: 3, teams: ["team1"] },
            {
              state: "championCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
          ];
          this.removeDependencies(dependents);
        }
        if (match.round === 3) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team1"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;

      case "mensLeagueTopRight":
        if (match.round === 1) {
          if (match.position === 1 || match.position === 2) {
            let dependents = [
              { state: "mensLeagueTopRight", round: 3, teams: ["team1"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team2"],
              },
              { state: "survivorsRight", round: 2, teams: ["team1"] },
              {
                state: "survivorsCenter",
                position: 2,
                round: 3,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeRight", round: 1, teams: ["team1"] },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championRight",
                round: 1,
                position: 1,
                teams: ["team1"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "championCenter", round: 3, teams: ["team2"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
            this.removeDependencies(dependents);
          }
          if (match.position === 3 || match.position === 4) {
            let dependents = [
              { state: "mensLeagueTopRight", round: 3, teams: ["team2"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team2"],
              },
              { state: "survivorsRight", round: 2, teams: ["team2"] },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeRight", round: 1, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championRight",
                round: 1,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              { state: "championCenter", round: 3, teams: ["team2"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
            this.removeDependencies(dependents);
          }
        }
        if (match.round === 2) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "championCenter",
              round: 2,
              position: 2,
              teams: ["team1"],
            },
            { state: "championCenter", round: 3, teams: ["team2"] },
            {
              state: "championCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        if (match.round === 3) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;
      case "mensLeagueBottomRight":
        if (match.round === 1) {
          if (match.position === 5 || match.position === 6) {
            let dependents = [
              { state: "mensLeagueBottomRight", round: 3, teams: ["team1"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsRight",
                round: 2,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeRight",
                round: 1,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championRight",
                round: 1,
                position: 2,
                teams: ["team1"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "championCenter", round: 3, teams: ["team2"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
            this.removeDependencies(dependents);
          }
          if (match.position === 7 || match.position === 8) {
            let dependents = [
              { state: "mensLeagueBottomRight", round: 3, teams: ["team2"] },
              {
                state: "mensLeagueCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "mensLeagueCenter",
                round: 5,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsRight",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 1,
                teams: ["team2"],
              },
              {
                state: "survivorsCenter",
                round: 4,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeRight",
                round: 1,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "challengeCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "challengeCenter", round: 3, teams: ["team2"] },
              {
                state: "challengeCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championRight",
                round: 1,
                position: 2,
                teams: ["team2"],
              },
              {
                state: "championCenter",
                round: 2,
                position: 2,
                teams: ["team2"],
              },
              { state: "championCenter", round: 3, teams: ["team2"] },
              {
                state: "championCenter",
                round: 3,
                position: 2,
                teams: ["team2"],
              },
            ];
            this.removeDependencies(dependents);
          }
        }
        if (match.round === 2) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "championCenter",
              round: 2,
              position: 2,
              teams: ["team2"],
            },
            { state: "championCenter", round: 3, teams: ["team2"] },
            {
              state: "championCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        if (match.round === 3) {
          let dependents = [
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 5,
              position: 2,
              teams: ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;
      default:
        break;
    }
  };

  forceUpdate = () => {
    if (this._popupButton === 2) {
      this.props.history.push("womens-bracket");
      return;
    }
    this.handleClick(
      this._match,
      this._stateKey,
      this._team,
      this._losingTeam,
      this._index,
      true
    );
    this.setState({ modalIsOpen: false });
  };

  handleClick = (match, stateKey, team, losingTeam, index, force = false) => {
    return;
  };

  setAddClass = () => {
    this.setState({
      addClass: true,
      addClass1: false,
      addClass2: false,
      tabNumber: 1,
    });
  };
  setAddClass1 = () => {
    this.setState({
      addClass1: true,
      addClass: false,
      addClass2: false,
      tabNumber: 2,
    });
  };
  setAddClass2 = () => {
    this.setState({
      addClass2: true,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };
  setAddClass3 = () => {
    this.setState({
      addClass3: false,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };

  arrowClass = () => {
    if (this.state.tabNumber == 3) {
      this.setAddClass1();
    } else if (this.state.tabNumber == 2) {
      this.setAddClass();
    } else if (this.state.tabNumber == 1) {
      this.setAddClass3();
    } else {
    }
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };
  toggleModal = () =>
    this.setState({
      showLogin: !this.state.showLogin,
    });

  setTieBreakerScore = (e, team) => {
    this.setState({ tiebrackerError: "" });
    if (isNaN(e.target.value)) {
      return;
    }
    if (e.target.value.length > 3) {
      return;
    }
    let tieBreaker = this.state.tieBrackerScore;
    if (team === "team1") {
      tieBreaker["team_1_score"] =
        e.target.value === "" ? 0 : parseInt(e.target.value);
    } else {
      tieBreaker["team_2_score"] =
        e.target.value === "" ? 0 : parseInt(e.target.value);
    }
    tieBreaker[team] = e.target.value === "" ? 0 : parseInt(e.target.value);
    this.setState({ tieBrackerScore: tieBreaker });
  };

  saveTieBreakerScore = () => {
    let leagueData = this.state.mensLeagueCenter;
    if (
      this.state.tieBrackerScore["team2"] ===
      this.state.tieBrackerScore["team1"]
    ) {
      this.setState({ tiebrackerError: "Scores cannot be equal" });
      return;
    }

    if (
      (leagueData[2]["winner_id"] === leagueData[2]["team1"].team_id ||
        leagueData[2]["winner"] === leagueData[2]["team1"].team_id) &&
      this.state.tieBrackerScore["team2"] > this.state.tieBrackerScore["team1"]
    ) {
      this.setState({
        tiebrackerError: "Team 1 score should be greater than Team 2 score",
      });
      return;
    }
    if (
      (leagueData[2]["winner_id"] === leagueData[2]["team2"].team_id ||
        leagueData[2]["winner"] === leagueData[2]["team2"].team_id) &&
      this.state.tieBrackerScore["team1"] > this.state.tieBrackerScore["team2"]
    ) {
      this.setState({
        tiebrackerError: "Team 2 score should be greater than Team 1 score",
      });
      return;
    }

    leagueData[2]["team1"]["score"] = this.state.tieBrackerScore["team1"];
    leagueData[2]["team2"]["score"] = this.state.tieBrackerScore["team2"];
    this.setState({ mensLeagueCenter: leagueData, tieBrackerModal: false });
  };

  gotowomens = () => {
    if (this.state.partiallyWomens) {
      this._popupButton = 2;
      this.setState({
        modalIsOpen: true,
        btn1value: "Cancel",
        btn2value: "Proceed",
        modalMsg: "Would you like to update women's bracket?",
      });
    } else {
      this.props.history.push("womens-bracket");
    }
  };

  render() {
    return (
      <Aux>
        <Loader show={this.state.loader} />
        <Menu />
        <div className="Maincontent leader-board-wrap">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <TimerBanner />
            <div className="leader-board-toplinks">
              <Container>
                <Row>
                  <div className="col-12">
                    <ul>
                      <li>
                        <div className="premier-links active">
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          MEN’S
                        </div>
                        <div
                          className="premier-links"
                          onClick={() =>
                            this.props.history.push("/womens-score")
                          }
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/women-icon.png"
                            className="premier-icon"
                          />{" "}
                          WOMEN’S
                        </div>
                        {/* <div
                          className="premier-links"
                          onClick={() =>
                            this.props.history.push("/groups")
                          }
                        >
                          <img
                            src={GroupsIcon} alt="Groups"
                            className="premier-icon"
                          />{" "}
                          GROUPS
                        </div> */}
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="invite-body-outer">
              {this.state.checkTournamentStarted === true ? (
                <div className="rugby-bracket-wrap">
                  <div className="bracket-wrapper-inner">
                    <div
                      className={
                        this.state.showPremierScroll === true
                          ? "brackets hbreakers scrollableBracket"
                          : "brackets hbreakers"
                      }
                      id="premiorBracket"
                    >
                      <ul
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "bracket-rouds-wrap secRd-left"
                                        : "bracket-rouds-wrap"
                                    }
                                    ${this.state.addClass1 ? "secRd-left1" : ""}
                                    ${this.state.addClass2 ? "secRd-left2" : ""}
                                
                                `}
                      >
                        <li
                          className={`
                                        ${
                                          this.state.addClass
                                            ? "round-tab arrow-left shift-arr1"
                                            : "round-tab arrow-left"
                                        }
                                        ${
                                          this.state.addClass1
                                            ? "shift-arr2"
                                            : ""
                                        }
                                        ${
                                          this.state.addClass2
                                            ? "shift-arr3"
                                            : ""
                                        }
                                    
                                    `}
                        >
                          <span
                            onClick={
                              this.state.showPremierScroll === true
                                ? ""
                                : this.arrowClass
                            }
                          ></span>
                        </li>
                        <li className="round-tab r_1">
                          <span className="r_name">1st ROUND</span>
                          <span className="r_date">April 26</span>
                        </li>
                        <li
                          className="round-tab r_2"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass
                          }
                        >
                          <span className="r_name">2nd ROUND</span>
                          <span className="r_date">April 26</span>
                        </li>
                        <li
                          className="round-tab r_3"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass1
                          }
                        >
                          <span className="r_name">QUARTERFINALS</span>
                          <span className="r_date">April 26</span>
                        </li>
                        <li
                          className="round-tab r_4"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass2
                          }
                        >
                          <span className="r_name">SEMI FINALS</span>
                          <span className="r_date">April 27</span>
                        </li>
                        <li
                          className="round-tab championship"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass2
                          }
                        >
                          <span className="r_name">CHAMPIONSHIP</span>
                          <span className="r_date">April 27</span>
                        </li>
                        <li className="round-tab r_4">
                          <span className="r_name">SEMI FINALS</span>
                          <span className="r_date">April 27</span>
                        </li>
                        <li className="round-tab r_3">
                          <span className="r_name">QUARTERFINALS</span>
                          <span className="r_date">April 26</span>
                        </li>
                        <li className="round-tab r_2">
                          <span className="r_name">2nd ROUND</span>
                          <span className="r_date">April 26</span>
                        </li>
                        <li className="round-tab r_1">
                          <span className="r_name">1st ROUND</span>
                          <span className="r_date">April 26</span>
                        </li>
                      </ul>
                      <div className="bracket-challenge-head"></div>
                      <div className="premier-cup-head" id="mensPremierCup">
                        PREMIER CUP
                      </div>
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "pairs-wrap sec-pair"
                                        : "pairs-wrap"
                                    }
                                    ${this.state.addClass1 ? "sec-pair1" : ""}
                                    ${this.state.addClass2 ? "sec-pair2" : ""}
                                `}
                      >
                        {/* <div className="pairs-wrap"> */}
                        {/* first pair */}
                        <div className="mW mW_1">
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* second pair */}
                        <div className="mW mW_2">
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>

                        {/* third pair */}
                        <div className="mW mW_3">
                          <BracketBox
                            stateKey="mensLeagueBottomLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomLeft}
                            round={1}
                            position={[5, 6]}
                          />
                        </div>
                        {/* fourth pair */}
                        <div className="mW mW_4">
                          <BracketBox
                            stateKey="mensLeagueBottomLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomLeft}
                            round={1}
                            position={[7, 8]}
                          />
                        </div>
                        {/* 5th pair */}
                        <div className="mW mW_5">
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* 6th pair */}
                        <div className="mW mW_6">
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>
                        {/* 7th pair */}
                        <div className="mW mW_7">
                          <BracketBox
                            stateKey="mensLeagueBottomRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomRight}
                            round={1}
                            position={[5, 6]}
                          />
                        </div>
                        {/* 8th pair */}
                        <div className="mW mW_8">
                          <BracketBox
                            stateKey="mensLeagueBottomRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomRight}
                            round={1}
                            position={[7, 8]}
                          />
                        </div>
                        {/* 9th pair */}
                        <div
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_9 m9Sec"
                                        : "mW mW_9"
                                    }
                                    ${this.state.addClass1 ? "m9Sec" : ""}
                                    ${this.state.addClass2 ? "m9Sec" : ""}
                                `}
                        >
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        {/* 10th pair */}
                        <div
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_10 m10Sec"
                                        : "mW mW_10"
                                    }
                                    ${this.state.addClass1 ? "m10Sec" : ""}
                                    ${this.state.addClass2 ? "m10Sec" : ""}
                                `}
                        >
                          <BracketBox
                            stateKey="mensLeagueBottomLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomLeft}
                            type={2}
                            round={2}
                            position={[3, 4]}
                          />
                        </div>

                        {/* 11th pair */}
                        <div
                          style={{ zIndex: "1" }}
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_11 m11Sec"
                                        : "mW mW_11"
                                    }
                                    ${this.state.addClass1 ? "m11Sec" : ""}
                                    ${this.state.addClass2 ? "m11Sec" : ""}
                                `}
                        >
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        {/* 12th pair */}
                        <div
                          style={{ zIndex: "1" }}
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_12 m12Sec"
                                        : "mW mW_12"
                                    }
                                    ${this.state.addClass1 ? "m12Sec" : ""}
                                    ${this.state.addClass2 ? "m12Sec" : ""}
                                `}
                        >
                          <BracketBox
                            stateKey="mensLeagueBottomRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomRight}
                            type={2}
                            round={2}
                            position={[3, 4]}
                          />
                        </div>
                        {/* 13th pair */}
                        <div
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_13 m13Sec"
                                        : "mW mW_13"
                                    }
                                    ${this.state.addClass1 ? "m13Sec1" : ""}
                                    ${this.state.addClass2 ? "m13Sec1" : ""}
                                `}
                        >
                          <div
                            className={`
                                        ${
                                          this.state.addClass
                                            ? "regions-left regTop"
                                            : "regions-left"
                                        }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                          >
                            {this.state.mensTopLeftText}
                          </div>
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            type={2}
                            round={3}
                            position={[1]}
                          />
                          <BracketBox
                            stateKey="mensLeagueBottomLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomLeft}
                            type={2}
                            round={3}
                            position={[2]}
                          />
                          <div
                            className={`
                                        ${
                                          this.state.addClass
                                            ? "regions-left-bottom regBot"
                                            : "regions-left-bottom"
                                        }
                                        ${this.state.addClass1 ? "regBot1" : ""}
                                    `}
                          >
                            {this.state.mensBottomLeftText}
                          </div>
                        </div>
                        {/* 14th pair */}
                        <div
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_14 m14Sec"
                                        : "mW mW_14"
                                    }
                                    ${this.state.addClass1 ? "m14Sec1" : ""}
                                    ${this.state.addClass2 ? "m14Sec1" : ""}
                                `}
                        >
                          {/* <div className="regions-right">WEST</div> */}
                          <div
                            className={`
                                        ${
                                          this.state.addClass
                                            ? "regions-right regTop"
                                            : "regions-right"
                                        }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                          >
                            {this.state.mensTopRightText}
                          </div>
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            type={2}
                            round={3}
                            position={[1]}
                          />
                          <BracketBox
                            stateKey="mensLeagueBottomRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueBottomRight}
                            type={2}
                            round={3}
                            position={[2]}
                          />
                          {/* <div className="regions-right-bottom">CENTRAL</div> */}
                          <div
                            className={`
                                        ${
                                          this.state.addClass
                                            ? "regions-right-bottom regBot"
                                            : "regions-right-bottom"
                                        }
                                        ${this.state.addClass1 ? "regBot1" : ""}
                                    `}
                          >
                            {this.state.mensBottomRightText}
                          </div>
                        </div>
                        {/* 15th pair */}
                        <div
                          style={{ zIndex: "0" }}
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_15 m15Sec"
                                        : "mW mW_15"
                                    }
                                    ${this.state.addClass1 ? "m15Sec1" : ""}
                                    ${this.state.addClass2 ? "m15Sec2" : ""}
                                `}
                        >
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={4}
                            position={[1]}
                          />
                        </div>
                        {/* 16th pair */}
                        <div
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_16 m16Sec"
                                        : "mW mW_16"
                                    }
                                    ${this.state.addClass1 ? "m16Sec1" : ""}
                                    ${this.state.addClass2 ? "m16Sec2" : ""}
                                `}
                        >
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={4}
                            position={[2]}
                          />
                        </div>
                        {/* 17th pair */}
                        <div
                          style={{ zIndex: 1 }}
                          className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_17 m17Sec"
                                        : "mW mW_17"
                                    }
                                    ${this.state.addClass1 ? "m17Sec1" : ""}
                                    ${this.state.addClass2 ? "m17Sec2" : ""}
                                `}
                        >
                          <div className="nat-champion-text">
                            National Championship
                          </div>
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={5}
                            position={[1]}
                          />
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={5}
                            position={[2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                      </div>
                      {/* pairs end */}
                      <div className="logo-brackets-bottom">
                        <img
                          src="/assets/images/logo-brackets.png"
                          alt="Bracket challenge"
                        />
                      </div>
                    </div>
                    {/* -------------------------champion cup bracket ------------------------------*/}
                    <div
                      className="brackets champion-bracket-wrap"
                      id="championCup"
                    >
                      <div className="champion-head"> PLATE</div>
                      <div className="champion-bracket-outer">
                        <div className="champion-bracket-inner">
                          {/* left col pair 1 */}
                          <div className="mW mW_32">
                            <BracketBox
                              stateKey="championLeft"
                              handleClick={this.handleClick}
                              data={this.state.championLeft}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col pair 1 */}
                          <div className="mW mW_33">
                            <BracketBox
                              stateKey="championRight"
                              handleClick={this.handleClick}
                              data={this.state.championRight}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col 2 */}
                          <div className="mW mW_34" style={{ zIndex: 0 }}>
                            <BracketBox
                              stateKey="championCenter"
                              handleClick={this.handleClick}
                              data={this.state.championCenter}
                              type={2}
                              round={2}
                              position={[1]}
                            />
                          </div>
                          {/* lerightft col 2 */}
                          <div className="mW mW_35">
                            <BracketBox
                              stateKey="championCenter"
                              handleClick={this.handleClick}
                              data={this.state.championCenter}
                              type={2}
                              round={2}
                              position={[2]}
                            />
                          </div>
                          {/* center col */}
                          <div className="mW mW_36" style={{ zIndex: 0 }}>
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              stateKey="championCenter"
                              handleClick={this.handleClick}
                              data={this.state.championCenter}
                              type={2}
                              round={3}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                        </div>
                        <div
                          className="bracket-bottom-text challenge-bottom-text blue-bracket-bottom-text"
                          style={{ left: "0px" }}
                        >
                          2nd round losers of
                          <br />
                          Premier Cup
                        </div>
                      </div>
                    </div>
                    {/* -------------------------survivor cup bracket ------------------------------*/}
                    <div
                      className="brackets survivor-bracket-wrap"
                      id="survivorsCup"
                    >
                      <div className="survivor-head">BOWL</div>
                      <div
                        className="survivor-bracket-outer"
                        style={{ paddingTop: "45px" }}
                      >
                        <div className="survivor-bracket-inner">
                          {/* left col pair 1 */}
                          <div className="mW mW_18">
                            <BracketBox
                              stateKey="survivorsLeft"
                              handleClick={this.handleClick}
                              data={this.state.survivorsLeft}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col pair 2 */}
                          <div className="mW mW_19">
                            <BracketBox
                              stateKey="survivorsLeft"
                              handleClick={this.handleClick}
                              data={this.state.survivorsLeft}
                              type={2}
                              round={1}
                              position={[3, 4]}
                            />
                          </div>
                          {/* right col pair 1 */}
                          <div className="mW mW_20" style={{ zIndex: 2 }}>
                            <BracketBox
                              stateKey="survivorsRight"
                              handleClick={this.handleClick}
                              data={this.state.survivorsRight}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col pair 2 */}
                          <div className="mW mW_21" style={{ zIndex: 2 }}>
                            <BracketBox
                              stateKey="survivorsRight"
                              handleClick={this.handleClick}
                              data={this.state.survivorsRight}
                              type={2}
                              round={1}
                              position={[3, 4]}
                            />
                          </div>
                          {/* left col 2 */}
                          <div className="mW mW_22">
                            <BracketBox
                              stateKey="survivorsLeft"
                              handleClick={this.handleClick}
                              data={this.state.survivorsLeft}
                              type={2}
                              round={2}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col 2 */}
                          <div className="mW mW_23" style={{ zIndex: 1 }}>
                            <BracketBox
                              stateKey="survivorsRight"
                              handleClick={this.handleClick}
                              data={this.state.survivorsRight}
                              type={2}
                              round={2}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col 3 */}
                          <div className="mW mW_24" style={{ zIndex: 0 }}>
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={3}
                              position={[1]}
                            />
                          </div>
                          {/* right col 3 */}
                          <div className="mW mW_25">
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={3}
                              position={[2]}
                            />
                          </div>
                          {/* center pair */}
                          <div className="mW mW_26" style={{ zIndex: 1 }}>
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={4}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                        </div>
                        <div className="bracket-bottom-text blue-bracket-bottom-text">
                          1st round losers from
                          <br />
                          Premier Cup
                        </div>
                      </div>
                    </div>
                    {/* -------------------------challenger cup bracket ------------------------------*/}
                    <div
                      className="brackets challenge-bracket-wrap"
                      id="challengeCup"
                    >
                      <div className="challenge-head"> SHIELD</div>
                      <div className="challenge-bracket-outer">
                        <div className="challenge-bracket-inner">
                          {/* left col pair 1 */}
                          <div className="mW mW_27">
                            <BracketBox
                              stateKey="challengeLeft"
                              handleClick={this.handleClick}
                              data={this.state.challengeLeft}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col pair 1 */}
                          <div className="mW mW_28">
                            <BracketBox
                              stateKey="challengeRight"
                              handleClick={this.handleClick}
                              data={this.state.challengeRight}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col 2 */}
                          <div className="mW mW_29" style={{ zIndex: 0 }}>
                            <BracketBox
                              stateKey="challengeCenter"
                              handleClick={this.handleClick}
                              data={this.state.challengeCenter}
                              type={2}
                              round={2}
                              position={[1]}
                            />
                          </div>
                          {/* lerightft col 2 */}
                          <div className="mW mW_30">
                            <BracketBox
                              stateKey="challengeCenter"
                              handleClick={this.handleClick}
                              data={this.state.challengeCenter}
                              type={2}
                              round={2}
                              position={[2]}
                            />
                          </div>
                          {/* center col */}
                          <div className="mW mW_31" style={{ zIndex: 0 }}>
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              stateKey="challengeCenter"
                              handleClick={this.handleClick}
                              data={this.state.challengeCenter}
                              type={2}
                              round={3}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                        </div>
                        <div
                          className="bracket-bottom-text challenge-bottom-text blue-bracket-bottom-text"
                          style={{ left: "0px" }}
                        >
                          1st round losers of
                          <br />
                          Shield Bracket
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bracket-error-message">
                  <img src={logo} alt="logo" />
                  <div className="message">
                    Tournament has not been started yet.
                  </div>
                </div>
              )}
            </div>
            <Placeaddsm />
            <div
              className="modal fade WelcomeModal"
              style={{
                display: `${this.state.tieBrackerModal ? "block" : "none"}`,
                opacity: "1",
              }}
              id="WelcomeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="rugby-tie-bracker">
                <div
                  className="modal-dialog modal-dialog-centered"
                  style={{ width: "320px" }}
                  width="350"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <Button
                        className="btn closebtn"
                        onClick={() =>
                          this.setState({ tieBrackerModal: false })
                        }
                      >
                        <img src={closeIcon} alt="deafult" />
                      </Button>
                    </div>
                    <div className="modal-body ">
                      <div className="tie-slots">
                        <div
                          className={
                            this.state.tieBrackerMatch.actual_winner_id
                              ? this.state.tieBrackerMatch.actual_winner_id ===
                                this.state.tieBrackerMatch.team1.team_id
                                ? "slot slot-1 green"
                                : "slot slot-1 red"
                              : "slot slot-1 grey"
                          }
                        >
                          <div className="team">
                            <div className="flag">
                              <img src="https://d7le4ielk3ho1.cloudfront.net/public_images/bracket-icons/fiji-icon.jpg" />
                            </div>
                            <div
                              className={
                                this.state.tieBrackerMatch.team1
                                  ? this.state.tieBrackerMatch.winner_id ===
                                      this.state.tieBrackerMatch.team1
                                        .team_id ||
                                    this.state.tieBrackerMatch.winner ===
                                      this.state.tieBrackerMatch.team1.team_id
                                    ? "teamName active"
                                    : "teamName"
                                  : "teamName"
                              }
                            >
                              {this.state.tieBrackerMatch.team1
                                ? this.state.tieBrackerMatch.team1.name
                                : ""}
                            </div>
                          </div>
                          <div className="score">
                            {this.state.tieBrackerMatch.team1_score
                              ? this.state.tieBrackerMatch.team1_score
                              : ""}
                          </div>
                        </div>
                        <div
                          className={
                            this.state.tieBrackerMatch.actual_winner_id
                              ? this.state.tieBrackerMatch.actual_winner_id ===
                                this.state.tieBrackerMatch.team2.team_id
                                ? "slot slot-2 green"
                                : "slot slot-2 red"
                              : "slot slot-2"
                          }
                        >
                          <div className="team">
                            <div className="flag">
                              <img src="https://d7le4ielk3ho1.cloudfront.net/public_images/bracket-icons/fiji-icon.jpg" />
                            </div>
                            <div
                              className={
                                this.state.tieBrackerMatch.team2
                                  ? this.state.tieBrackerMatch.winner_id ===
                                      this.state.tieBrackerMatch.team2
                                        .team_id ||
                                    this.state.tieBrackerMatch.winner ===
                                      this.state.tieBrackerMatch.team2.team_id
                                    ? "teamName active"
                                    : "teamName"
                                  : "teamName"
                              }
                            >
                              {this.state.tieBrackerMatch.team2
                                ? this.state.tieBrackerMatch.team2.name
                                : ""}
                            </div>
                          </div>
                          <div className="score">
                            {this.state.tieBrackerMatch.team2_score
                              ? this.state.tieBrackerMatch.team2_score
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="tie-title">
                        Tie Breaker
                        <div className="tie-subtitle">Pick the final score</div>
                      </div>
                      <div className="tie-title" style={{ marginTop: "16px" }}>
                        Total :{" "}
                        {parseInt(this.state.tieBrackerScore.team1) +
                          parseInt(this.state.tieBrackerScore.team2)}
                      </div>

                      <div className="team-tiles">
                        <div className="tile">
                          <div className="tile-box">
                            <div className="flag">
                              <img src="https://d7le4ielk3ho1.cloudfront.net/public_images/bracket-icons/fiji-icon.jpg" />
                            </div>
                            <input
                              type="text"
                              className="score"
                              onChange={(e) =>
                                this.setTieBreakerScore(e, "team1")
                              }
                              value={this.state.tieBrackerScore.team1}
                            />
                          </div>
                          <div className="tile-text">
                            {this.state.tieBrackerMatch.team1
                              ? this.state.tieBrackerMatch.winner_id ===
                                  this.state.tieBrackerMatch.team1.team_id ||
                                this.state.tieBrackerMatch.winner ===
                                  this.state.tieBrackerMatch.team1.team_id
                                ? "WINNER"
                                : "LOSER"
                              : "LOSER"}
                          </div>
                        </div>
                        <div className="tile-hiphen">-</div>
                        <div className="tile">
                          <div className="tile-box">
                            <div className="flag">
                              <img src="https://d7le4ielk3ho1.cloudfront.net/public_images/bracket-icons/fiji-icon.jpg" />
                            </div>
                            <input
                              type="text"
                              className="score"
                              onChange={(e) =>
                                this.setTieBreakerScore(e, "team2")
                              }
                              value={this.state.tieBrackerScore.team2}
                            />
                          </div>
                          <div className="tile-text">
                            {this.state.tieBrackerMatch.team2
                              ? this.state.tieBrackerMatch.winner_id ===
                                  this.state.tieBrackerMatch.team2.team_id ||
                                this.state.tieBrackerMatch.winner ===
                                  this.state.tieBrackerMatch.team2.team_id
                                ? "WINNER"
                                : "LOSER"
                              : "LOSER"}
                          </div>
                        </div>
                      </div>
                      <div className="error-message">
                        {this.state.tiebrackerError}
                      </div>
                      <div
                        className="submit-bracket-2"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          style={{ width: "100px" }}
                          type="button"
                          className="submit-style"
                          value="Save"
                          onClick={() => this.saveTieBreakerScore()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              imgsrc={logo}
              toggle={() => this.setState({ modalIsOpen: false })}
              showModal={this.state.modalIsOpen}
              invitefrend={this.state.modalIsOpen}
              title="MEN’S PREMIER CUP"
              className="rugby-mens-bracket"
              bodytext={this.state.modalMsg}
              btn1value={this.state.btn1value}
              btn2value={this.state.btn2value}
              button1Click={() => this.setState({ modalIsOpen: false })}
              button2Click={this.forceUpdate}
            />
          </div>
        </div>
      </Aux>
    );
  }
}
export default withRouter(RugbyBracket);
