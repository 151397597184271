import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import { Link, NavLink } from "react-router-dom";
import Faqaccordian from "../components/Faqs";
import Placebigadd from "../components/Placeyouraddbigbox";

class HowToPlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: "input",
    };
    this.showHide = () => this.showHide.bind(this);
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };

  componentDidMount() {
    document.title = "NCR Rugby | How To Play";
  }

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent invite-friend-wrap howto-play-wrap">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <div className="invite-body-outer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="invite-body-inner">
                      {/* Left container */}
                      <div className="invite-inner-left col-12">
                        <h1>How to Play</h1>
                        <div className="howto-play-left">
                          <div className="howto-play-listing">
                            {/* Step one */}
                            <p>
                              <div className="steps-head">Step One</div>
                              <h2>Entering the Game</h2>
                              <div className="steps-text">
                                Create a username and password to log in to the
                                game and complete your bracket. You must
                                complete all brackets and then select Submit
                                Bracket.
                              </div>
                            </p>
                            {/* Step two */}
                            {/* <p>
                              <div className="steps-head">Step Two</div>
                              <h2>Naming Your Entry (optional)</h2>
                              <div className="steps-text">
                                Before filling out your bracket give your entry
                                some character and assign it a name. After
                                you’ve clicked the{" "}
                                <strong>"Create Entry"</strong> button, you will
                                be forwarded to a screen that will ask you to
                                name your entry. Once you have named your entry,
                                you may choose to change your Email Reminders
                                setting and then click on the{" "}
                                <strong>"Submit Entry Settings"</strong> button.
                                <br />
                                <br />
                                You will have the opportunity to change your
                                entry name at any point during the game by
                                clicking the{" "}
                                <strong>"Edit Entry Settings"</strong> link on
                                your entry page.
                              </div>
                            </p>
                             */}
                            {/* Step two */}
                            <p>
                              <div className="steps-head">Step Two</div>
                              <h2>
                                Make Your Selections
                                {/* <span>
                                  (Bracket available Selection Sevens Sunday May
                                  2, 2021)
                                </span> */}
                              </h2>
                              <div className="steps-text">
                                Choose the winners of each match starting with
                                Round 1 of the Premier Cup. As you make your
                                selections, the losing teams will automatically
                                move to the consolation brackets. You must
                                choose a winner in every match and enter the
                                total score of the Premier Cup Championship in
                                order to submit your bracket.
                              </div>
                              <br />
                              <div className="steps-text">
                                Your bracket must be submitted by 11:59pm EST on
                                Thursday, April 25.
                              </div>
                            </p>
                            {/* Step four */}
                            <p>
                              <div className="steps-head">Step Three</div>
                              <h2>Invite Your Friends</h2>
                              <div className="steps-text">
                                <span>
                                  <strong>
                                    <Link to="/invite-friends">
                                      Invite Your Friends
                                    </Link>
                                  </strong>
                                </span>
                                &nbsp;to play the Brackets Challenge. The
                                bracket challenge is open until 11:59pm EST on
                                Thursday, April 25.
                              </div>
                            </p>
                            {/* Step five */}
                            <p className="pb-0">
                              <div className="steps-head">Step Four</div>
                              <h2>Accumulating Points (Men’s)</h2>
                              <div className="steps-text">
                                The object of the game is simple: Correctly pick
                                the winner of the most matches in the 64 games
                                of the tournament. You must complete all four
                                brackets in the tournament including the
                                Premier, Survivor, Contender, and Challenge
                                brackets. A correct selection in the first round
                                of the main brackets counts as 15 points, and
                                the points are then doubled for each successive
                                round. See below for more information.
                              </div>
                              <div className="howto-table-head">
                                <span className="blue">
                                  Premier Cup Bracket
                                </span>
                              </div>

                              {/* table for Desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner">
                                  <div className="table-head">ROUND</div>
                                  <div className="table-head">TEAMS</div>
                                  <div className="table-head">GAMES</div>
                                  <div className="table-head">
                                    POINTS (each)
                                  </div>
                                  <div className="table-head">
                                    POINTS (total)
                                  </div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">First Round</div>
                                  <div className="table-body">32</div>
                                  <div className="table-body">16</div>
                                  <div className="table-body">15</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Second Round</div>
                                  <div className="table-body">16</div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">30</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Quarterfinals
                                  </div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">60</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Semi Finals</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    National Championship
                                  </div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">240</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">3rd Place</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">120</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Total</div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>32</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>1320</strong>
                                  </div>
                                </div>
                              </div>
                              {/* table for device */}
                              <div className="table-device">
                                {/* first round */}
                                <div className="round-head">First Round</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 32
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 16
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 15
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* second round */}
                                <div className="round-head">Second Round</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 16
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 8
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 30
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Quarterfinals */}
                                <div className="round-head">Quarterfinals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 8
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 4
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 60
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Semi Finals */}
                                <div className="round-head">Semi Finals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 4
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 2
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* National Championship */}
                                <div className="round-head">
                                  National Championship
                                </div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 240
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* 3rd Place */}
                                <div className="round-head">3rd Place</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* Total */}
                                <div className="round-head">Total</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 32
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 1320
                                  </div>
                                </div>
                              </div>
                              {/* table 4 */}
                              <div className="howto-table-head">
                                <span className="blue">
                                  Plate Bracket
                                  <br />
                                  2nd Round losers, Premier Cup Bracket
                                </span>
                              </div>
                              {/* Table for desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner">
                                  <div className="table-head">ROUND</div>
                                  <div className="table-head">TEAMS</div>
                                  <div className="table-head">GAMES</div>
                                  <div className="table-head">
                                    POINTS (each)
                                  </div>
                                  <div className="table-head">
                                    POINTS (total)
                                  </div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Quarterfinals{" "}
                                  </div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">60</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Semi Final</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">240</div>
                                </div>

                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Champions Plate
                                  </div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">240</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">3rd Place</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">120</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Total</div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>8</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>840</strong>
                                  </div>
                                </div>
                              </div>
                              {/* Table for device */}
                              {/* table for device */}
                              <div className="table-device">
                                {/* first round */}
                                <div className="round-head">Quarterfinals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 8
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 4
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 60
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Quarterfinals */}
                                <div className="round-head">Semi Final</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 4
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 2
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/*  Champions Plate */}
                                <div className="round-head">
                                  {" "}
                                  Champions Plate
                                </div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 240
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>

                                {/* 3rd Place */}
                                <div className="round-head">3rd Place</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* Total */}
                                <div className="round-head">Total</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 8
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 840
                                  </div>
                                </div>
                              </div>
                              {/* table 2 */}
                              <div className="howto-table-head">
                                <span className="blue">
                                  Bowl Bracket
                                  <br />
                                  1st Round losers, Premier Cup Bracket
                                </span>
                              </div>
                              {/* table for Desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner">
                                  <div className="table-head">ROUND</div>
                                  <div className="table-head">TEAMS</div>
                                  <div className="table-head">GAMES</div>
                                  <div className="table-head">
                                    POINTS (each)
                                  </div>
                                  <div className="table-head">
                                    POINTS (total)
                                  </div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">First Round</div>
                                  <div className="table-body">16</div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">30</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Quarterfinals
                                  </div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">60</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Semi Finals</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Survivors Bowl
                                  </div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">240</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">3rd Place</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">120</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Total</div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>16</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>1080</strong>
                                  </div>
                                </div>
                              </div>
                              {/* table for device */}
                              <div className="table-device">
                                {/* first round */}
                                <div className="round-head">First Round</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 16
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 8
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 30
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Quarterfinals */}
                                <div className="round-head">Quarterfinals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 8
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 4
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 60
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Semi Finals */}
                                <div className="round-head">Semi Finals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 4
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 2
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Survivors Bowl */}
                                <div className="round-head">Semi Finals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 240
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* 3rd Place */}
                                <div className="round-head">3rd Place</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* Total */}
                                <div className="round-head">Total</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 16
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> --
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 1080
                                  </div>
                                </div>
                              </div>
                              {/* table 3 */}
                              <div className="howto-table-head">
                                <span className="blue">
                                  SHIELD BRACKET
                                  <br />
                                  1st Round losers,  Bowl Bracket
                                </span>
                              </div>
                              {/* Table for desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner">
                                  <div className="table-head">ROUND</div>
                                  <div className="table-head">TEAMS</div>
                                  <div className="table-head">GAMES</div>
                                  <div className="table-head">
                                    POINTS (each)
                                  </div>
                                  <div className="table-head">
                                    POINTS (total)
                                  </div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Quarterfinals
                                  </div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">60</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Semi Finals</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Challenge Bowl
                                  </div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">240</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">3rd Place</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">120</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Total</div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>8</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>840</strong>
                                  </div>
                                </div>
                              </div>
                              {/* Table for device */}
                              {/* table for device */}
                              <div className="table-device">
                                {/* Quarterfinals */}
                                <div className="round-head">Quarterfinals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 8
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 4
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 60
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Semi Finals */}
                                <div className="round-head">Semi Finals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 4
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 2
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Challenge Bowl */}
                                <div className="round-head">Challenge Bowl</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 240
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* 3rd Place */}
                                <div className="round-head">3rd Place</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* Total */}
                                <div className="round-head">Total</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 8
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> --
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 840
                                  </div>
                                </div>
                              </div>

                              {/* total */}
                              <div className="howto-table-head">
                                <span className="blue">
                                  TOTAL
                                  <br />
                                  Best Score Possible:
                                </span>
                              </div>
                              {/* Table for desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner"></div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Premier Cup :{" "}
                                  </div>
                                  <div className="table-body">1320</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Plate :</div>
                                  <div className="table-body">840</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Bowl :</div>
                                  <div className="table-body">1080</div>
                                </div>

                                <div className="table-play-inner">
                                  <div className="table-body">Shield :</div>
                                  <div className="table-body">840</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">TOTAL :</div>
                                  <div className="table-body">
                                    <strong>4080</strong>
                                  </div>
                                </div>
                              </div>
                              {/* Table for device */}
                              {/* table for device */}
                              <div className="table-device">
                                {/* first round */}
                                <div className="round-head">
                                  Premier Cup :{" "}
                                  <span style={{ float: "right" }}>1320</span>
                                </div>

                                {/* Quarterfinals */}
                                <div className="round-head">
                                  Survivors Bowl :{" "}
                                  <span style={{ float: "right" }}>1080</span>
                                </div>
                                {/*  Champions Plate */}
                                <div className="round-head">
                                  {" "}
                                  Champions Plate :{" "}
                                  <span style={{ float: "right" }}>840</span>
                                </div>

                                {/* 3rd Place */}
                                <div className="round-head">
                                  {" "}
                                  Champions Plate :{" "}
                                  <span style={{ float: "right" }}>840</span>
                                </div>

                                {/* Total */}
                                <div className="round-head">
                                  {" "}
                                  TOTAL :{" "}
                                  <span style={{ float: "right" }}>4080</span>
                                </div>
                              </div>
                            </p>
                            {/* Step Six */}
                            <p className="pb-0">
                              <div className="steps-head">Step Five</div>
                              <h2>Accumulating Points (Women’s)</h2>

                              <div className="howto-table-head">
                                <span className="blue">
                                  Premier Cup Bracket
                                </span>
                              </div>

                              {/* table for Desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner">
                                  <div className="table-head">ROUND</div>
                                  <div className="table-head">TEAMS</div>
                                  <div className="table-head">GAMES</div>
                                  <div className="table-head">
                                    POINTS (each)
                                  </div>
                                  <div className="table-head">
                                    POINTS (total)
                                  </div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">First Round</div>
                                  <div className="table-body">16</div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">30</div>
                                  <div className="table-body">240</div>
                                </div>

                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Quarterfinals
                                  </div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">60</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Semi Finals</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Survivors Bowl
                                  </div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">220</div>
                                  <div className="table-body">220</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">3rd Place</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">120</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Total</div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>16</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>1080</strong>
                                  </div>
                                </div>
                              </div>
                              {/* table for device */}
                              <div className="table-device">
                                {/* first round */}
                                <div className="round-head">First Round</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 16
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 8
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 30
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>

                                {/* Quarterfinals */}
                                <div className="round-head">Quarterfinals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 8
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 4
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 60
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Semi Finals */}
                                <div className="round-head">Semi Finals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 4
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 2
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 240
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* National Championship */}
                                <div className="round-head">Survivors Bowl</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* 3rd Place */}
                                <div className="round-head">3rd Place</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* Total */}
                                <div className="round-head">Total</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 16
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 1080
                                  </div>
                                </div>
                              </div>
                              {/* table 4 */}
                              <div className="howto-table-head">
                                <span className="blue">
                                  Plate Bracket
                                  <br />
                                  2nd Round losers, Premier Cup Bracket
                                </span>
                              </div>
                              {/* Table for desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner">
                                  <div className="table-head">ROUND</div>
                                  <div className="table-head">TEAMS</div>
                                  <div className="table-head">GAMES</div>
                                  <div className="table-head">
                                    POINTS (each)
                                  </div>
                                  <div className="table-head">
                                    POINTS (total)
                                  </div>
                                </div>

                                <div className="table-play-inner">
                                  <div className="table-body">Semi Final</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">240</div>
                                </div>

                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Champions Bowl
                                  </div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">240</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">3rd Place</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">120</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Total</div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>4</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>600</strong>
                                  </div>
                                </div>
                              </div>
                              {/* Table for device */}
                              {/* table for device */}
                              <div className="table-device">
                                {/* first round */}

                                {/* Quarterfinals */}
                                <div className="round-head">Semi Final</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 4
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 2
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/*  Champions Plate */}
                                <div className="round-head">
                                  {" "}
                                  Champions Bowl
                                </div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 240
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>

                                {/* 3rd Place */}
                                <div className="round-head">3rd Place</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* Total */}
                                <div className="round-head">Total</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 4
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 600
                                  </div>
                                </div>
                              </div>
                              {/* table 2 */}
                              <div className="howto-table-head">
                                <span className="blue">
                                  Bowl Bracket
                                  <br />
                                  1st Round losers, Premier Cup Bracket
                                </span>
                              </div>
                              {/* table for Desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner">
                                  <div className="table-head">ROUND</div>
                                  <div className="table-head">TEAMS</div>
                                  <div className="table-head">GAMES</div>
                                  <div className="table-head">
                                    POINTS (each)
                                  </div>
                                  <div className="table-head">
                                    POINTS (total)
                                  </div>
                                </div>

                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Quarterfinals
                                  </div>
                                  <div className="table-body">8</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">60</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Semi Finals</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Champions Plate
                                  </div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">240</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">3rd Place</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">120</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Total</div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>8</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>840</strong>
                                  </div>
                                </div>
                              </div>
                              {/* table for device */}
                              <div className="table-device">
                                {/* Quarterfinals */}
                                <div className="round-head">Quarterfinals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 8
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 4
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 60
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Semi Finals */}
                                <div className="round-head">Semi Finals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 4
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 2
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Survivors Bowl */}
                                <div className="round-head">Semi Finals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 240
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* 3rd Place */}
                                <div className="round-head">3rd Place</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* Total */}
                                <div className="round-head">Total</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 8
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> --
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 840
                                  </div>
                                </div>
                              </div>
                              {/* table 3 */}
                              <div className="howto-table-head">
                                <span className="blue">
                                  SHIELD BRACKET
                                  <br />
                                  1st Round losers,  Bowl Bracket
                                </span>
                              </div>
                              {/* Table for desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner">
                                  <div className="table-head">ROUND</div>
                                  <div className="table-head">TEAMS</div>
                                  <div className="table-head">GAMES</div>
                                  <div className="table-head">
                                    POINTS (each)
                                  </div>
                                  <div className="table-head">
                                    POINTS (total)
                                  </div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Quarterfinals
                                  </div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">140</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Semi Finals</div>
                                  <div className="table-body">4</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Challenge Bowl
                                  </div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">240</div>
                                  <div className="table-body">240</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">3rd Place</div>
                                  <div className="table-body">2</div>
                                  <div className="table-body">1</div>
                                  <div className="table-body">120</div>
                                  <div className="table-body">120</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Total</div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>4</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>---</strong>
                                  </div>
                                  <div className="table-body">
                                    <strong>600</strong>
                                  </div>
                                </div>
                              </div>
                              {/* Table for device */}
                              {/* table for device */}
                              <div className="table-device">
                                {/* Quarterfinals */}

                                {/* Semi Finals */}
                                <div className="round-head">Semi Finals</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 4
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 2
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* Challenge Bowl */}
                                <div className="round-head">Challenge Bowl</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 240
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 240
                                  </div>
                                </div>
                                {/* 3rd Place */}
                                <div className="round-head">3rd Place</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> 2
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 1
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> 120
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 120
                                  </div>
                                </div>
                                {/* Total */}
                                <div className="round-head">Total</div>
                                <div className="table-round-inner">
                                  <div className="table-round-inner-left">
                                    <span>TEAM:</span> ---
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>GAMES:</span> 4
                                  </div>
                                  <div className="table-round-inner-left">
                                    <span>POINTS (each):</span> --
                                  </div>
                                  <div className="table-round-inner-right">
                                    <span>POINTS (total):</span> 600
                                  </div>
                                </div>
                              </div>

                              {/* total */}
                              <div className="howto-table-head">
                                <span className="blue">
                                  TOTAL
                                  <br />
                                  Best Score Possible:
                                </span>
                              </div>
                              {/* Table for desktop */}
                              <div className="table-howto-play">
                                <div className="table-play-inner"></div>
                                <div className="table-play-inner">
                                  <div className="table-body">
                                    Premier Cup :{" "}
                                  </div>
                                  <div className="table-body">1080</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Plate :</div>
                                  <div className="table-body">600</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">Bowl :</div>
                                  <div className="table-body">840</div>
                                </div>

                                <div className="table-play-inner">
                                  <div className="table-body">Shield :</div>
                                  <div className="table-body">600</div>
                                </div>
                                <div className="table-play-inner">
                                  <div className="table-body">TOTAL :</div>
                                  <div className="table-body">
                                    <strong>3120</strong>
                                  </div>
                                </div>
                              </div>
                              {/* Table for device */}
                              {/* table for device */}
                              <div className="table-device">
                                {/* first round */}
                                <div className="round-head">
                                  Premier Cup:{" "}
                                  <span style={{ float: "right" }}>1080</span>
                                </div>

                                {/* Quarterfinals */}
                                <div className="round-head">
                                  Survivors Bowl :{" "}
                                  <span style={{ float: "right" }}>840</span>
                                </div>
                                {/*  Champions Plate */}
                                <div className="round-head">
                                  {" "}
                                  Champions Plate :{" "}
                                  <span style={{ float: "right" }}>600</span>
                                </div>

                                {/* 3rd Place */}
                                <div className="round-head">
                                  {" "}
                                  Champions Plate :{" "}
                                  <span style={{ float: "right" }}>600</span>
                                </div>

                                {/* Total */}
                                <div className="round-head">
                                  {" "}
                                  TOTAL :{" "}
                                  <span style={{ float: "right" }}>3120</span>
                                </div>
                              </div>
                            </p>
                          </div>
                          <p
                            style={{
                              color: "#333333",
                              fontFamily: "MaisonNeue",
                              textAlign: "center",
                              marginTop: "10px",
                              fontSize: "14px",
                            }}
                          >
                            No purchase necessary to enter, win or claim a prize{" "}
                            <Link to="/read-more">Read More.</Link>
                          </p>
                        </div>
                      </div>
                      {/* right container */}
                      <div
                        className="invite-inner-right"
                        style={{ display: "none" }}
                      >
                        <div
                          className="invite-right-top"
                          style={{ display: "none" }}
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/invite-right-img.jpg"
                            alt="deafult"
                          />
                        </div>
                        {/* <div className="invite-faq-right">
                          <Faqaccordian
                            heading1="How do I create an entry ?"
                            content1="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                            heading2="What is the deadline for entries ?"
                            content2="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                          />
                       
                        </div> */}
                        <Placebigadd />
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
            <Placeaddsm />
          </div>
        </div>
      </Aux>
    );
  }
}
export default HowToPlay;
