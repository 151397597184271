import React, { Component } from "react";
import GoogleAd from './GoogleAdsense'
//const advtImage =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Rhino-Ad-300x250.jpg";
const advtImage =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Rhino-ad-2.jpg";
class Placeyouraddbigbox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="place-b" style={{display:'none'}}>
          <a href="https://usa.rhinorugby.com/" style={{display:'block'}} target="_blank" rel="noreferrer">
            <img src={advtImage} alt="advt image"/>
          </a>
        </div>
      </>
    );
  }
}

export default Placeyouraddbigbox;
