import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { Row, Col, Form } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import Input from "../components/Input";
import Bluebtn from "../components/Bluebtn";
import ProfileBaner from "../components/Profile-hero";
import Faqaccordian from "../components/Faqs";

import Placebigadd from "../components/Placeyouraddbigbox";
import { globalVariable } from "../utils/global";
import { consts } from "../utils/validation";
import axios from "axios";

const Team =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/contact-banner.jpg";
const mail =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/mail.svg";
const map =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/map-pin.svg";
const profileheromobile =  "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/contact-banner-mobile.jpg";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: "input",
      succesMessage: "",
      errorMessage: "",
      firstName: null,
      lastName: null,
      showSpinner: false,
      email: null,
      message: null,
      errfirstName: "",
      redirectTimeout: null,
      errlastName: "",
      erremail: "",
      errmessage: "",
    };
    this.showHide = () => this.showHide.bind(this);
  }

  componentDidMount() {
    document.title = "NCR Rugby | Contact Us";
  }

  submitcontactus() {
    this.setState({
      showSpinner: true,
    });

    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      message: this.state.message,
    };
    if (
      (this.state.firstName == null || this.state.firstName == "") &&
      (this.state.lastName == null || this.state.lastName == "") &&
      (this.state.email == null || this.state.email == "") &&
      (this.state.message == null || this.state.message == "")
    ) {
      this.setState({
        errfirstName: "Please fill first name",
        errlastName: "Please fill last name",
        erremail: "Please fill email",
        errmessage: "Please fill message",
        succesMessage: false,
        errorMessage: false,
        showSpinner: false,
      });
    } else {
      if (this.state.firstName == null || this.state.firstName == "") {
        this.setState({
          errfirstName: "Please fill first name",
          showSpinner: false,
        });
        this.setState({ errorMessage: false });
      } else if (this.state.lastName == null || this.state.lastName == "") {
        this.setState({
          errlastName: "Please fill last name",
          showSpinner: false,
        });
        this.setState({ errorMessage: false });
      } else if (
        !consts.emailRegex.test(this.state.email) ||
        this.state.email == null
      ) {
        window.scrollTo(0, 0);
        this.setState({
          erremail: "Please enter a valid email ID",
          showSpinner: false,
        });
        return;
      } else if (this.state.email == null || this.state.email == "") {
        this.setState({
          erremail: "Please fill email",
          showSpinner: false,
        });
        this.setState({ errorMessage: false });
      } else if (this.state.message == null || this.state.message == "") {
        this.setState({
          errmessage: "Please fill message",
          showSpinner: false,
        });
        this.setState({ errorMessage: false });
      } else {
        let BaseUrl = globalVariable.baseURL + "contactUs";

        axios
          .post(BaseUrl, data)
          .then((resp) => {
            console.warn("resp", resp);
            console.warn(resp);
            if (resp.data.code === 200) {
              // alert(resp.data.message);
              // this.redirectTimeout = setTimeout(() => {
              //   this.setState({
              //     showSpinner: true,
              //   });
              // }, 100);
              this.setState({
                succesMessage: resp.data.message,
                showSpinner: false,
              });
              this.setState({ errorMessage: false });
              // console.warn(this.props.history.push("/Home"));
            }
          })
          .catch((err) => {
            // alert(err.response.data.message);
            this.setState({ errorMessage: err.response.data.message });
            this.setState({ succesMessage: false });
            this.setState({
              showSpinner: false,
            });
          });
      }
    }
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent">
          <Row>
            <Col md="12">
              <div className="leaderord headerAdvt">
                <Placeadd />
              </div>
            </Col>
          </Row>
          <Row>
            <ProfileBaner
              imgmobile={profileheromobile}
              img={Team}
              text="CONTACT US"
              alt="deafult"
            />
          </Row>

          <Col className="contactus">
            <div className="sighnuppad">
              <div className="sighnup-box">
                <Row>
                  <Col md="7">
                    <div className="sighnup-form">
                      <p className="header-title">Get in Touch</p>
                      <Row>
                        <Col>
                          <Form.Row className="firstrow">
                            <Col className="fname" md="6">
                              <Input
                                require="*"
                                label="First Name"
                                placeholder="Enter first name"
                                onChange={(event) => {
                                  this.setState({
                                    firstName: event.target.value,
                                    errfirstName: "",
                                  });
                                }}
                              />
                              <Form.Text
                                style={{ marginBottom: "10px" }}
                                className="text-muted"
                              >
                                <div className="error">
                                  {this.state.errfirstName}
                                </div>
                              </Form.Text>
                            </Col>
                            <Col className="lname" md="6">
                              <Input
                                require="*"
                                label="Last Name"
                                placeholder="Enter last name"
                                onChange={(event) => {
                                  this.setState({
                                    lastName: event.target.value,
                                    errlastName: "",
                                  });
                                }}
                              />
                              <Form.Text
                                style={{ marginBottom: "10px" }}
                                className="text-muted"
                              >
                                <div className="error">
                                  {this.state.errlastName}
                                </div>
                              </Form.Text>
                            </Col>
                          </Form.Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Input
                              label="Email Address"
                              require="*"
                              placeholder="Enter Email Address"
                              onChange={(event) => {
                                this.setState({
                                  email: event.target.value,
                                  erremail: "",
                                });
                              }}
                            />
                            <Form.Text
                              style={{ marginBottom: "10px" }}
                              className="text-muted"
                            >
                              <div className="error">{this.state.erremail}</div>
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group style={{ marginBottom: "0" }}>
                            <Form.Label>
                              Message <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              className="textarea"
                              as="textarea"
                              maxLength={500}
                              rows={6}
                              placeholder="Enter your message"
                              onChange={(event) => {
                                this.setState({
                                  message: event.target.value,
                                  errmessage: "",
                                });
                              }}
                            />
                          </Form.Group>
                          <Form.Text
                            style={{ marginBottom: "10px" }}
                            className="text-muted"
                          >
                            <div className="error">{this.state.errmessage}</div>
                          </Form.Text>
                          <Form.Text
                            style={{ marginBottom: "20px" }}
                            className="text-muted"
                          >
                            {this.state.errorMessage && (
                              <div style={{ color: "red" }} className="error">
                                {this.state.errorMessage}
                              </div>
                            )}
                            {this.state.succesMessage && (
                              <div
                                style={{ color: "green" }}
                                className="succesmsg"
                              >
                                {this.state.succesMessage}
                              </div>
                            )}
                          </Form.Text>
                        </Col>
                      </Row>

                      <Row>
                        <Col style={{ padding: "0 15px" }}>
                          <div className="frombtn">
                            <Bluebtn
                              value="SUBMIT"
                              type="submit"
                              onClick={() => {
                                this.submitcontactus();
                              }}
                              spinner={
                                this.state.showSpinner && (
                                  <i className="fa fa-circle-o-notch fa-spin"></i>
                                )
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md="5">
                    <div className="national-box">
                      <p className="title">
                        National Collegiate Rugby Organization
                      </p>
                      <div className="contactadd">
                        <div className="location">
                          <img src={map} alt="deafult" />
                          <p>
                            230 N. Maple Ave., Suite B1 #295 Marlton, NJ
                            08053-9412
                          </p>
                        </div>
                        <div className="mail">
                          <img src={mail} alt="deafult" />
                          <a
                            style={{ color: "#14376f" }}
                            href="mailto:mm7brackets@ncr.rugby"
                          >
                            mm7brackets@ncr.rugby
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="fawrow">
                  <Col>
                    <div className="img-box1">
                      <div className="img-box" style={{display:'none'}}>
                        <div className="img"></div>
                      </div>
                      {/* <Faqaccordian
                        heading1="How do I create an entry ?"
                        content1="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                        heading2="What is the deadline for entries ?"
                        content2="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                      /> */}
                      <Placebigadd />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>

          <Row>
            <Col md="12">
              <Placeaddsm />
            </Col>
          </Row>
        </div>
      </Aux>
    );
  }
}
export default ContactUs;
