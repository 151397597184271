import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "../src/components/ScrollToTop";
import { GetData } from "./utils/apiRequestHandler";

import Footer from "../src/screens/includes/Footer";
import Home from "./screens/Home";
import Homelogin from "./screens/UserHome";
import Signup from "./screens/SignUp";
import Profile from "./screens/Profile";
import AboutUs from "./screens/AboutUs";
import MatchTimes from "./screens/MatchTimes";


import Termscondition from "./screens/Termscondition";
import Privatepolicy from "./screens/Privatepolicy";
import InviteFriend from "./screens/InviteFriend";
import HowToPlay from "./screens/HowToPlay";
import LeaderBoardMain from "./screens/LeaderBoardMain";
import Contactus from "./screens/Contactus";
import FillBracket from "./screens/FillBracket";
import Createbracket from "./screens/Create-bracket";
import Faq from "./screens/Faq";
import Protected from "./utils/protectedpath";
import UnProtected from "./utils/unprotectedpath";
import readmore from "./screens/Readmore";

import WhereToWatch from './screens/whereToWatch'

import RugbyMensBracket from "./screens/rugby-mens-bracket";
import RugbyWomensBracket from "./screens/rugby-womens-bracket";

import RugbyMensWiningBracket from "./screens/rugby-mens-wining-bracket";
import RugbyWomensWiningBracket from "./screens/rugby-womens-wining-bracket";

import AdminMensBracket from "./screens/admin-mens-bracket";
import AdminWomensBracket from "./screens/admin-womens-bracket";

import MensBracketScore from "./screens/score-mens-bracket";
import WomensBracketScore from "./screens/score-womens-bracket";

import MensScoreShare from "./screens/MensScoreShare";
import WomensScoreShare from "./screens/WomensScoreShare";
import LeaderBoardShare from "./screens/LeaderBoardShare";

import VerifyEmail from "./screens/verify-email";
import ResetPassword from "./screens/reset-password";
import Groups from "./screens/Groups";

import CreateBracketNew from "./screens/CreateBracketNew";
import PublicGroup from "./screens/PublicGroup";

class Routes extends React.Component {
  constructor(props) {
    // var today = new Date(),
    //   time = today.toISOString();
    super(props);
    this.state = {
      // currentTime: time,
      tournamenttime: localStorage.getItem("SatrtTime"),
      localtoken: localStorage.getItem("login"),
    };
  }
  GetTime = () => {
    GetData("starttime").then((response) => {
      if (response !== "") {
        let checkLocalStorage = localStorage.SatrtTime;
        localStorage.setItem("SatrtTime", response.gameStarted);
        // response.gameStarted = false;
        localStorage.setItem("tournamenttime", JSON.stringify(response));
        if (checkLocalStorage === undefined && response.gameStarted === true) {
          window.location.reload();
        }
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  componentDidMount() {
    this.GetTime();
  }
  render() {
    return (
      <div>
        <Router>
          <ScrollToTop>
            <Switch>
              {this.state.localtoken !== null ? (
                <Route path="/" exact>
                  <UnProtected cmp={Homelogin} />
                </Route>
              ) : (
                <Route path="/" exact>
                  <UnProtected cmp={Homelogin} />
                </Route>
              )}
              {/* <Route path="/" exact>
                <UnProtected cmp={Home} />
              </Route> */}
              <Route path="/Home" exact>
                <Protected cmp={Homelogin} />
              </Route>
              <Route path="/profile" exact>
                <Protected cmp={Profile} />
              </Route>
              <Route path="/sign-up" exact>
                <UnProtected cmp={Signup} />
              </Route>
              <Route path="/about-us" exact component={AboutUs} />
              <Route path="/match-times" exact component={MatchTimes} />
              <Route
                path="/terms-and-conditions"
                exact
                component={Termscondition}
              />
              <Route path="/private-policy" exact component={Privatepolicy} />
              <Route path="/invite-friends" exact component={InviteFriend} />
              <Route path="/how-to-play" exact component={HowToPlay} />
              <Route
                path="/leader-board-main/:id"
                exact
                component={LeaderBoardMain}
              />
              <Route path="/contact-us" exact component={Contactus} />
              <Route path="/fill-brackets" exact>
                <Protected cmp={FillBracket} />
              </Route>
              <Route path="/create-brackets/:tabname?/:gender?" exact>
                <Protected cmp={Createbracket} />
              </Route>
              <Route path="/read-more" exact component={readmore} />
              {/* <Route path="/faq" exact component={Faq} /> */}
              <Route path="/mens-bracket/:name/:id" exact>
                <Protected cmp={RugbyMensBracket} />
              </Route>
              <Route path="/mens-bracket-public/:name/:id" exact component={RugbyMensBracket} />
              <Route path="/manage-mens-bracket" exact>
                <Protected cmp={AdminMensBracket} />
              </Route>
              <Route path="/manage-womens-bracket" exact>
                <Protected cmp={AdminWomensBracket} />
              </Route>
              <Route path="/womens-bracket/:name/:id" exact>
                <Protected cmp={RugbyWomensBracket} />
              </Route>
              <Route path="/womens-bracket-public/:name/:id" exact component={RugbyWomensBracket} />
              <Route path="/mens-wining-bracket" exact>
                <RugbyMensWiningBracket/>
              </Route>
              <Route path="/womens-wining-bracket" exact>
                <RugbyWomensWiningBracket/>
              </Route>

              <Route path="/mens-score" exact component={MensBracketScore} />
              <Route
                path="/womens-score"
                exact
                component={WomensBracketScore}
              />
              <Route path="/mens-score-share" exact component={MensScoreShare} />
              <Route path="/womens-score-share" exact component={WomensScoreShare} />
              <Route path="/leaderboard-share" exact component={LeaderBoardShare} />
              <Route path="/groups" exact>
                <Protected cmp={Groups} />
              </Route>                
              <Route
                path="/where-to-watch"
                exact
                component={WhereToWatch}
              />
              <Route
                path="/verify-email/:token"
                exact
                component={VerifyEmail}
              />
              <Route
                path="/reset-password/:forgottoken"
                exact
                component={ResetPassword}
              />
              {/* new */}
              <Route path="/create-brackets-new" exact component={CreateBracketNew} />
              <Route path="/public-groups" exact component={PublicGroup} />
              
              
            </Switch>
            {window.location.pathname !== '/mens-score-share' && window.location.pathname !== '/womens-score-share' && window.location.pathname !== '/leaderboard-share' && window.location.pathname !== '/mens-wining-bracket' && window.location.pathname !== '/womens-wining-bracket' ? <Footer /> : ''}
            
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}

export default Routes;
