import React from "react";
import { Redirect } from "react-router-dom";
import { GetData } from "../utils/apiRequestHandler";

function protectedpath(props) {
  const Cmp = props.cmp;
  var auth = JSON.parse(localStorage.getItem("login"));
  const GetTime = () => {
    GetData("starttime").then((response) => {
      if (response !== "") {
        let checkLocalStorage = localStorage.SatrtTime;
        localStorage.setItem("SatrtTime", response.gameStarted);
        localStorage.setItem("tournamenttime", JSON.stringify(response));
        if (checkLocalStorage === undefined && response.gameStarted === true) {
          window.location.reload();
        }
      } else {
        console.log("Error while fetching data");
      }
    });
  };
  GetTime();
  
  console.warn(auth);

  return <div>{auth ? <Cmp/> : <Redirect to="/"></Redirect>}</div>;
}

export default protectedpath;
