import React from "react";
import { withRouter } from "react-router-dom";
import Aux from "../hoc/_Aux";
import { Link, NavLink } from "react-router-dom";
import Menu from "../screens/includes/Navbar";
import Login from "../components/LoginModal/Login";
import { Row, Col } from "react-bootstrap";
import Midimg from "../components/Midsectionimg";
import Steps from "../components/Steps";
import Earnpoints from "../components/Earnpoints";
import Loginbtn from "../components/Homemidloginbtn";

//import Accordion from "../components/Accordion";
import Textslider from "../components/slider";
import Placeleaderbox from "../components/Placeleaderbox";
import Placebigadd from "../components/Placeyouraddbigbox";
import Banner from "../components/homepageBanner";
import Placeadd from "../components/Placeadd";

import Championship from "../components/Championship";
import Videosection from "../components/Video";
// import CrsLogo from "../assets/images/Images_Assets/cbs-sports-logo.png";
//import { Accordion, Card, Button } from 'react-bootstrap';
import {GetData} from '../utils/apiRequestHandler'
const demoimg = "https://d7le4ielk3ho1.cloudfront.net/src_images/bg-images/video1imgbew.jpg";
const demoimg2 = "https://d7le4ielk3ho1.cloudfront.net/src_images/new/video2.jpg";

const pointsimgA = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/earn-points.svg";
const pointsimgB = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/earn-point2.svg";
const pointsimgC = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/leader-borad.svg";
const pointsimgD = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/invite.svg";
const stepiconA = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/register.svg";
const stepiconB = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/create-bracket.svg";
const stepiconC = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/winpoints.svg";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setActiveAccordion = this.setActiveAccordion.bind(this);

    this.state = {
      show: false,
      type: "input",
      activeAccordion: null,
      active: false,
      autoplayone: "",
      autoplaytwo: "",
      playvideo: "",
      playsecondvideo: "",
    };
    this.handleplayvideo = this.handleplayvideo.bind(this);
    this.handlesecondvid = this.handlesecondvid.bind(this);
  }
  componentDidMount() {
    document.title = "NCR Rugby | Home";
  }

  setActiveAccordion(activename) {
    this.setState({ activeAccordion: activename });
  }
  handleplayvideo() {
    this.setState({ playvideo: true, playsecondvideo: false });
    let youtubeurl =
      "https://www.youtube.com/embed/Gdzc1tJpsys?rel=0&amp;autoplay=1&amp;controls=0&amp;showinfo=0";
    this.setState({ autoplayone: youtubeurl });
  }
  handlesecondvid() {
    this.setState({ playsecondvideo: true, playvideo: false });
    let youtubeurl =
      "https://www.youtube.com/embed/B3E2u4oz044?rel=0&amp;autoplay=1&amp;controls=0&amp;showinfo=0";
    this.setState({ autoplaytwo: youtubeurl });
  }
  handleShow() {
    console.log(this.state);
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent">
          <Row>
            <Col md="12">
              <div className="leaderord headerAdvt">
                <Placeadd />
              </div>
            </Col>
          </Row>
          <Row>
            <Banner />
          </Row>
          <Row className="swapcol">            
            <Col
              className="pl-0 pr-0 pl-xl-3 pr-lg-3 order-md-1 order-lg-2"
            >
              <Loginbtn onClick={this.handleShow} />
            </Col>
          </Row>
          
          <Midimg />
          <Row>
            <Col className="pointsect">
              <div className="cards-box">
                <Earnpoints
                  imgsrc={pointsimgA}
                  text="Pick a perfect bracket and win a 2023 National 7s Vacation Package"
                />
                <div className="mid-line"></div>
                <Earnpoints
                  imgsrc={pointsimgB}
                  text="Raise funds for your club by setting up an Alumni Pool"
                />
                <div className="mid-line"></div>
                <Earnpoints
                  imgsrc={pointsimgC}
                  text="Check Your Score on Leaderboard"
                />
                <div className="mid-line"></div>
                <Earnpoints
                  imgsrc={pointsimgD}
                  text="Invite Your Friends &amp; Compare Your Score"
                />
              </div>
            </Col>
          </Row>
          {/* <Championship /> */}
          
          <Row>
            <Col md="12">
              <div className="faq-box">
                <div className="faq-button">
                  <p>
                    <br />
                    <br />
                    No purchase necessary to enter, win or claim a prize.
                  </p>
                  <p className="readmemore">
                    <Link to="/read-more">Read More</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="placeleaderbox">
                <Placeleaderbox sno="1" />
              </div>
            </Col>
          </Row>
        </div>
        <Login
          onClick={() => this.setState({ show: false })}
          show={this.state.show}
        />
      </Aux>
    );
  }
}
export default Home;
